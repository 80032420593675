
<div class="header-black-logo">
  <div class="header-black-logo-div">
    <img src="/assets/images/logo.svg" class="header-black-logo-img" onclick="window.open('https://bit.ly/ord2eatcom', '_blank');"/>
  </div>

</div>
<div class="top-meni-welcome-text">
  <h1 class="reqistration_title" style="    margin-top: 1rem;    margin-bottom: 1rem;">{{ Text| translate }}</h1>

</div>
<div class="companymenu">
  <div class="col-12 companymenu-div ">
    <div [ngClass]="cssProfile" (click)="menuClick([LanguageId,'company'])">{{ 'profile'| translate}}</div>
    <div [ngClass]="cssMedia" (click)="menuClick([LanguageId,'company','accessory'])">{{ 'accessory'| translate}}</div>
    <div [ngClass]="cssMenu" (click)="menuClick([LanguageId,'company','menu'])">{{ 'menu'| translate}}</div>
    <div [ngClass]="cssUser" (click)="menuClick([LanguageId,'company','user'])">{{ 'user'| translate}}</div>
    <div [ngClass]="cssInvoices" (click)="menuClick([LanguageId,'company','invoice'])">{{ 'invoices'| translate}}</div>
    <SignOut class="col-2 company-menu-item"></SignOut>
  </div>

</div>
<div class="companymenu-mobile">
  <div class="companymenu-mobile-text">{{ Active | translate}}</div>
  <div class="companymenu-mobile-img" (click)="ShowMobileMenu()">
    <img src="/assets/images/mobile-menu.svg" />
  </div>
  <div class="companymenu-mobile-menu" *ngIf="showMobileMenu">
    <ul class="companymenu-mobile-menu-ul">
      <li class="companymenu-mobile-menu-ul-li" (click)="menuClick([LanguageId,'company'])">{{ 'profile'| translate}}</li>
      <li class="companymenu-mobile-menu-ul-li" (click)="menuClick([LanguageId,'company','accessory'])">{{ 'accessory'| translate}}</li>
      <li class="companymenu-mobile-menu-ul-li" (click)="menuClick([LanguageId,'company','menu'])">{{ 'menu'| translate}}</li>
      <li class="companymenu-mobile-menu-ul-li" (click)="menuClick([LanguageId,'company','user'])">{{ 'user' | translate}}</li>
      <li class="companymenu-mobile-menu-ul-li" (click)="menuClick([LanguageId,'company','invoice'])">{{ 'invoices'| translate}}</li>
      <li class="companymenu-mobile-menu-ul-li" (click)="SignOutClick()">{{ 'sign out'| translate}}</li>
      <li class="companymenu-mobile-menu-ul-li-close">
        <div class="companymenu-mobile-menu-ul-li-close-img" (click)="CloseMobileMenu()"></div>
      </li>
    </ul>

    <!--<SignOut class="col-2 company-menu-item"></SignOut>-->
  </div>
</div>
