import { Injectable } from "@angular/core";


export class OrderItem {

  public ProductId: number;
  public ProductListId: number;
  public ProductName: string;
  public ProductPrice: number;
  public Count: number;
  public TmpCount: number;
  public DefaultName: string;
}


@Injectable()
export class OrderService {

  private items: OrderItem[] = [];
  private companyId = "";

  constructor() {
    const raw = localStorage.getItem("OrderList");
    const companyId = localStorage.getItem("OrderListCompanyId");
    if (typeof raw !== "undefined" && raw !== null && raw !== "") {
      this.items = JSON.parse(raw);
    }
    else {
      this.items = [];
    }
    if (typeof companyId !== "undefined" && companyId !== null && companyId !== "") {
      this.companyId = companyId;
    }
  }

  public GetItems(companyId: string): OrderItem[] {

    if (this.companyId !== companyId) {
      this.Clear();
      localStorage.setItem("OrderListCompanyId", companyId);
      return this.items;
    }
    else {
      return this.items;
    }
    //;
    //for (let i = 0; i < 4; i++) {
    //  items.push({ ProductId: i, ProductName: 'ProductName ProductName ProductName' + i, ProductPrice: 50 * (i + 1), Count : 1});
    //}
    
  }

  public GetProduct(companyId: string,ProductId: number): OrderItem  {

    if (this.companyId !== companyId) {
      localStorage.setItem("OrderListCompanyId", companyId);
      this.Clear();
      this.companyId = companyId;
    }

    const found = this.items.find(function (item) { return item.ProductId === ProductId;});
    if (typeof found === "undefined")
    {
      return null;
    }
    else
      return found;
  }

  public GetProductList(companyId: string, ProductId: number, ListItemId): OrderItem {

    if (this.companyId !== companyId) {
      localStorage.setItem("OrderListCompanyId", companyId);
      this.Clear();
      this.companyId = companyId;
    }

    const found = this.items.find(function (item) { return item.ProductId === ProductId && item.ProductListId === ListItemId });
    if (typeof found === "undefined") {
      return null;
    }
    else
      return found;
  }

  public SaveItem(companyId: string, item: OrderItem): void {
    if (this.companyId !== companyId) {
      localStorage.setItem("OrderListCompanyId", companyId);
      this.Clear();
      this.companyId = companyId;
    }

    const found = this.items.find(function (_item) { return item.ProductId === _item.ProductId; });
    if (typeof found === "undefined") {
      if (item.Count > 0) {
        this.items.push(item);
        localStorage.setItem("OrderList", JSON.stringify(this.items));
      }

    }
    else {
      if (item.Count === 0) {
        const foundIndex = this.items.findIndex(function (_item) { return item.ProductId === _item.ProductId; });
        this.items.splice(foundIndex, 1);
      }
      localStorage.setItem("OrderList", JSON.stringify(this.items));
    }
    
  }

  public SaveListItem(companyId: string, item: OrderItem): void {
    if (this.companyId !== companyId) {
      localStorage.setItem("OrderListCompanyId", companyId);
      this.Clear();
      this.companyId = companyId;
    }

    const found = this.items.find(function (_item) { return item.ProductId === _item.ProductId && item.ProductListId === _item.ProductListId; });
    if (typeof found === "undefined") {
      if (item.Count > 0) {
        this.items.push(item);
        localStorage.setItem("OrderList", JSON.stringify(this.items));
      }

    }
    else {
      if (item.Count === 0) {
        const foundIndex = this.items.findIndex(function (_item) { return item.ProductId === _item.ProductId && item.ProductListId === _item.ProductListId; });
        this.items.splice(foundIndex, 1);
      }
      localStorage.setItem("OrderList", JSON.stringify(this.items));
    }

  }


  public Clear() {
    this.items = [];
    localStorage.setItem("OrderList", JSON.stringify(this.items));
  }
}
