import { Component, Input, OnInit } from "@angular/core";
import { Navigation, Router } from "@angular/router";

@Component({
  selector: 'TopMenu',
  templateUrl: 'topmenu.component.html'
})
export class TopMenuComponent implements OnInit {

  @Input()
  Text = "";

  @Input()
  Active = "profile"

  @Input()
  LanguageId = 'en'

  public cssProfile = "col-2 company-menu-item";
  public cssMenu = "col-2 company-menu-item";
  public cssUser = "col-2 company-menu-item";
  public cssInvoices = "col-2 company-menu-item";
  public cssMedia = "col-2 company-menu-item";

  public showMobileMenu = false;

  constructor(public navigate: Router) {

  }

  ngOnInit() {
    if (this.Active === "profile")
      this.cssProfile = "col-2 company-menu-item company-menu-item-active";
    if (this.Active === "menu")
      this.cssMenu = "col-2 company-menu-item company-menu-item-active";
    if (this.Active === "user")
      this.cssUser = "col-2 company-menu-item company-menu-item-active";
    if (this.Active === "invoices")
      this.cssInvoices = "col-2 company-menu-item company-menu-item-active";
    if (this.Active === "media")
      this.cssMedia = "col-2 company-menu-item company-menu-item-active";
  }

  public menuClick(_url: any[]) {
    this.showMobileMenu = false;
    this.navigate.navigate(_url);
  }

  public ShowMobileMenu() {
    this.showMobileMenu = true;
  }

  public CloseMobileMenu() {
    this.showMobileMenu = false;
  }

  public SignOutClick() {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('jwt_sys_role');
    location.reload();
  }

}
