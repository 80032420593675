import { Component, ViewChild, OnInit } from "@angular/core";
import { UserService, AddUserModel, UpdateUserModel } from "../../../services/user/UserService";
import { ActivatedRoute, Router } from "@angular/router";
import { DataTableConfig, DataTableSource, DataTableComponent } from "../../../components/DataTable/datatable.component";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";


export class UserDataTableSource implements DataTableSource {

  constructor(public userService: UserService) {

  }

  GetHeaderColumns(): string[] {
    return ["Id","User name"];
    }
  GetItems(Page: number, Count: number): import("rxjs").Observable<any> {
    return this.userService.GetUserList(Page, Count);
    }
    GetItemColumns(): string[] {
      return ["id","userName"];
    }
  GetCountPerPage(): number {
    return 10;
    }

}

@Component({
  templateUrl:'users.page.html'
})
export class UsersAdminPage implements OnInit {

  public PageState = 0;
  public formState = "";
  public updateId = 0;

  public dataTableConfig: DataTableConfig;
  public dataTableSource: DataTableSource;

  public UserName = "";
  public Password = "";
  public RePassword = "";

  public pageLanguageId = 'en';

  @ViewChild(DataTableComponent, { static: false }) dataTable: DataTableComponent;

  constructor(public userService: UserService, public route: ActivatedRoute, public router: Router,
    public translateService: TranslateService) {
    this.formState = this.route.snapshot.paramMap.get("formState");
    if (this.formState === "add") {
     
      this.PageState = 1;

    }
    else if (this.formState === "update") {
      this.updateId = parseInt( this.route.snapshot.paramMap.get("id"));
      this.PageState = 2;
      this.userService.GetUser(this.updateId).subscribe(data => {
        this.UserName = data.userName;
      });
    }
    else {
      this.dataTableConfig = new DataTableConfig();
      this.dataTableConfig.add = true;
      this.dataTableConfig.addUrl = ['admin','users','add'];
      this.dataTableConfig.remove = true;
      this.dataTableConfig.update = true;
      this.dataTableConfig.updateUrl = ['admin', 'users', 'update', '{id}'];
      const removeEvent = new Subject<string>();
      removeEvent.subscribe(id => {
        this.userService.RemoveUser(parseInt(id)).subscribe(result => {
          this.dataTable.ReloadData();
        });
      });
      this.dataTableConfig.removeEvent = removeEvent;

      this.dataTableSource = new UserDataTableSource(userService);
    }
  }
    ngOnInit(): void {
      this.route.params.subscribe(routeParams => {
        this.pageLanguageId = routeParams.languageId;
        this.translateService.use(this.pageLanguageId);

      });
    }

  public AddSubmit() {

    const _model = new AddUserModel();
    if (this.UserName === "") {
      alert("User name is required");
    }
    else if (this.Password === "") {
      alert("Password is required");
    }
    else if (this.RePassword === "") {
      alert("Re-password is required");
    }
    else if (this.Password !== this.RePassword) {
      alert("Passwords must be same");
    }
    else {
      _model.Password = this.Password;
      _model.UserName = this.UserName
      this.userService.AddUser(_model).subscribe(data => {
        if (data.code === -1) {
          alert("User exists with this user name.");
        }
        else if (data.code === 0) {
          alert("Unknow error")
        }
        else {
          this.router.navigate(['/admin/users/']);
        }
      });
    }

  }

  public UpdateSubmit() {
  
      const _model = new UpdateUserModel();
      if (this.Password === "") {
        alert("Password is required");
      }
      else if (this.RePassword === "") {
        alert("Re-password is required");
      }
      else if (this.Password !== this.RePassword) {
        alert("Passwords must be same");
      }
      else {
        _model.Password = this.Password;
        _model.Id = this.updateId;
        this.userService.UpdateUser(_model).subscribe(data => {
          if (data.code === -1) {
            alert("User exists with this user name.");
          }
          else if (data.code === 0) {
            alert("Unknow error")
          }
          else {
            alert("Password has been changed");
          }
        });
      }
  }

}
