<body>
  <sign-in-page *ngIf="AppState == 3">

  </sign-in-page>
  <div *ngIf="AppState == 4">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="AppState == 1">
    <TopBar></TopBar>
    <LeftMenuComponent></LeftMenuComponent>

    <div class="main-container">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div *ngIf="AppState == 2">
    <router-outlet></router-outlet>

  </div>


</body>
