import { Component, ViewChild, OnInit } from "@angular/core";
import { UserService, AddUserModel, UpdateUserModel } from "../../../services/user/UserService";
import { ActivatedRoute, Router } from "@angular/router";
import { DataTableConfig, DataTableSource, DataTableComponent } from "../../../components/DataTable/datatable.component";
import { Subject } from "rxjs";
import { CompanyService, AddCompanyModel } from "../../../services/company/CompanyService";
import { TranslateService } from "@ngx-translate/core";

declare function InitDatePicker(id: string, _date: string): any;
declare function GetDatePicker(id: string): string;

export class CompaniesDataTableSource implements DataTableSource {

  constructor(private companyService: CompanyService) {

  }

  GetHeaderColumns(): string[] {
    return ["Id","Company name","Email","ShortId", "State"];
    }
  GetItems(Page: number, Count: number): import("rxjs").Observable<any> {
    return this.companyService.GetCompanyList(Page, Count);
    }
    GetItemColumns(): string[] {
      return ["id", "companyName","email","shortcutId","state"];
    }
    GetCountPerPage(): number {
    return 100;
    }

}

@Component({
  templateUrl:'companies.page.html'
})
export class CompaniesAdminPage implements OnInit {

  public PageState = 0;
  public formState = "";
  public updateId = 0;

  public dataTableConfig: DataTableConfig;
  public dataTableSource: DataTableSource;

  public UserName = "";
  public Password = "";
  public RePassword = "";

  private pageLanguageId = 'en';

  public _model = new AddCompanyModel();

  @ViewChild(DataTableComponent, { static: false }) dataTable: DataTableComponent;

  constructor(private companyService: CompanyService, private route: ActivatedRoute, private router: Router, private translateService: TranslateService, private userService: UserService) {
    this.formState = this.route.snapshot.paramMap.get("formState");
    if (this.formState === "add") {
      this._model = new AddCompanyModel();
      this._model.languageDefault = 1;
     // this._model.expirationDate = new Date();
      this.PageState = 1;

    }
    else if (this.formState === "update") {
      this.updateId = parseInt( this.route.snapshot.paramMap.get("id"));
      this.PageState = 2;
      this.companyService.GetCompany(this.updateId).subscribe(data => {

        this._model = data;
        InitDatePicker("expirationDate2", this._model.expirationDate);
      });
    }
    else {
      this.dataTableConfig = new DataTableConfig();
      this.dataTableConfig.add = true;
      this.dataTableConfig.addUrl = ['admin','companies','add'];
      this.dataTableConfig.remove = true;
      this.dataTableConfig.update = true;
      this.dataTableConfig.updateUrl = ['admin', 'companies', 'update', '{id}'];
      const removeEvent = new Subject<string>();
      removeEvent.subscribe(id => {
        this.companyService.RemoveCompany(parseInt(id)).subscribe(result => {
          this.dataTable.ReloadData();
        });
      });
      this.dataTableConfig.removeEvent = removeEvent;

      this.dataTableSource = new CompaniesDataTableSource(companyService);
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.pageLanguageId = routeParams.languageId;
      this.translateService.use(this.pageLanguageId);
      if (this.PageState === 2) {
        
      }
      
    });
  }

  public AddSubmit() {


    if (this.UserName === "") {
      this.translateService.get("User name is required").subscribe(trans => {
        alert(trans);
      })
      
    }
    else if (this.Password === "") {
      this.translateService.get("Password is required").subscribe(trans => {
        alert(trans);
      })
    }
    else if (this.RePassword === "") {
      this.translateService.get("Ronfirm password is required").subscribe(trans => {
        alert(trans);
      })
    }
    else if (this.Password !== this.RePassword) {
      this.translateService.get("Passwords must be same").subscribe(trans => {
        alert(trans);
      })
    }
    else {
      this._model.languageDefault = parseInt("" + this._model.languageDefault);
      this.companyService.AddCompany(this._model).subscribe(data => {
        if (data.code === -1) {
          this.translateService.get("User exists with this user name.").subscribe(trans => {
            alert(trans);
          })
        }
        else if (data.code === 0) {
          this.translateService.get("Unknow error").subscribe(trans => {
            alert(trans);
          })
        }
        else {
          this.router.navigate(["/" + this.pageLanguageId,'admin','companies']);
        }
      });
    }

  }

  public UpdateSubmit(updateExpirationDate: boolean) {
  
      if (this.Password !== this.RePassword) {
        this.translateService.get("Passwords must be same").subscribe(trans => {
          alert(trans);
        })
      }
      else {
        if (typeof this.Password !== "undefined" || this.Password !== "") {
          this._model.password = this.Password;
        }
        this._model.expirationDate = GetDatePicker("expirationDate2");
        this._model.languageDefault = parseInt("" + this._model.languageDefault);
        this._model.updateExpirationDate = updateExpirationDate;
        this.companyService.UpdateCompany(this._model).subscribe(data => {
          if (data.code === -1) {
            this.translateService.get("User exists with this user name.").subscribe(trans => {
              alert(trans);
            })
          }
          else if (data.code === 0) {
            this.translateService.get("Unknow error").subscribe(trans => {
              alert(trans);
            })
          }
          else {
            this.translateService.get("Data have been updated").subscribe(trans => {
              alert(trans);
            })
            alert("Data have been updated");
          }
        });
      }
  }

  public SingInAsCompany() {
    this.userService.SignInAsCompany(this.updateId).subscribe(result => {
      if (result.code === 1) {
        localStorage.setItem('jwt_token', result.jwt);
        localStorage.setItem('jwt_sys_role', "false");
        this.router.navigate(['/' + this.pageLanguageId,'company']);
      }
    });
  }

}
