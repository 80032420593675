
<TopMenu [LanguageId]="pageLanguageId" Text="" Active="user"></TopMenu>

<div class="content_640">
  <div class="sign-in-form-box">
    <div class="registration_white_text" style="display: contents;font-size: 1rem;">
      {{ 'New password'|translate }}
    </div>
    <div style="">
      <div class="registration_white_text default_input_box" style="color: #4d4d4d;">
       <br /> {{ 'Your email'|translate}}:<br>
        &nbsp;&nbsp;&nbsp;{{ companyDetail.userName }}
      </div>
      <div class="default_input_box max_input" >
        <input type="password" [(ngModel)]="newPassword.currentPassword" class="default_input" placeholder="{{ 'CURRENT PASSWORD'|translate }}" autcomplete="false" />
      </div>
      <div class="default_input_box max_input" >
        <input type="password" [(ngModel)]="newPassword.newPassword" class="default_input" placeholder="{{ 'PASSWORD'|translate }}*" autcomplete="false" />
      </div>
      <div class="default_input_box max_input" >
        <input type="password" [(ngModel)]="ConfirmPassword" class="default_input" placeholder="{{ 'CONFIRM PASSWORD'|translate }}*" autcomplete="false" />
      </div>
    </div>
    <div style="">
      <button class="button_black" (click)="SaveNewPassword()">{{ 'Save'|translate }}</button>
    </div>
  </div>
  <div>
    <div style="  display: flex;">
      <div class="sign-in-form-box">
        <div class="registration_white_text" style="margin-top:5rem;display: contents;font-size: 1rem;margin-bottom:2rem;">
          {{ 'Invoice information'|translate }}
        </div>
        <div class="default_input_box max_input">
          <input type="text" [(ngModel)]="companyDetail.companyName" class="default_input" placeholder="{{ 'COMPANY NAME'|translate }}*" autcomplete="false" />
          <!--<img *ngIf="EmailError" class="error_validation" src="/assets/images/error.svg" />-->
        </div>
        <div class="default_input_box max_input" style="float: left;margin-right: 15px;">
          <input type="text" [(ngModel)]="companyDetail.street" class="default_input" placeholder="{{ 'ADDRESS'|translate }}*" autcomplete="false" />
        </div>
        <div class="default_input_box max_input_small" style="float: left;">
          <input type="text" [(ngModel)]="companyDetail.streetNumber" class="default_input" placeholder="{{ 'NUMBER'|translate }}*" autcomplete="false" />
        </div>
        <div class="default_input_box max_input" style="float: left;margin-right: 15px;">
          <input type="text" [(ngModel)]="companyDetail.city" class="default_input" placeholder="{{ 'CITY'|translate }}*" autcomplete="false" />
        </div>
        <div class="default_input_box max_input_small" style="float: left;">
          <input type="text" [(ngModel)]="companyDetail.postCode" class="default_input" placeholder="{{ 'POST CODE'|translate }}*" autcomplete="false" />
        </div>
        <div class="default_input_box max_input">
          <input type="text" [(ngModel)]="companyDetail.country" class="default_input" placeholder="{{ 'COUNTRY'|translate }}*" autcomplete="false" />
        </div>
        <div class="default_input_box max_input">
          <input type="text" [(ngModel)]="companyDetail.companyVAT" class="default_input" placeholder="{{ 'VAT CODE'|translate }}*" autcomplete="false" />
        </div>
        <div class="registration_white_text">
          *{{ 'necessary data'|translate }}
        </div>
        <div class="default_input_box" style="display: table;    margin: 2rem 0rem">
          <CheckBox Text="THE SHIPPING ADDRESS IS DIFFERENT" [OnChange]="step2CheckBoxEvent"></CheckBox>
        </div>
        <div class="step2registration" [ngStyle]="step2registration_style">
          <div class="default_input_box max_input">
            <input type="text" [(ngModel)]="companyDetail.deliveryCompanyName" class="default_input" placeholder="{{ 'COMPANY NAME'|translate }}*" autcomplete="false" />
          </div>
          <div class="default_input_box max_input" style="float: left;margin-right: 15px;">
            <input type="text" [(ngModel)]="companyDetail.deliveryStreet" class="default_input" placeholder="{{ 'ADDRESS'|translate }}*" autcomplete="false" />
          </div>
          <div class="default_input_box max_input_small" style="float: left;">
            <input type="text" [(ngModel)]="companyDetail.deliveryStreetNumber" class="default_input" placeholder="{{ 'NUMBER'|translate }}*" autcomplete="false" />
          </div>
          <div class="default_input_box max_input" style="float: left;margin-right: 15px;">
            <input type="text" [(ngModel)]="companyDetail.deliveryCity" class="default_input" placeholder="{{ 'CITY'|translate }}*" autcomplete="false" />
          </div>
          <div class="default_input_box max_input_small" style="float: left;">
            <input type="text" [(ngModel)]="companyDetail.deliveryPostCode" class="default_input" placeholder="{{ 'POST CODE'|translate }}*" autcomplete="false" />
          </div>
          <div class="default_input_box max_input">
            <input type="text" [(ngModel)]="companyDetail.deliveryCountry" class="default_input" placeholder="{{ 'COUNTRY'|translate }}*" autcomplete="false" />
          </div>
        </div>
      </div>

    </div>
    <div style="    padding: 0rem 1.5rem;">
      <button class="button_black" (click)="SaveDetail()">{{ 'Save'|translate }}</button>
    </div>
  </div>
  <div style="    padding: 1.5rem 1.5rem;">
    <div class="registration_white_text" style="display: contents;font-size: 1rem;">
      {{ 'Credit card information are stored in Stripe'|translate }}<br><br>
      {{ 'Set a new credit card'|translate }}
    </div>
    <div class="default_input_box max_input">
      <input type="text" [(ngModel)]="AconutHolder" class="default_input" placeholder="{{ 'ACCOUNT HOLDER'|translate }}*" autcomplete="false" />
    </div>
    <div class="default_input_box max_input">
      <input type="text" (keyup)="CreditCardNumberFormat()" [(ngModel)]="CreditCardNumber" class="default_input" placeholder="{{ 'CREDIT CART NUMBER'|translate }}*" autcomplete="false" />
    </div>
    <div class="default_input_box max_input" style="float: left;margin-right: 15px;">
      <select class="default_input" [(ngModel)]="CreditCardMonth" style="width: 75px;margin-right:10px">
        <option *ngFor="let i of months" value="{{ i }}">{{ i }}</option>
      </select>

      <select class="default_input" [(ngModel)]="CreditCardYear" style="width: 75px;margin-right: 10px;">
        <option *ngFor="let i of years" value="{{ i }}">{{ i }}</option>
      </select>
      <div class="registration_white_text" style="display: contents;font-size: 1rem;">
        {{ 'Expiration date'| translate }}*
      </div>
    </div>
    <div class="default_input_box max_input">
      <input type="number" [(ngModel)]="CreditCardCVV" [ngClass]="CreditCardCVVClass" placeholder="000" min="0" autcomplete="false" style=" width:100px;margin-right: 10px;" />
      <div class="registration_white_text" style="display: contents;font-size: 1rem;">
        {{ 'CVV'| translate }}*
      </div>
    </div>
    <div style="">
      <button class="button_black" (click)="SaveCreditCard()">{{ 'Save'|translate }}</button>
    </div>
  </div>
</div>

<div class="footer-black"></div>


<Toast Message="Item updated"></Toast>
