import { OnInit, Component } from "@angular/core";
import { OrderService } from "../../../services/user/OrderList";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CompanyService } from "../../../services/company/CompanyService";


@Component({
  templateUrl: "rating.page.html"
})
export class RatingPage implements OnInit {

  public langArray = ['en', 'it', 'eu', 'us', 'chf', 'ge', 'sk', 'cs', 'pl', 'cn', 'ru', 'es', 'nl', 'fr', 'dk', 'se', 'hr', 'hu', 'pt', 'no', 'ar'];


  private type = "";
  private parentId = -1;
  private productId = -1;
  private companyId = -1;
  private langId = -1;

  public showPripadvisor = false;
  public showGoogle = false;
  public showYelp= false;
  public showThefork = false;

  public linkPripadvisor = "";
  public linkGoogle = "";
  public linkYelp = "";
  public linkThefork = "";

  constructor(private orderService: OrderService, public route: ActivatedRoute, public router: Router,
    public translateService: TranslateService, public companyService: CompanyService) {

  }

    ngOnInit(): void {
      this.route.params.subscribe(routeParams => {

        this.companyId = routeParams.companyId;
        this.langId = this.langArray.findIndex(function (item) { return item === routeParams.languageId; });
        this.translateService.use(routeParams.languageId);

        this.type = routeParams.type;
        this.parentId = routeParams.parentId;
        this.productId = routeParams.productId;

        this.companyService.GetPublicCompany(""+this.companyId, this.langId).subscribe(result => {

          if (result.yelpLink !== null && result.yelpLink !== "") {
            this.showYelp = true;
            this.linkYelp = result.yelpLink;
          }

          if (result.googleLink !== null && result.googleLink !== "") {
            this.showGoogle = true;
            this.linkGoogle = result.googleLink;
          }


          if (result.theforkLink !== null && result.theforkLink !== "") {
            this.showThefork = true;
            this.linkThefork = result.theforkLink;
          }

          if (result.tripadvisorLink !== null && result.tripadvisorLink !== "") {
            this.showPripadvisor = true;
            this.linkPripadvisor = result.tripadvisorLink;
          }


        });

      });
    }

  public BackButton() {
    if (this.productId > -1)
      this.router.navigate([this.companyId, this.langArray[this.langId],this.type, this.parentId, this.productId]);
    else
      this.router.navigate([this.companyId, this.langArray[this.langId]]);
  }

  public OpenUrl(id) {
    if (id === 1) {
      window.open( this.linkPripadvisor);
    }
    if (id === 2) {
      window.open( this.linkGoogle );
    }
    if (id === 3) {
      window.open(  this.linkYelp );
    }
    if (id === 4) {
      window.open( this.linkThefork );
    }
  }
}
