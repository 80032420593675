import { Component } from '@angular/core';


@Component({
  selector: 'TopBar',
  templateUrl: './topbar.component.html',

})
export class TopBarComponent {
}
