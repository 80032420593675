
<div class="order-public-header">
  <div  class="content-public-back" (click)="BackButton()">
  </div>
  <div class="order-public-header-icon">
    <img src="/assets/images/help.svg" style="width: 1.5rem;" class="order-public-header-icon-svg" />
    <span class="order-public-header-icon-text">{{ 'Help' |translate }}</span>
  </div>

</div>
<div class="order-help-message-box">
  {{ 'order-help-message'|translate}}
</div>
