import { Component, Input } from "@angular/core";



@Component({
  selector: 'StaticBox',
  templateUrl: 'staticbox.component.html'
})
export class StaticBoxComponent {

  @Input()
  Text = ""

  @Input()
  CssClass = ""

}
