
<TopMenu [LanguageId]="pageLanguageId" Text="" Active="menu"></TopMenu>

<div class="content_full">
  <div style="width: 230px;    margin: 0px auto;    padding: 2rem;padding-bottom: 1rem;">
    <LangSelectBox [SelectedValue]="selectedLangId"  [DataSource]="LangSelectBoxDataSource" [SelectedChange]="ChangeLangEvent2" ></LangSelectBox>
  </div>
  <div>
    <div style=" margin: 0px auto;text-align: center;padding-bottom: 2rem;">
      <a class="sign-in-href" href="https://bit.ly/stocko2e" target="_blank">{{ 'You can download images from our library'| translate }}</a>
    </div>
  </div>
  <a (click)="BackButton()" [routerLink]="[]" class="reqistration_button_prev_a mobile-hide" style="margin-top:1.5rem"><div class="reqistration_button_prev"></div></a>
  <div class="categories-div" >
    <CategoryCarusal [LongType]="LongCarusal" [DataSource]="carusalItemSource" style="display:flex;margin:0px auto;" id="category_items_carusal" [SelectedIndex]="carusalDetailIndex" [OnSelectedChanged]="CarusalLongOnSelectedChanged"></CategoryCarusal>

  </div>
  <div   class="menu-detail-layout-div">{{ 'Layout'| translate }}</div>
  <div   class="categories-div" style="padding-top:1.5rem;display:flex;">

    <div style="display:flex;margin:0px auto;">
      <div [ngClass]="template2css" (click)="ChangeTemplage(2)">
        <img *ngIf="VisibleTemplate == 1" src="/assets/images/template2.png" style="width:6rem"  />
        <img *ngIf="VisibleTemplate == 2" src="/assets/images/template2s.png" style="width:6rem"  />
        <br />
        <span>{{ 'List'| translate }}</span>
      </div>
      <div [ngClass]="template1css" (click)="ChangeTemplage(1)">
        <img *ngIf="VisibleTemplate == 2" src="/assets/images/template1.png" style="width:6rem"  />
        <img *ngIf="VisibleTemplate == 1" src="/assets/images/template1s.png" style="width:6rem"  />
        <br />
        <span>{{ 'Description'| translate }}</span>
      </div>

    </div>
  </div>
  <div   class="menu-detail-layout-div">{{ 'Description'| translate }}</div>
  <div *ngIf="VisibleTemplate == 1">
    <div class="content_680">
      <div class="input-detail-div">
        <label for="detail_title">{{ 'Title'| translate }}:</label>
        <input id="detail_title" [(ngModel)]="detailTitle" />
      </div>
      <div class="input-detail-div">
        <label for="detail_desc">{{ 'Description'| translate }}:</label>
        <textarea id="detail_desc" [(ngModel)]="detailDesc"></textarea>
      </div>
      <div class="input-detail-div" style="height: 130px;">
        <label for="detail_desc">Info:</label>
        <CheckBox Text="FROZEN PRODUCT" [DefaultValue]="detailFrozenProduct" [InputClass]="AcceptPolicyClass" style="display: table;float: left;padding-right: 6px;" [OnChange]="FrozenProductEvent">
        </CheckBox>
        <br /><br>
        <CheckBox Text="Show price" [DefaultValue]="showPrice" [InputClass]="AcceptPolicyClass" style="display: table;float: left;padding-right: 6px;" [OnChange]="ShowPriceEvent">
        </CheckBox>
      </div>
      <div class="input-detail-div" style="display: table;">
        <label for="detail_desc">{{ 'Alergens'| translate }}:</label>
        <div>
          <AllergenSelectBox [SelectedValue]="selectedAllergens" [DataSource]="allergens" [SelectedChange]="SelectedAllergensChanged"> </AllergenSelectBox>
          <!--<select2 id="allergnesId" [data]="allergens" [value]="selectedAllergens" [options]="options" (valueChanged)="selectedAllergensChange($event)"></select2>-->
          <!--<div *ngFor="let allergen of allergens; let _index = index">
        <CheckBox #allergenList [DefaultValue]="allergenDefaultValues[_index]" [Text]="allergen.name" [Id]="allergen.name" [InputClass]="AcceptPolicyClass" style="display: table;float: left;padding-right: 6px;" [OnChangeWithId]="OnChangeWithId">
        </CheckBox>
      </div>-->
        </div>
      </div>
      <div class="input-detail-div">
        <label for="detail_price">{{ 'Show price'| translate }}:</label>
       </div>
      <div class="input-detail-div">
        <label for="detail_price">{{ 'Price'| translate }}:</label>
        <input type="number" id="detail_price" [(ngModel)]="detailPrice" style="max-width:150px" />
      </div>
      <div *ngIf="selectedLangId != defaultLanguageId" style="margin:2rem auto;display: table;">
        <button class="button_black" (click)="GetDefaultText()">{{ 'get default text'| translate }}</button>
      </div>
      <div *ngIf=" selectedLangId != defaultLanguageId" style="margin:2rem auto;display: table;">
        <button class="button_black" (click)="GetTranslationDetailTempl1()">{{ 'translate'| translate }}</button>
      </div>
      <div style="margin: 2rem auto 0px;    display: table;">
        <button class="button_black" (click)="SaveItemDetail()">{{ 'Save'| translate }}</button>
      </div>

    </div>
  </div>


  <div *ngIf="VisibleTemplate == 2">
    <div class="content_680" style="    overflow: hidden;">
      <ListItems [ListItems]="ListItems" [RemoveItemEvent]="RemoveListItemEvent"></ListItems>

      <div style="width:75%;display: table;">
        <div *ngIf=" selectedLangId != defaultLanguageId" style="margin:2rem auto;display: table;">

          <button class="button_black" (click)="GetTranslationDetailTempl2()">{{ 'translate'| translate }}</button>
        </div>
        <div style="    display: table;  margin: 2rem auto;">
          <button class="button_black" (click)="SaveItemDetail()">{{ 'Save'| translate }}</button>
        </div>
      </div>

    </div>

  </div>

  <a (click)="BackButton()" [routerLink]="[]" class="reqistration_button_next_a desktop-hide"><div class="menu_button_prev"></div></a>
</div>




  <!--<a *ngIf="PageState == 0 && ParentCategoryModelId > 0" [routerLink]="['/' + this.pageLanguageId,'company','menu','categories']" class="reqistration_button_next_a"><div class="menu_button_next"></div></a>-->
  <div class="footer-black"></div>

  <Toast Message="Item updated"></Toast>
