/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./staticbox.component";
var styles_StaticBoxComponent = [];
var RenderType_StaticBoxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StaticBoxComponent, data: {} });
export { RenderType_StaticBoxComponent as RenderType_StaticBoxComponent };
export function View_StaticBoxComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.CssClass; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.Text)); _ck(_v, 3, 0, currVal_1); }); }
export function View_StaticBoxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "StaticBox", [], null, null, null, View_StaticBoxComponent_0, RenderType_StaticBoxComponent)), i0.ɵdid(1, 49152, null, 0, i3.StaticBoxComponent, [], null, null)], null, null); }
var StaticBoxComponentNgFactory = i0.ɵccf("StaticBox", i3.StaticBoxComponent, View_StaticBoxComponent_Host_0, { Text: "Text", CssClass: "CssClass" }, {}, []);
export { StaticBoxComponentNgFactory as StaticBoxComponentNgFactory };
