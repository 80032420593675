/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../components/TopMenu/topmenu.component.ngfactory";
import * as i4 from "../../../components/TopMenu/topmenu.component";
import * as i5 from "@angular/router";
import * as i6 from "./invoice.page";
import * as i7 from "../../../services/company/CompanyService";
var styles_InvoicePage = [];
var RenderType_InvoicePage = i0.ɵcrt({ encapsulation: 2, styles: styles_InvoicePage, data: {} });
export { RenderType_InvoicePage as RenderType_InvoicePage };
function View_InvoicePage_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["style", "padding: 0.5rem 0rem; display: block;"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" - ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.description; _ck(_v, 1, 0, currVal_0); }); }
function View_InvoicePage_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "tr", [["class", "invoice_tr"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "td", [["class", "invoice_td"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InvoicePage_2)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ": ", " \u20AC"])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 4, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "a", [["class", "invoice_link"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "img", [["class", "invoice_link_img"], ["src", "/assets/images/print.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, [" ", " "])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = _v.context.$implicit.lines; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.created.replace("T", " ").replace("Z", " "); _ck(_v, 2, 0, currVal_0); var currVal_2 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("total")); var currVal_3 = (_v.context.$implicit.total / 100); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.invoicePdf, ""); _ck(_v, 10, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("print")); _ck(_v, 12, 0, currVal_5); }); }
export function View_InvoicePage_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "TopMenu", [["Active", "invoices"], ["Text", ""]], null, null, null, i3.View_TopMenuComponent_0, i3.RenderType_TopMenuComponent)), i0.ɵdid(1, 114688, null, 0, i4.TopMenuComponent, [i5.Router], { Text: [0, "Text"], Active: [1, "Active"], LanguageId: [2, "LanguageId"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "content_640"], ["style", "padding-top: 2rem;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "invoice_title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ":"])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, null, 2, "table", [["class", "invoice_table"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InvoicePage_1)), i0.ɵdid(8, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 0, "div", [["class", "footer-black"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = "invoices"; var currVal_2 = _co.pageLanguageId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.invoices; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_3 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("Historical invoices")); _ck(_v, 4, 0, currVal_3); }); }
export function View_InvoicePage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_InvoicePage_0, RenderType_InvoicePage)), i0.ɵdid(1, 114688, null, 0, i6.InvoicePage, [i7.CompanyService, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvoicePageNgFactory = i0.ɵccf("ng-component", i6.InvoicePage, View_InvoicePage_Host_0, {}, {}, []);
export { InvoicePageNgFactory as InvoicePageNgFactory };
