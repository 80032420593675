/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./checkbox.component";
var styles_CheckBoxComponent = [];
var RenderType_CheckBoxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CheckBoxComponent, data: {} });
export { RenderType_CheckBoxComponent as RenderType_CheckBoxComponent };
export function View_CheckBoxComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "label", [["class", "checkbox-container"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 5, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "ngModelChange"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.onChange($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = ((_co.DefaultValue = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 16384, null, 0, i2.CheckboxControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i0.ɵdid(6, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(8, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "span", [["class", "checkmark"]], null, null, null, null, null)), i0.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(11, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.DefaultValue; _ck(_v, 6, 0, currVal_8); var currVal_9 = "checkmark"; var currVal_10 = _co.InputClass; _ck(_v, 11, 0, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.Text)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 8).ngClassValid; var currVal_6 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_CheckBoxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "CheckBox", [], null, null, null, View_CheckBoxComponent_0, RenderType_CheckBoxComponent)), i0.ɵdid(1, 49152, null, 0, i4.CheckBoxComponent, [], null, null)], null, null); }
var CheckBoxComponentNgFactory = i0.ɵccf("CheckBox", i4.CheckBoxComponent, View_CheckBoxComponent_Host_0, { Text: "Text", OnChange: "OnChange", OnChangeWithId: "OnChangeWithId", InputClass: "InputClass", Id: "Id", DefaultValue: "DefaultValue" }, {}, []);
export { CheckBoxComponentNgFactory as CheckBoxComponentNgFactory };
