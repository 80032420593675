<div class="header-black-logo">
  <div class="header-black-logo-div">
    <img src="/assets/images/logo.svg" class="header-black-logo-img" onclick="window.open('https://bit.ly/ord2eatcom', '_blank');" />
  </div>
</div>
<div class="content_480" *ngIf="PageState == 0" style="min-height: calc(100vh - 6rem);">

  <h1 class="reqistration_title" style=" margin-bottom: 1rem;">{{ '1. registration'| translate }}</h1>
  <div style=" text-align: center; color: #4d4d4d; text-transform: uppercase; padding: 1rem; letter-spacing: 1px;"> {{ 'Get sterted'| translate }}</div>
  <div class="sign-in-form-box">
    <div class="default_input_box">
      <input type="text" [(ngModel)]="registrationStateService.UserName" [ngClass]="UserNameClass" placeholder="{{ 'EMAIL'| translate }}*" autcomplete="false" />
      <!--<img *ngIf="EmailError" class="error_validation" src="/assets/images/error.svg" />-->
    </div>
    <div class="default_input_box">
      <input type="password" [(ngModel)]="registrationStateService.Password" [ngClass]="PasswordClass" placeholder="{{ 'PASSWORD'| translate }}*" autcomplete="false" />
    </div>
    <div class="default_input_box">
      <input type="password" [(ngModel)]="registrationStateService.ConfirmPassword" [ngClass]="ConfirmPasswordClass" placeholder="{{ 'CONFIRM PASSWORD'| translate }}*" autcomplete="false" />
    </div>
    <div class="default_input_box" style="display: table;    margin: 2rem auto;">
      <CheckBox Text="ACCEPT" [InputClass]="AcceptPolicyClass" style="display: table;float: left;padding-right: 6px;" [OnChange]="AcceptPolicyEvent">
      </CheckBox><a href="https://bit.ly/o2edocument" target="_blank" class="registration_link">{{ 'policy and privacy'| translate }}*</a>
    </div>
    <div class="registration_white_text">
      * {{ 'necessary data'| translate }}
    </div>
    <div style="margin:1.5rem auto;display: table;">
      <button class="button_black" (click)="ValidationPageState0()">{{ 'next'| translate }} <span style=" font-size: 1.2rem;">&#8250;</span></button>
    </div>
  </div>
</div>
<div class="content_640" *ngIf="PageState == 1">
  <!--<a [routerLink]="['/registration/step1/']" class="reqistration_button_prev_a" style="margin-top:1.5rem"><div class="reqistration_button_prev"></div></a>-->
  <h1 class="reqistration_title" style="margin-bottom: 1rem;margin-top: 2rem;">{{ '2. product'| translate }}</h1>
  <div style=" text-align: center; color: #4d4d4d; text-transform: uppercase; padding: 1rem; letter-spacing: 1px;    width: 350px;
    margin: 0px auto;"> {{ 'Get sterted'| translate }}</div>

  <div class="product-type-1">
    <div class="product-type-1-name">{{ product.name }}</div>
    <img class="product-type-1-img" src="{{ product.imagePath }}" />
    <div class="product-type-1-desc">
      {{ product.desc }}
    </div>
    <div class="product-type-1-type">
      <button class="button_black" (click)="SelectedProduct(product.id,1)" style="width: 250px;margin-bottom:1rem;">{{ '30 days'| translate }} = {{ product.price30 }} {{ product.currency}}</button><br />
      <!--<button class="button_black" (click)="SelectedProduct(product.id,2)" style="width: 250px;margin-bottom:1rem;">{{ '180 days'| translate }} =  {{ product.price180 }} {{ product.currency}}</button><br />-->
      <button class="button_black" (click)="SelectedProduct(product.id,3)" style="width: 250px;margin-bottom:1rem;">{{ '365 days'| translate }} = {{ product.price365 }} {{ product.currency}}</button><br />
    </div>
  </div>

</div>

<div class="content_640" *ngIf="PageState == 2">
  <!--<a [routerLink]="['/'+this.pageLanguageId,'registration']" class="reqistration_button_prev_a mobile-hide" style="margin-top:1.5rem"><div class="reqistration_button_prev"></div></a>-->
  <h1 class="reqistration_title" style="margin-top:2.5rem">{{ '3. invoice and shipping data'| translate }}</h1>
  <div class="sign-in-form-box">
    <div class="default_input_box max_input">
      <input type="text" [(ngModel)]="registrationStateService.Heading" [ngClass]="HeadingClass" placeholder="{{ 'COMPANY NAME'| translate }}*" autcomplete="false" />
      <!--<img *ngIf="EmailError" class="error_validation" src="/assets/images/error.svg" />-->
    </div>
    <div class="default_input_box max_input" style="float: left;margin-right: 15px;">
      <input type="text" [(ngModel)]="registrationStateService.Address" [ngClass]="AddressClass" placeholder="{{ 'ADDRESS'| translate }}*" autcomplete="false" />
    </div>
    <div class="default_input_box max_input_small" style="float: left;">
      <input type="text" [(ngModel)]="registrationStateService.AddressNumber" [ngClass]="AddressNumberClass" placeholder="{{ 'NUMBER'| translate }}*" autcomplete="false" />
    </div>
    <div class="default_input_box max_input" style="float: left;margin-right: 15px;">
      <input type="text" [(ngModel)]="registrationStateService.City" [ngClass]="CityClass" placeholder="{{ 'CITY'| translate }}*" autcomplete="false" />
    </div>
    <div class="default_input_box max_input_small" style="float: left;">
      <input type="text" [(ngModel)]="registrationStateService.PostCode" [ngClass]="PostCodeClass" placeholder="{{ 'POST CODE' | translate }}*" autcomplete="false" />
    </div>
    <div class="default_input_box max_input">
      <input type="text" [(ngModel)]="registrationStateService.Country" [ngClass]="CountryClass" placeholder="{{ 'COUNTRY'| translate }}*" autcomplete="false" />
    </div>
    <div class="default_input_box max_input">
      <input type="text" [(ngModel)]="registrationStateService.VatCode" [ngClass]="VatCodeClass" placeholder="{{ 'VAT CODE'| translate }}*" autcomplete="false" />
    </div>
    <div class="registration_white_text">
      * {{ 'necessary data'| translate }}
    </div>
    <div class="default_input_box" style="display: table;    margin: 2rem 0rem">
      <CheckBox Text="THE SHIPPING ADDRESS IS DIFFERENT" [OnChange]="step2CheckBoxEvent"></CheckBox>
    </div>
    <div class="step2registration" [ngStyle]="step2registration_style">
      <div class="default_input_box max_input">
        <input type="text" [(ngModel)]="registrationStateService.DeliveryHeading" [ngClass]="DeliveryHeadingClass" placeholder="{{ 'COMPANY NAME'| translate }}*" autcomplete="false" />
      </div>
      <div class="default_input_box max_input" style="float: left;margin-right: 15px;">
        <input type="text" [(ngModel)]="registrationStateService.DeliveryAddress" [ngClass]="DeliveryAddressClass" placeholder="{{ 'ADDRESS'| translate }}*" autcomplete="false" />
      </div>
      <div class="default_input_box max_input_small" style="float: left;">
        <input type="text" [(ngModel)]="registrationStateService.DeliveryAddressNumber" [ngClass]="DeliveryAddressNumberClass" placeholder="{{ 'NUMBER'| translate }}*" autcomplete="false" />
      </div>
      <div class="default_input_box max_input" style="float: left;margin-right: 15px;">
        <input type="text" [(ngModel)]="registrationStateService.DeliveryCity" [ngClass]="DeliveryCityClass" placeholder="{{ 'CITY'| translate }}*" autcomplete="false" />
      </div>
      <div class="default_input_box max_input_small" style="float: left;">
        <input type="text" [(ngModel)]="registrationStateService.DeliveryPostCode" [ngClass]="DeliveryPostCodeClass" placeholder="{{ 'POST CODE'| translate }}*" autcomplete="false" />
      </div>
      <div class="default_input_box max_input">
        <input type="text" [(ngModel)]="registrationStateService.DeliveryCountry" [ngClass]="DeliveryCountryClass" placeholder="{{ 'COUNTRY'| translate }}*" autcomplete="false" />
      </div>
      <!--<div class="default_input_box max_input">
        <input type="text" [(ngModel)]="registrationStateService.DeliveryVatCode" [ngClass]="DeliveryVatCodeClass" placeholder="{{ 'VAT CODE'| translate }}*" autcomplete="false" />
      </div>-->
    </div>
  </div>
  <div style="margin:1.5rem auto;display: table;">
    <button class="button_black" (click)="BackToState0()" style="margin-right:1rem"><span style=" font-size: 1.2rem;">&#8249;</span> {{ 'previous'| translate }} </button>
    <button class="button_black" (click)="ValidationPageState2()">{{ 'next'| translate }} <span style=" font-size: 1.2rem;">&#8250;</span></button>
  </div>
</div>

<div class="content_480" *ngIf="PageState == 3">
  <!--<a [routerLink]="['/'+this.pageLanguageId,'registration','step2']" class="reqistration_button_prev_a mobile-hide" style="margin-top:1.5rem"><div class="reqistration_button_prev"></div></a>-->
  <h1 class="reqistration_title" style="margin-top:2.5rem;margin-bottom: 3.5rem;">{{ '3. payment'| translate }}</h1>
  <div class="blackTextBox">{{ 'TOTAL'| translate }}: {{ calculatedTotalPrice }} {{ product.currency }}</div>
  <div class="sign-in-form-box">
    <div class="default_input_box max_input">
      <input type="text" [(ngModel)]="registrationStateService.AccountHolder" [ngClass]="AccountHolderClass" placeholder="{{ 'ACCOUNT HOLDER'| translate }}*" autcomplete="false" />
    </div>
    <div class="default_input_box max_input">
      <input type="text" (keyup)="CreditCardNumberFormat()" [(ngModel)]="registrationStateService.CreditCardNumber" [ngClass]="CreditCardNumberClass" placeholder="{{ 'CREDIT CART NUMBER'| translate }}*" autcomplete="false" />
    </div>
    <div class="default_input_box max_input" style="float: left;margin-right: 15px;">
      <select class="default_input" [(ngModel)]="registrationStateService.CreditCardMonth" style="width: 75px;margin-right:10px">
        <option *ngFor="let i of months" value="{{ i }}">{{ i }}</option>
      </select>

      <select class="default_input" [(ngModel)]="registrationStateService.CreditCardYear" style="width: 75px;margin-right: 10px;">
        <option *ngFor="let i of years" value="{{ i }}">{{ i }}</option>
      </select>
      <div class="registration_white_text" style="display: contents;font-size: 1rem;">
        {{ 'Expiration date'| translate }}*
      </div>
    </div>
    <div class="default_input_box max_input">
      <input type="number" [(ngModel)]="registrationStateService.CreditCardCVV" [ngClass]="CreditCardCVVClass" placeholder="000" min="0" autcomplete="false" style=" width:100px;margin-right: 10px;" />
      <div class="registration_white_text" style="display: contents;font-size: 1rem;">
        {{ 'CVV'| translate }}*
      </div>
    </div>
    <div class="registration_white_text">
      *{{ 'necessary data'| translate }}
    </div>

    <div style=" display: table; margin: 0px auto;">
      <re-captcha (resolved)="captchaResolved($event)" 
                  siteKey="6Lcw6KwZAAAAANslpvSOgQ7tkrQ7zmW-hNEVodHP">
      </re-captcha>
    </div>
    <div style="margin:3.5rem auto 0rem;display: table;">
      <button class="button_black" (click)="BackToState2()" style="margin-right:1rem"><span style=" font-size: 1.2rem;">&#8249;</span> {{ 'previous'| translate }} </button>
      <button class="button_black" style=" padding: 12px 2.2rem;" (click)="ValidationPageState3()">{{ 'Pay'| translate }}</button>
    </div>
  </div>
  
  <!--<a [routerLink]="['/'+this.pageLanguageId,'registration','step2']" class="reqistration_button_next_a desktop-hide"><div class="menu_button_prev" style="    margin-top: 2rem;"></div></a>-->

</div>

<!--<a *ngIf="PageState == 0" (click)="ValidationPageState0()" [routerLink]="[]" class="reqistration_button_next_a"><div class="reqistration_button_next"></div></a>-->
<!--<div class="registration-step2-links">
  <a *ngIf="PageState == 2" [routerLink]="['/'+this.pageLanguageId,'registration']" class="reqistration_button_next_a desktop-hide"><div class="menu_button_prev"></div></a>
  <a *ngIf="PageState == 2" (click)="ValidationPageState2()" [routerLink]="[]" class="reqistration_button_next_a"><div class="reqistration_button_next"></div></a>
</div>-->
<div class="footer-black"></div>
