
<div class="slider-div" style="display:flex;margin:0px auto;" id="category_items_carusal">
  <div class="carusal_button_left" (click)="Left()"></div>
  <div class="carusal-content">
    <div class="carusal-content-wrap" [ngStyle]="posStyle">
      <div #carusalCategories  *ngFor="let item of _dataSource;let _index = index" (click)="OnClick(_index)" [class]="(_selectedIndex == _index ? 'slider-div-item-active':'slider-div-item')">
        <img *ngIf="LongType" src="{{item.imageBase64}}" style="width:8.33rem!important">
        <img *ngIf="LongType == false" src="{{item.imageBase64}}" >
      </div>
    </div>
  </div>
  <div class="carusal_button_right" (click)="Right()"></div>
</div>
