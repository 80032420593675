/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./pages/admin/signin/signin.page.ngfactory";
import * as i2 from "./pages/admin/signin/signin.page";
import * as i3 from "./services/user/UserService";
import * as i4 from "@angular/router";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./components/TopBar/topbar.component.ngfactory";
import * as i7 from "./components/TopBar/topbar.component";
import * as i8 from "./components/LeftMenu/leftmenu.component.ngfactory";
import * as i9 from "./components/LeftMenu/leftmenu.component";
import * as i10 from "@angular/common";
import * as i11 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sign-in-page", [], null, null, null, i1.View_SignInPage_0, i1.RenderType_SignInPage)), i0.ɵdid(1, 114688, null, 0, i2.SignInPage, [i3.UserService, i4.Router, i5.TranslateService, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "TopBar", [], null, null, null, i6.View_TopBarComponent_0, i6.RenderType_TopBarComponent)), i0.ɵdid(2, 49152, null, 0, i7.TopBarComponent, [], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "LeftMenuComponent", [], null, null, null, i8.View_LeftMenuComponent_0, i8.RenderType_LeftMenuComponent)), i0.ɵdid(4, 49152, null, 0, i9.LeftMenuComponent, [i10.Location], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(7, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 7, 0); }, null); }
function View_AppComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "body", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(2, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i0.ɵdid(4, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i0.ɵdid(6, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i0.ɵdid(8, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.AppState == 3); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.AppState == 4); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.AppState == 1); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.AppState == 2); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i11.AppComponent, [i10.Location, i4.Router, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i11.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
