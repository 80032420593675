/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./toast.component";
var styles_ToastComponent = [];
var RenderType_ToastComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ToastComponent, data: {} });
export { RenderType_ToastComponent as RenderType_ToastComponent };
function View_ToastComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "cm_toast"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.Message)); _ck(_v, 1, 0, currVal_0); }); }
export function View_ToastComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ToastComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.Visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ToastComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "Toast", [], null, null, null, View_ToastComponent_0, RenderType_ToastComponent)), i0.ɵdid(1, 49152, null, 0, i3.ToastComponent, [], null, null)], null, null); }
var ToastComponentNgFactory = i0.ɵccf("Toast", i3.ToastComponent, View_ToastComponent_Host_0, { Message: "Message" }, {}, []);
export { ToastComponentNgFactory as ToastComponentNgFactory };
