import { Component, Input, ViewChildren, ComponentRef, AfterViewInit, ElementRef, QueryList } from "@angular/core";
import { Subject } from "rxjs";


@Component({
  selector: 'CategoryCarusal',
  templateUrl:'categorycarusal.component.html'
})
export class CategoryCarusalComponent implements AfterViewInit {

  public _dataSource = [];

  @Input("DataSource")
  set DataSource(value: any[]) {
    this._dataSource = value;
  }
  get DataSource(): any[] {
    return this._dataSource;
  }

  public _selectedIndex = 0;

  @Input("SelectedIndex")
  set SelectedIndex(value: number) {
    this._selectedIndex = value;
    if (this.LongType === true) {
      if (window.screen.width < 768) {
        this.movePos = this._selectedIndex;
      }
      else {
        this.movePos = this._selectedIndex -1;
      }
    }
    else {
      if (window.screen.width < 768) {
        this.movePos = this._selectedIndex-1;
      }
      else {
        this.movePos = this._selectedIndex - 2;
      }
    }
    if (this.movePos < 0)
      this.movePos = 0;
    if (this.LongType === false)
      this.posStyle = { marginLeft: "calc((5rem + 23px) * " + (-this.movePos) + ")", transitionDuration: "unset" };
    else
      this.posStyle = { marginLeft: "calc((8.33rem + 23px) * " + (-this.movePos) + ")", transitionDuration: "unset" };
  }

  @Input()
  OnSelectedChanged: Subject<number>;

  @Input()
  LongType = false

  public defaultCss = "slider-div-item";

  @ViewChildren('carusalCategories') carusalCategories: QueryList<ElementRef>;

  ngAfterViewInit(): void {
    //if (this.DataSource.length === this.carusalCategories.length && this.DataSource.length > 0) {
    //  this.carusalCategories.toArray()[this.SelectedIndex].nativeElement.className = "slider-div-item-active";
    //}
    //this.carusalCategories.changes.subscribe(data => {
    //  if (data.length === this.DataSource.length) {
    //  }
    //});
  }


  public movePos = 0;

  public posStyle = { marginLeft: "0px", transitionDuration: "unset"  };

  public movConst = 100;
  public movConstLong = 150;

  public Left() {

    if (this.movePos > 0) {
      this.movePos--;
      if (this.LongType === false)
        this.posStyle = { marginLeft: "calc((5rem + 23px) * " + (-this.movePos) + ")", transitionDuration: "0.25s" };
      else
        this.posStyle = { marginLeft: "calc((8.33rem + 23px) * " + (-this.movePos) + ")", transitionDuration: "0.25s" };
    }

  }

  public Right() {
    let end_pos = (this._dataSource.length - 3);
    if (this.LongType === true) {
      if (window.screen.width < 768) {
        end_pos = (this._dataSource.length - 1);
      }
      else {
        end_pos = (this._dataSource.length - 2);
      }
    } else {
      if (window.screen.width < 768) {
        end_pos = (this._dataSource.length - 1);
      }
    }

    if (this.movePos < end_pos) {
      this.movePos++;
      if (this.LongType === false)
        this.posStyle = { marginLeft: "calc((5rem + 23px) * " + (-this.movePos) + ")", transitionDuration: "0.25s" };
      else
        this.posStyle = { marginLeft: "calc((8.33rem + 23px) * " + (-this.movePos) + ")", transitionDuration: "0.25s" };
     // this.posStyle = { marginLeft: "calc((5rem + 26px) * " + (-this.movePos) + ")" };// * (this.LongType === true ? this.movConstLong : this.movConst)) + "px" }
    }
  }

  public OnClick(index: number) {
    if (index !== this.SelectedIndex) {
      this.carusalCategories.toArray()[this._selectedIndex].nativeElement.className = "slider-div-item";
      this._selectedIndex = index;
      this.carusalCategories.toArray()[this._selectedIndex].nativeElement.className = "slider-div-item-active";
      if (typeof this.OnSelectedChanged !== "undefined") {
        this.OnSelectedChanged.next(index);
      }
    }

  }

}
