
<TopMenu [LanguageId]="pageLanguageId" Text="Welcome" Active="profile"></TopMenu>

<div class="content_640" *ngIf="PageState == 1">
  <div class="profile-company-info">
    <div class="profile-company-info-text-box">
      <div class="default_input_box max_input" style="float: left;margin-right: 15px;">
        <input type="text" [(ngModel)]="Detail.companyName" class="default_input" placeholder="{{ 'Resarurant name' | translate}}*" autcomplete="false" />
      </div>
      <div class="default_input_box max_input" style="float: left;margin-right: 15px;">
        <input type="text" value="ID: {{Detail.companyId}}" disabled class="default_input" placeholder="{{ 'Company Id' | translate}}*" autcomplete="false" />
      </div>
    </div>
    <div class="image-upload">
      <label for="file-input" style="position: relative;height: 100%;cursor: pointer;">
        <img [src]="Detail.imageBase64" style="cursor: pointer;max-width: 100%;" />
        <div class="file-input-text">{{ 'add image' | translate}}</div>
      </label>

      <input id="file-input" type="file" (change)="handleUpload($event)" />
    </div>

  </div>
  <div class="default_input_box" style="display: table; width: 100%; max-width: 300px;">
    <span class="default-lang-text">{{ 'Default language' | translate}}:</span>
    <LangSelectBox [SelectedValue]="Detail.defaultLanguage" [DataSource]="DefaultLangSelectBoxDataSource" [SelectedChange]="DefaultLangChange"></LangSelectBox>
    <!--<LangBox [selectedLang]="Detail.defaultLanguage"></LangBox>-->
  </div>
  <!--<div class="default_input_box" style="display: table; width: 100%; max-width: 300px;">
    <span class="default-lang-text">{{ 'Whatsapp activation' | translate}}:</span><br/>
    <input type="checkbox" class="default_input" [(ngModel)]="WhatsappActivation" style="width: 25px;    margin-left: 4rem;">
  </div>
  <div class="default_input_box" style="display: table; width: 100%; max-width: 300px;">
    <span class="default-lang-text">{{ 'Whatsapp link' | translate}}:</span>
    <input type="text" class="default_input" [(ngModel)]="WhatsappLink">
  </div>-->
  <div class="default_input_box" style="    display: table; width: 100%;  max-width: 300px;">
    <span class="default-lang-text">{{ 'QR code' | translate}}:</span>
    <div style="display:block">
      <LangSelectBox [SelectedValue]="QrCodeLang" [DataSource]="DefaultLangSelectBoxDataSource" [SelectedChange]="QrCodeLangChange"></LangSelectBox>
      <br />
      <button class="button_black" style="min-width: 250px; margin-right: 0.5rem; margin-left: 2rem; margin-top: 3rem;" (click)="GetQrCode()">{{ 'Get qr code grid' | translate}}</button>
      <button class="button_black" style="min-width: 250px; margin-right: 0.5rem; margin-left: 2rem; margin-top: 1.5rem;" (click)="GetQrCodeTable()">{{ 'Get table print' | translate}}</button>
      <button class="button_black" style="min-width: 250px; margin-right: 0.5rem; margin-left: 2rem; margin-top: 1.5rem;" (click)="GetQrCodeOnly()">{{ 'Get qr only' | translate}}</button>
    </div>
  </div>
  <div class="default-lang" style="margin-top: 2rem;">
    <span class="default-lang-text">{{ 'SELECT LANGUAGES MENU TRANSLATE REQUIRED' | translate}}:</span>
    <div style="display: table;margin-bottom:1rem;">
      <div class="carusal_button_left" (click)="Left()" style="float:left;margin-top: 2rem;margin-right: 1rem;"></div>
      <div class="profile-lang-carusal">
        <div class="carusal-content" style="float:left;    width: 2000px;height: 120px;">
          <ul class="profile-lang-ul" [ngStyle]="langPosCss" #profile_lang_id>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 0" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[0]" Id="0" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/english.svg"><span class="default-lang-lang-text">
                {{ 'english'| translate }}
              </span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 1" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[1]" Id="1" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/italy.svg">
              <span class="default-lang-lang-text">{{ 'italian'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 2" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[2]" Id="2" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/eu.svg">
              <span class="default-lang-lang-text">{{ 'european'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 3" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[3]" Id="3" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/us.svg">
              <span class="default-lang-lang-text">{{ 'united state'| translate }}</span>
            </li>

            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 5" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[5]" Id="5" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/ge.svg">
              <span class="default-lang-lang-text">{{ 'germany'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 6" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[6]" Id="6" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/sk.svg">
              <span class="default-lang-lang-text">{{ 'slovakia'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 7" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[7]" Id="7" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/cs.svg">
              <span class="default-lang-lang-text">{{ 'czech republic'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 8" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[8]" Id="8" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/pl.svg">
              <span class="default-lang-lang-text">{{ 'poland'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 9" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[9]" Id="9" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/cn.svg">
              <span class="default-lang-lang-text">{{ 'china'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 10" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[10]" Id="10" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/ru.svg">
              <span class="default-lang-lang-text">{{ 'russia'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 11" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[11]" Id="11" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/es.svg">
              <span class="default-lang-lang-text">{{ 'spain'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 12" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[12]" Id="12" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/nl.svg">
              <span class="default-lang-lang-text">{{ 'netherlands'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 13" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[13]" Id="13" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/fr.svg">
              <span class="default-lang-lang-text">{{ 'france'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 14" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[14]" Id="14" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/dk.svg">
              <span class="default-lang-lang-text">{{ 'denmark'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 15" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[15]" Id="15" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/se.svg">
              <span class="default-lang-lang-text">{{ 'sweden'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 16" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[16]" Id="16" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/hr.svg">
              <span class="default-lang-lang-text">{{ 'croatia'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 17" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[17]" Id="17" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/hu.svg">
              <span class="default-lang-lang-text">{{ 'hungary'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 18" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[18]" Id="18" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/pt.svg">
              <span class="default-lang-lang-text">{{ 'portugal'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 19" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[19]" Id="19" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/no.svg">
              <span class="default-lang-lang-text">{{ 'norway'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 20" lass="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[20]" Id="20" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/ar.svg">
              <span class="default-lang-lang-text">{{ 'arabic'| translate }}</span>
            </li>
            <li class="default-lang-li">
              <span class="default-lang-check">
                <CheckBox *ngIf="Detail.defaultLanguage !== 4" class="default-lang-checkbox" [DefaultValue]="translateLanguagesDefautls[4]" Id="4" [OnChangeWithId]="OnChangeWithId"></CheckBox>
              </span>
              <img class="default-lang-img" src="/assets/images/langs/chf.svg">
              <span class="default-lang-lang-text">{{ 'switzerland'| translate }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="carusal_button_right" (click)="Right()" style="margin-top: 2rem;margin-left: 1rem;"></div>
    </div>

    <span class="default-lang-text2">
      {{ 'profil_text_1' | translate}}
    </span>
  </div>
  <div class="menu-init-textarea">
    <span class="default-lang-text">{{ 'INITIAL MESSAGE'| translate }}:</span><br>
    <LangSelectBox [SelectedValue]="InitialTextLangId" [SelectedChange]="InitTextLangChange" [DataSource]="LangSelectBoxDataSource" style="   width: 300px;    display: table;"></LangSelectBox><br>
    <textarea class="default_input" [(ngModel)]="CurremtInitText" maxlength="90">

    </textarea><br>
    <span class="default-lang-text" style="font-size:0.75rem">{{ 'E.G. SERVICE, 1€ PER PERSON CHARGED TO THE TOTAL BILL'| translate }}</span><br>

    <span class="default-lang-text">{{ 'Restaurant MESSAGE'| translate }}:</span><br>
    <textarea class="default_input" [(ngModel)]="CurremtRestaurantText" maxlength="500">

    </textarea><br>
    <span class="default-lang-text" style="font-size:0.75rem">{{ 'Restaurant message 2'| translate }}</span><br>

    <div style="display: table;margin: 2rem auto;">
      <button *ngIf="Detail.defaultLanguage !== this.InitialTextLangId" class="button_black" style="margin-right:0.5rem" (click)="GetTranslationInitText()">{{ 'translate'| translate }}</button>
    </div>

  </div>
  <div style="display: table;margin: 2rem auto;">
    <button class="button_black" style="margin-right:0.5rem" (click)="Save()">{{ 'save'| translate }}</button>
  </div>
  <div class="license-profile-box">
    <div class="expiration-span">
      {{ 'EXPIRY SUBSCRIPTION' | translate}}: {{ Detail.expireDate}}
    </div>
    <div class="license-profile-box-buttons">
      <button class="button_black" style=" margin-right: 0.5rem " (click)="BuyNewSubscription()">{{ 'Buy' | translate}}</button>
      <button class="button_transparent" (click)="DeleteSubscription()">{{ 'Delete' | translate}}</button>
    </div>
  </div>
</div>

<div class="crop-window" *ngIf="showCropWindow == true">
  <div class="crop-window-controls">
    <button class="button_black" (click)="OkWindow()">{{ 'save' | translate}}</button>
    <button class="button_black" (click)="CloseWindow()">{{ 'cancel' | translate}}</button>
  </div>
  <image-cropper [imageChangedEvent]="imageChangedEvent"
                 [aspectRatio]="1"
                 [maintainAspectRatio]="true"
                 [resizeToWidth]="400"
                 [resizeToHeight]="400"
                 [cropperMinWidth]="400"
                 [cropperMinHeight]="400"
                 format="png"
                 (imageCropped)="imageCropped($event)"></image-cropper>

</div>


<div class="content_640" *ngIf="PageState == 2">
  <a [routerLink]="['/' + this.pageLanguageId, 'company']" class="reqistration_button_prev_a" style="margin-top:1.5rem"><div class="reqistration_button_prev"></div></a>
  <div *ngIf="processingSub == 0">
    <h1 class="reqistration_title" style="margin-bottom: 2rem;margin-top: 2rem;">{{ 'select product'| translate }}</h1>
    <div class="product-type-1">
      <div class="product-type-1-name">{{ product.name }}</div>
      <img class="product-type-1-img" src="{{ product.imagePath }}" />
      <div class="product-type-1-desc">
        {{ product.desc }}
      </div>
      <div class="product-type-1-type">
        <button class="button_black" (click)="SelectedProduct(product.id,1)" style="width: 250px;margin-bottom:1rem;">{{ '30 days'| translate }} = {{ product.price30 }} {{ product.currency}}</button><br />
        <!--<button class="button_black" (click)="SelectedProduct(product.id,2)" style="width: 250px;margin-bottom:1rem;">{{ '180 days'| translate }} =  {{ product.price180 }} {{ product.currency}}</button><br />-->
        <button class="button_black" (click)="SelectedProduct(product.id,3)" style="width: 250px;margin-bottom:1rem;">{{ '365 days'| translate }} = {{ product.price365 }} {{ product.currency}}</button><br />
      </div>
    </div>
  </div>
  <div *ngIf="processingSub == 1 || processingSub == 2">
    <div  style="    position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
      <div  *ngIf="processingSub == 1" class="lds-roller" style="left: 50%; transform: translate(-50%, 0%);">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>

      </div>
      <button *ngIf="processingSub == 2" class="button_black" (click)="BackToProfile()" style="width: 250px;margin-bottom:1rem;">{{ 'back to profile'| translate }}  </button>
      <div style=" text-align: center;   letter-spacing: 2px;    text-transform: uppercase;">{{ processingOrderText | translate }}</div>
    </div>
    
  </div>
</div>

<div class="footer-black"></div>

<Toast Message="Item updated"></Toast>
