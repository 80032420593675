import { Component, OnInit } from "@angular/core";
import { CompanyService } from "../../../services/company/CompanyService";
import { ActivatedRoute } from "@angular/router";


@Component({

  templateUrl: 'invoice.page.html'
})
export class InvoicePage implements OnInit  {

  public pageLanguageId = 'en';

  public invoices: any;

  constructor(public companyService: CompanyService, public route: ActivatedRoute) {

    this.companyService.GetAllInvoices().subscribe(results => {
      this.invoices = results;
    });

  }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.pageLanguageId = routeParams.languageId;

    });
  }

}
