import { Component, Input } from "@angular/core";


@Component({
  selector:'Toast',
  template:'<div *ngIf="Visible" class="cm_toast">{{ Message | translate}}</div>'
})
export class ToastComponent {

  @Input()
  Message = "";

  public Visible = false;

  public Show() {
    this.Visible = true;
    setTimeout(() => {
      this.Visible = false;
    }, 2000);
  }



}
