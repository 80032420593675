/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "../SignOut/signout.component.ngfactory";
import * as i4 from "../SignOut/signout.component";
import * as i5 from "./topmenu.component";
import * as i6 from "@angular/router";
var styles_TopMenuComponent = [];
var RenderType_TopMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TopMenuComponent, data: {} });
export { RenderType_TopMenuComponent as RenderType_TopMenuComponent };
function View_TopMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 21, "div", [["class", "companymenu-mobile-menu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 20, "ul", [["class", "companymenu-mobile-menu-ul"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "li", [["class", "companymenu-mobile-menu-ul-li"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClick([_co.LanguageId, "company"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 2, "li", [["class", "companymenu-mobile-menu-ul-li"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClick([_co.LanguageId, "company", "accessory"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 2, "li", [["class", "companymenu-mobile-menu-ul-li"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClick([_co.LanguageId, "company", "menu"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(11, 0, null, null, 2, "li", [["class", "companymenu-mobile-menu-ul-li"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClick([_co.LanguageId, "company", "user"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(14, 0, null, null, 2, "li", [["class", "companymenu-mobile-menu-ul-li"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClick([_co.LanguageId, "company", "invoice"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(15, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(17, 0, null, null, 2, "li", [["class", "companymenu-mobile-menu-ul-li"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.SignOutClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(18, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(20, 0, null, null, 1, "li", [["class", "companymenu-mobile-menu-ul-li-close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 0, "div", [["class", "companymenu-mobile-menu-ul-li-close-img"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.CloseMobileMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("profile")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("accessory")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform("menu")); _ck(_v, 9, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("user")); _ck(_v, 12, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 15, 0, i0.ɵnov(_v, 16).transform("invoices")); _ck(_v, 15, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 18, 0, i0.ɵnov(_v, 19).transform("sign out")); _ck(_v, 18, 0, currVal_5); }); }
export function View_TopMenuComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "header-black-logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "header-black-logo-div"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["class", "header-black-logo-img"], ["onclick", "window.open('https://bit.ly/ord2eatcom', '_blank');"], ["src", "/assets/images/logo.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "top-meni-welcome-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "h1", [["class", "reqistration_title"], ["style", "    margin-top: 1rem;    margin-bottom: 1rem;"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 28, "div", [["class", "companymenu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 27, "div", [["class", "col-12 companymenu-div "]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClick([_co.LanguageId, "company"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(11, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(12, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(14, 0, null, null, 4, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClick([_co.LanguageId, "company", "accessory"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(16, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(17, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(19, 0, null, null, 4, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClick([_co.LanguageId, "company", "menu"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(21, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(22, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(24, 0, null, null, 4, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClick([_co.LanguageId, "company", "user"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(26, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(27, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(29, 0, null, null, 4, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClick([_co.LanguageId, "company", "invoice"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(31, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(32, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(34, 0, null, null, 1, "SignOut", [["class", "col-2 company-menu-item"]], null, null, null, i3.View_SignOutComponent_0, i3.RenderType_SignOutComponent)), i0.ɵdid(35, 49152, null, 0, i4.SignOutComponent, [], null, null), (_l()(), i0.ɵeld(36, 0, null, null, 7, "div", [["class", "companymenu-mobile"]], null, null, null, null, null)), (_l()(), i0.ɵeld(37, 0, null, null, 2, "div", [["class", "companymenu-mobile-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(38, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(40, 0, null, null, 1, "div", [["class", "companymenu-mobile-img"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ShowMobileMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(41, 0, null, null, 0, "img", [["src", "/assets/images/mobile-menu.svg"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TopMenuComponent_1)), i0.ɵdid(43, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.cssProfile; _ck(_v, 11, 0, currVal_1); var currVal_3 = _co.cssMedia; _ck(_v, 16, 0, currVal_3); var currVal_5 = _co.cssMenu; _ck(_v, 21, 0, currVal_5); var currVal_7 = _co.cssUser; _ck(_v, 26, 0, currVal_7); var currVal_9 = _co.cssInvoices; _ck(_v, 31, 0, currVal_9); var currVal_12 = _co.showMobileMenu; _ck(_v, 43, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.Text)); _ck(_v, 5, 0, currVal_0); var currVal_2 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("profile")); _ck(_v, 12, 0, currVal_2); var currVal_4 = i0.ɵunv(_v, 17, 0, i0.ɵnov(_v, 18).transform("accessory")); _ck(_v, 17, 0, currVal_4); var currVal_6 = i0.ɵunv(_v, 22, 0, i0.ɵnov(_v, 23).transform("menu")); _ck(_v, 22, 0, currVal_6); var currVal_8 = i0.ɵunv(_v, 27, 0, i0.ɵnov(_v, 28).transform("user")); _ck(_v, 27, 0, currVal_8); var currVal_10 = i0.ɵunv(_v, 32, 0, i0.ɵnov(_v, 33).transform("invoices")); _ck(_v, 32, 0, currVal_10); var currVal_11 = i0.ɵunv(_v, 38, 0, i0.ɵnov(_v, 39).transform(_co.Active)); _ck(_v, 38, 0, currVal_11); }); }
export function View_TopMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "TopMenu", [], null, null, null, View_TopMenuComponent_0, RenderType_TopMenuComponent)), i0.ɵdid(1, 114688, null, 0, i5.TopMenuComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopMenuComponentNgFactory = i0.ɵccf("TopMenu", i5.TopMenuComponent, View_TopMenuComponent_Host_0, { Text: "Text", Active: "Active", LanguageId: "LanguageId" }, {}, []);
export { TopMenuComponentNgFactory as TopMenuComponentNgFactory };
