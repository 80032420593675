import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ResponseCodeModel } from "../../GlobalModels";

export class UserSignInModel {

  public code = 0;
  public jwt = "";
  public admin = false;
}

export class UserListModel {
  public id: number;
  public userName: string;
}


export class AddUserModel {
  public UserName: string;
  public Password: string;
}


export class UpdateUserModel {
  public Id: number;
  public Password: string;
}

export class PasswordRecoverModel {
  public Token: string;
  public NewPassword: string
}


@Injectable()
export class UserService {

  constructor(private Http: HttpClient) {

  }

  public SingIn(UserName: string, Password: string): Observable<UserSignInModel>  {
    return this.Http.post<UserSignInModel>("/Api/User/SignIn/", { UserName: UserName, Password: Password });
  }


  public GetUserList(page: number, count: number): Observable<UserListModel> {
    return this.Http.get<UserListModel>("/Api/User/GetUserList/?page=" + page + "&count=" + count);

  }

  public AddUser(userModel: AddUserModel): Observable<ResponseCodeModel> {
    return this.Http.post<ResponseCodeModel>("/Api/User/AddUser/", userModel);
  }

  public UpdateUser(userModel: UpdateUserModel): Observable<ResponseCodeModel> {
    return this.Http.post<ResponseCodeModel>("/Api/User/UpdateUser/", userModel);
  }

  public GetUser(id: number): Observable<UserListModel> {
    return this.Http.get<UserListModel>("/Api/User/GetUser/?id="+id);
  }

  public RemoveUser(id: number): Observable<ResponseCodeModel> {
    return this.Http.delete<ResponseCodeModel>("/Api/User/RemoveUser/?id="+id);
  }

  public SignInAsCompany(companyId: number): Observable<UserSignInModel> {
    return this.Http.get<UserSignInModel>("/Api/User/SignInAsCompany/?CompanyId=" + companyId);
  }

  public CreatePasswordRecoverToken(userEmail: string): Observable<ResponseCodeModel> {
    return this.Http.get<ResponseCodeModel>("/Api/User/CreatePasswordRecoverToken/?UserEmail=" + userEmail);
  }

  public PasswordRecover(passwordRecoverModel: PasswordRecoverModel): Observable<ResponseCodeModel> {
    return this.Http.put<ResponseCodeModel>("/Api/User/PasswordRecover/", passwordRecoverModel);
  }

}
