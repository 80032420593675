import { Component } from "@angular/core";
import { CompanyService } from "../../../services/company/CompanyService";

@Component({
  templateUrl:'dashboard.page.html'
})
export class DashboardAdminPage {

  public currnetVersion = "";

  constructor(public companyService: CompanyService) {
    companyService.GetVersion().subscribe(restult => {
      this.currnetVersion = restult.version;
    });
  }

}
