import { OnInit, Component } from "@angular/core";
import { OrderService } from "../../../services/user/OrderList";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";


@Component({
  templateUrl: "orderhelp.page.html"
})
export class OrderHelpPage implements OnInit {

  public langArray = ['en', 'it', 'eu', 'us', 'chf', 'ge', 'sk', 'cs', 'pl', 'cn', 'ru', 'es', 'nl', 'fr', 'dk', 'se', 'hr', 'hu', 'pt', 'no', 'ar'];


  private type = "";
  private parentId = -1;
  private productId = -1;
  private companyId = -1;
  private langId = -1;

  constructor(private orderService: OrderService, public route: ActivatedRoute, public router: Router,
    public translateService: TranslateService) {

  }

    ngOnInit(): void {
      this.route.params.subscribe(routeParams => {

        this.companyId = routeParams.companyId;
        this.langId = this.langArray.findIndex(function (item) { return item === routeParams.languageId; });
        this.translateService.use(routeParams.languageId);

        this.type = routeParams.type;
        this.parentId = routeParams.parentId;
        this.productId = routeParams.productId;

      });
    }

  public BackButton() {
    if (this.productId > -1)
      this.router.navigate([this.companyId, this.langArray[this.langId],'orders', this.type, this.parentId, this.productId]);
    else if (this.parentId > -1)
      this.router.navigate([this.companyId, this.langArray[this.langId],'orders', this.type, this.parentId]);
    else if (typeof this.type !== "undefined" && this.type !== "")
      this.router.navigate([this.companyId, this.langArray[this.langId],'orders', this.type]);
    else
      this.router.navigate([this.companyId, this.langArray[this.langId],'orders']);
  }
}
