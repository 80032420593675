import { Component, ViewChild, OnInit, ElementRef, AfterViewInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { CompanyService,  CompanyProfilModel } from "../../../services/company/CompanyService";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { ToastComponent } from "../../../components/Toast/toast.component";
import { Subject } from "rxjs";
import { CheckBoxChangeEvent } from "../../../components/checkbox/checkbox.component";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Product } from "../../public/registration/registration.page";
import { GetLangSelectBoxDaata, GetSupportedLanguages } from "../../../GlobalModels";
import { HttpClient } from "@angular/common/http";
import { LangSelectBox } from "../../../components/langselectbox/langselectbox";

@Component({
  templateUrl:'profile.page.html'
})
export class ProfilePage implements OnInit, AfterViewInit, OnDestroy {

  public Detail = new CompanyProfilModel();

  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  public newImage = false;
  public showCropWindow = false;

  public OnChangeWithId: Subject<CheckBoxChangeEvent>;

  public translateLanguages = [];

  public translateLanguagesDefautls = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];

  public pageLanguageId = 'en';

  public PageState = 1;

  public product: Product;
  public processingSub = 0;

  public langPos = 0;
  public langPosStep = 100;
  public langPosCss = { marginLeft: "0px" };
  public langStartTouch = 0;
  public langTouchAvg = 0;
  public langTouchStarted = false;
  public langCurrentTouchMove = 0;

  public processingOrderText = "processing order";

  public LangSelectBoxDataSource = [];
  public InitialTexts = [];
  public RestaurantTexts = [];
  private InitialTextLangId = -1;
  public InitTextLangChange: Subject<number>;
  public DefaultLangChange: Subject<number>;
  public QrCodeLangChange: Subject<number>;

  public DefaultLangSelectBoxDataSource = [];

  private newDefaultValue = -1;



  public QrCodeLang = -1;
  public CurremtInitText = "";
  public CurremtRestaurantText = "";

  public WhatsappActivation = false;
  public WhatsappLink = "";

  private langTimer1: any;


  @ViewChild(ToastComponent, { static: false }) toast: ToastComponent;

  @ViewChild('profile_lang_id', { static: false }) ProfileLantId: ElementRef;

  //@ViewChild("LangInitBoxId", { static: false }) LangInitBoxId: LangSelectBox;
  //@ViewChild("LangQrBoxId", { static: false }) LangQrBoxId: LangSelectBox;
  //@ViewChild("LangDefaultBoxId", { static: false }) LangDefaultBoxId: LangSelectBox;

  constructor(public companyService: CompanyService, public route: ActivatedRoute, public translateService: TranslateService,
    public router: Router, private elementRef: ElementRef, public http: HttpClient, private cdRef: ChangeDetectorRef) {
    const _defLangs: any[] = [0, 1, 2, 3, 4,10];
    this.DefaultLangSelectBoxDataSource = GetLangSelectBoxDaata(_defLangs as []);
    //this.langTimer1 = setInterval(() => {
    //  if (this.translateService.langs.length === 5) {
    //    clearInterval(this.langTimer1);
    //    let _langs = GetLangSelectBoxDaata(_defLangs as []);
    //    let _translated = _langs;
    //    for (let i = 0; i < _langs.length; i++) {

    //      _translated[i].text = this.translateService.instant(_langs[i].text);
    //    }
    //    this.DefaultLangSelectBoxDataSource = _translated;

    //  }
    //}, 100);

    this.product = new Product();

    this.product.imagePath = "/assets/images/product1.png";
    this.translateService.get("Online menu").subscribe(trans => {
      this.product.name = trans;
    });
    const defaultLangs = GetSupportedLanguages();

    //this.companyService.GetPaymentIntents().subscribe(restult => {
    //  debugger;
    //});

    this.companyService.GetCompanyProfil().subscribe(data => {
      this.Detail = data;
      //this.LangDefaultBoxId.SetValue(this.Detail.defaultLanguage);
      //this.LangQrBoxId.SetValue(this.Detail.defaultLanguage);
      //this.LangInitBoxId.SetValue(this.Detail.defaultLanguage);
      this.WhatsappActivation = data.whatsappActivate;
      this.WhatsappLink = data.whatsappLink;



      this.QrCodeLang = this.Detail.defaultLanguage;

      this.InitialTextLangId = this.Detail.defaultLanguage;
      this.companyService.GetProducts(defaultLangs[this.Detail.defaultLanguage]).subscribe(results => {
        this.product.price30 = results[results.findIndex(function (item) { return item.name === 'eMenu30'; })].price / 100;
        //this.product.price180 = results[results.findIndex(function (item) { return item.name === 'eMenu180'; })].price / 100;;
        this.product.price365 = results[results.findIndex(function (item) { return item.name === 'eMenu365'; })].price / 100;
        this.product.currency = results[results.findIndex(function (item) { return item.name === 'eMenu30'; })].currency;
      });
      if (typeof this.Detail.initialText !== "undefined" && this.Detail.initialText !== null && this.Detail.initialText.startsWith("[") === true)
        this.InitialTexts = JSON.parse(this.Detail.initialText);
      else
        this.InitialTexts = [];


      if (typeof this.Detail.publicInitMessages !== "undefined" && this.Detail.publicInitMessages !== null && this.Detail.publicInitMessages.startsWith("[") === true)
        this.RestaurantTexts = JSON.parse(this.Detail.publicInitMessages);
      else
        this.RestaurantTexts = [];
      // i < language_total
      for (let i = this.InitialTexts.length; i < 21; i++) {
        this.InitialTexts.push("");
      }
      for (let i = this.RestaurantTexts.length; i < 21; i++) {
        this.RestaurantTexts.push("");
      }
      if (typeof this.Detail.imageBase64 === "undefined" || this.Detail.imageBase64 === null)
        this.Detail.imageBase64 = "/assets/images/bck_image.png";
      if (typeof this.Detail.translateLanguages !== "undefined" && this.Detail.translateLanguages !== null && this.Detail.translateLanguages.startsWith("[") === true) {
        this.translateLanguages = JSON.parse(this.Detail.translateLanguages);
        for (let i = 0; i < this.translateLanguages.length; i++) {
          this.translateLanguagesDefautls[this.translateLanguages[i]] = true;
        }
        this.translateLanguagesDefautls[this.Detail.defaultLanguage] = true;
        this.LangSelectBoxDataSource = GetLangSelectBoxDaata(JSON.parse(this.Detail.translateLanguages));
      }
     // this.InitialTextLangId = this.Detail.defaultLanguage;
      this.CurremtInitText = this.InitialTexts[this.Detail.defaultLanguage];
      this.CurremtRestaurantText = this.RestaurantTexts[this.Detail.defaultLanguage];
    });
    this.OnChangeWithId = new Subject<CheckBoxChangeEvent>();
    this.OnChangeWithId.subscribe((result: CheckBoxChangeEvent) => {
      let _id = parseInt(result.id);
      if (_id !== this.Detail.defaultLanguage)
      {
        const found_item = this.translateLanguages.findIndex(function (item) { return item === _id });
        if (found_item === -1 && result.state === true) {
          this.translateLanguages.push(_id);
        }
        if (found_item > -1 && result.state === false) {
          this.translateLanguages.splice(found_item, 1);
        }
         
       // this.LangSelectBoxDataSource = GetLangSelectBoxDaata(this.translateLanguages as []);
       // const _swap = this.Detail.defaultLanguage;
       // this.Detail.defaultLanguage = -1;
       // this.Detail.defaultLanguage = _swap;
      }
    });

    this.InitTextLangChange = new Subject<number>();
    this.InitTextLangChange.subscribe(id => {
      this.InitialTexts[this.InitialTextLangId] = this.CurremtInitText;
      this.CurremtInitText = this.InitialTexts[id];
      this.RestaurantTexts[this.InitialTextLangId] = this.CurremtRestaurantText;
      this.CurremtRestaurantText = this.RestaurantTexts[id];
      this.InitialTextLangId = id;
    });

    this.DefaultLangChange = new Subject<number>();
    this.DefaultLangChange.subscribe(id => {
    //  this.newDefaultValue = id;
      this.Detail.defaultLanguage = id;

      const found_item = this.translateLanguages.findIndex(function (item) { return item === id });
      if (found_item === -1 ) {
        this.translateLanguages.push(id);
      }
      this.LangSelectBoxDataSource = GetLangSelectBoxDaata(this.translateLanguages as []);


      this.cdRef.detectChanges();
    });

    this.QrCodeLangChange = new Subject<number>();
    this.QrCodeLangChange.subscribe(id => {
      //  this.newDefaultValue = id;
      this.QrCodeLang = id;

      this.cdRef.detectChanges();
    });

  }
  ngAfterViewInit(): void {
    if (this.PageState === 1) {
      this.ProfileLantId.nativeElement
        .addEventListener('touchmove', (e) => {
          if (this.langTouchStarted === true) {
            var _move = (this.langPos - (this.langTouchAvg - e.touches[0].pageX));
            if (_move < -1300)
              _move = -1300;
            if (_move < 0) {
              this.langCurrentTouchMove = _move;
              console.log(this.langCurrentTouchMove);
              this.langPosCss = { marginLeft: (this.langCurrentTouchMove + "px") };
            }
          }
        });
      this.ProfileLantId.nativeElement.addEventListener('touchstart', (e) => {
        this.langTouchAvg = e.touches[0].pageX;
        this.langCurrentTouchMove
        this.langTouchStarted = true;
      });

      this.ProfileLantId.nativeElement.addEventListener('touchend', (e) => {
        this.langPos = this.langCurrentTouchMove;
        if (this.langPos > 0)
          this.langPos = 0;
        if (this.langPos < -1300)
          this.langPos = -1300;
        this.langPosCss = { marginLeft: ((this.langPos) + "px") };
        this.langTouchStarted = false;
      });
    }

  }

  ngOnDestroy() {
    this.router.onSameUrlNavigation = 'ignore';
  }
  ngOnInit(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.router.routeReuseStrategy.shouldReuseRoute = () => { return false; };

      this.route.params.subscribe(routeParams => {
        this.pageLanguageId = routeParams.languageId;
        this.translateService.use(this.pageLanguageId);




        
        const _pageState = routeParams.pageState;
        if (_pageState === "new-subscription") {
          this.PageState = 2;
        }

      });
  }


  public Save() {
    if (this.newImage === true)
      this.Detail.imageBase64 = this.croppedImage;
    this.InitialTexts[this.InitialTextLangId] = this.CurremtInitText;
    this.RestaurantTexts[this.InitialTextLangId] = this.CurremtRestaurantText;
    this.Detail.initialText = JSON.stringify(this.InitialTexts);
    this.Detail.publicInitMessages = JSON.stringify(this.RestaurantTexts);
    this.Detail.translateLanguages = JSON.stringify(this.translateLanguages);
    this.Detail.whatsappActivate = this.WhatsappActivation;
    this.Detail.whatsappLink = this.WhatsappLink;
    
    this.companyService.UpdateCompanyProfil(this.Detail).subscribe(result => {
      if (result.code === 1) {
        this.toast.Message = "Item updated";
        this.toast.Show();
        this.router.navigate(['/' + this.pageLanguageId, 'company']);
      }

      else {
        this.toast.Message = "Error";
        this.toast.Show();
      }
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.newImage = true;
  }

  public handleUpload(event) {
    this.imageChangedEvent = event;
    this.showCropWindow = true;
  }

  public OkWindow() {
    this.showCropWindow = false;
    this.Detail.imageBase64 = this.croppedImage;
  }

  public CloseWindow() {
    this.showCropWindow = false;
  }

  public GetQrCode() {

    const _langs = GetSupportedLanguages();

    window.open("/api/qrcode/grid/?jwt=" + localStorage.getItem('jwt_token') + "&lang=" + _langs[this.QrCodeLang], "_blank");
  }

  public GetQrCodeTable() {
    const _langs = GetSupportedLanguages();
    window.open("/api/qrcode/TablePrint/?jwt=" + localStorage.getItem('jwt_token') + "&lang=" + _langs[this.QrCodeLang], "_blank");
  }

  public GetQrCodeOnly()
  {
    const _langs = GetSupportedLanguages();
    window.open("/api/qrcode/OnlyQrCode/?jwt=" + localStorage.getItem('jwt_token') + "&lang=" + _langs[this.QrCodeLang], "_blank");
  }

  public BuyNewSubscription() {
    this.router.navigate(['/' + this.pageLanguageId, 'company', 'new-subscription']);
  }


  public SelectedProduct(id, type) {
    this.processingSub = 1;
    let producName = "";
    if (type === 1)
      producName = "eMenu30";
    //else if (type === 2)
    //  producName = "eMenu180";
    else if (type === 3)
      producName = "eMenu365";
    else {
      this.processingOrderText = "Unknow product.";
      return;
    }
    
    this.companyService.CreateSubscription(producName).subscribe(
      result => {
        this.processingSub = 2;
        if (result.code === 1) {
          this.processingOrderText = "Substriction has been created.";
        }
        else {
          this.processingOrderText = "Error. Please contact ord2eat support.";
        }
      });

  }

  public BackToProfile() {
    this.router.navigate(['/' + this.pageLanguageId, 'company']);
  }

  public Right() {

    this.langPos -= this.langPosStep;
    if (this.langPos < -1300)
      this.langPos = -1300;
    this.langPosCss = { marginLeft: ( this.langPos + "px") };
  }

  public Left() {
    this.langPos += this.langPosStep;
    if (this.langPos > 0)
      this.langPos = 0;
    this.langPosCss = { marginLeft: (this.langPos + "px") };
  }


  public DeleteSubscription() {

    this.translateService.get("Do you realy want to cancel current subscription ?").subscribe(trans => {
      if (confirm(trans) === true) {
        this.companyService.RemoveSubscription().subscribe(result => {
          if (result.code === 1) {
            this.toast.Message = "subscription has been canceled";
            this.toast.Show();
          }

          else {
            this.toast.Message = "Error";
            this.toast.Show();
          }
        });
      }
    });
  }

  public GetTranslationInitText() {
    let langs = [];
    langs.push(this.InitialTextLangId);
    let lang_item = GetLangSelectBoxDaata(langs as []);
    let langtarget = lang_item[0].shortcutLang;
    let textQ = "q=" + encodeURIComponent(this.CurremtInitText);
    textQ += "&q=" + encodeURIComponent(this.CurremtRestaurantText);

    this.http.get("https://translation.googleapis.com/language/translate/v2?" + textQ + "&target=" + langtarget + "&format=text&key=AIzaSyAYRgDYz9R8GnvvdPr7hSPpLoXELZnJbPc").subscribe((result: any) => {
      this.CurremtInitText = result.data.translations[0].translatedText;
      this.CurremtRestaurantText = result.data.translations[1].translatedText;
    });
  }
}
