import { Component, ViewChild, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { CompanyService, AddCompanyModel, PublicUpdatePassword, CompanyNewCreditCard, CompanyAccessoryModel } from "../../../services/company/CompanyService";
import { ToastComponent } from "../../../components/Toast/toast.component";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  templateUrl: 'accessory.page.html'
})
export class AccessoryPage implements OnInit {

  public step2CheckBoxEvent = new Subject<boolean>();

  public step2registration_style = { maxHeight: "0px" };

  public months: number[] = [];
  public years: number[] = [];

  public companyDetail = new CompanyAccessoryModel();
  @ViewChild(ToastComponent, { static: false }) toast: ToastComponent;

  public pageLanguageId = 'en';

  constructor(public companyService: CompanyService, public translateService: TranslateService,
    public route: ActivatedRoute) {

  

    this.companyService.GetAccessory().subscribe(result => {
      
      this.companyDetail = result;
    })


  }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.pageLanguageId = routeParams.languageId;
      this.translateService.use(this.pageLanguageId);

    });
  }

  public SaveDetail() {
    this.companyService.SetAccessory(this.companyDetail).subscribe(result => {
      if (result === true) {
        this.toast.Message = "Item updated";
        this.toast.Show();
      }

      else {
        this.toast.Message = "Error";
        this.toast.Show();
      }
    });
  }

}
