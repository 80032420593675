import { Component, Input, AfterViewInit, OnInit } from "@angular/core";
import { NgxImageCompressService } from "ngx-image-compress";
import { Subject } from "rxjs";
import { ImageCroppedEvent } from "ngx-image-cropper";

declare function CategoryItemsInitDragAndDrop(): any;
declare function GetCategoryItemsDragAndDropOrder(): any;

export class CategoryItem {
  public title: string;
  public visible: boolean;
  public imageBase64: string;
  public order: number;
  public id: number;
  public languageId: number;
  public highliting: boolean;
 }

@Component({
  selector: "CategoryItems",
  templateUrl:"categoryitems.component.html"
})
export class CategoryItemsComponent implements AfterViewInit, OnInit {


  @Input()
  CategoryItems: CategoryItem[] = [];

  @Input()
  OpenSubcategoryEvent: Subject<CategoryItem>;

  @Input()
  RemoveItemEvent: Subject<CategoryItem>;

  @Input()
  ImageType2 = false;

  public max_order = 0;

  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  public opennedItem: CategoryItem;

  public showCropWindow = false;

  @Input()
  ImageHeight: number;

  @Input()
  SpecialOffer: boolean = false;

  @Input()
  ImageWidth: number;

  @Input()
  AspectRatio = 1 / 1;

  @Input()
  LanguageId = 0;

  public Highliting = false;

  get Long() { return this.long_postfix === "-long"; }
  @Input()
  set Long(val: any) {
    if (val === true) {
      this.long_postfix = "-long";
      this.Highliting = true;
    }
    else {
      this.long_postfix = "";
      this.Highliting = false;
    }
  }

  public long_postfix = "";

  constructor(public imageCompress: NgxImageCompressService) {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
      CategoryItemsInitDragAndDrop();
  }

  public GetRealOrder() {
    return GetCategoryItemsDragAndDropOrder();
  }

  public AddItem() {

    for (let i = 0; i < this.CategoryItems.length; i++) {
      if (this.CategoryItems[i].order > this.max_order)
        this.max_order = this.CategoryItems[i].order;
    }
    this.max_order++;
    const new_item = new CategoryItem();
    if (this.long_postfix === "")
      new_item.imageBase64 = "/assets/images/default2.jpg";
    else
      new_item.imageBase64 = "/assets/images/default1.jpg";
    new_item.title = "";
    new_item.id = 0;
    new_item.visible = true;
    new_item.order = this.max_order;
    new_item.languageId = this.LanguageId;
    new_item.highliting = false;
    this.CategoryItems.push(new_item);
  }

  public OpenSubcategory(item: CategoryItem) {
    if (typeof this.OpenSubcategoryEvent !== "undefined") {
      this.OpenSubcategoryEvent.next(item);
    }
  }

  public handleUpload(event, item: CategoryItem) {
    this.imageChangedEvent = event;
    this.showCropWindow = true;
    this.opennedItem = item;
    //let fileName = "";
    //let file = event.target.files[0];
    //fileName = file['name'];
    //if (event.target.files && event.target.files[0]) {
    //  var reader = new FileReader();
    //  reader.onload = (event: any) => {
    //    let localUrl = event.target.result;
    //    this.compressFile(localUrl, fileName, item)
    //  }
    //  reader.readAsDataURL(event.target.files[0]);
    //}
  }

  public ChangeVisible(item_category: CategoryItem) {
    item_category.visible = !item_category.visible;
  }

  public ChangeHighliting(item_category: CategoryItem) {
    item_category.highliting = !item_category.highliting;
  }

  public RemoveItem(item_category: CategoryItem) {

    if (confirm("Do you realy want to reamove this item ?")) {

      if (typeof this.RemoveItemEvent !== "undefined") {
        this.RemoveItemEvent.next(item_category);
      }
    }
  }

  compressFile(image, fileName, item: CategoryItem) {
    let orientation = -1;
    let sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', sizeOfOriginalImage);
    this.imageCompress.compressFile(image, orientation, 50, 100).then(
      result => {
        const imgResultAfterCompress = result;
        item.imageBase64 = result;
        //   this.localCompressedURl = result;
      //  debugger;
        // create file from byte
        //const imageName = fileName;
        //// call method that creates a blob from dataUri
        //const imageBlob = this.dataURItoBlob(imgResultAfterCompress.split(',')[1]);
        ////imageFile created below is the new compressed file which can be send to API in form data
        //const imageFile = new File([result], imageName, { type: 'image/jpeg' });
      });
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  public OkWindow() {
    this.showCropWindow = false;
    this.opennedItem.imageBase64 = this.croppedImage;
  }

  public CloseWindow() {
    this.showCropWindow = false;
  }

  public UpStep(index: number) {

    const swap = this.CategoryItems[index];
    this.CategoryItems[index] = this.CategoryItems[index - 1];
    this.CategoryItems[index - 1] = swap;
  }

  public DownStep(index: number) {
    const swap = this.CategoryItems[index];
    this.CategoryItems[index] = this.CategoryItems[index + 1];
    this.CategoryItems[index + 1] = swap;
  }

}
