import { Injectable } from "@angular/core";

@Injectable()
export class RegistrationStateService {

  public UserName = "";
  public Password = "";
  public ConfirmPassword = "";
  public AcceptPolicy = false;

  public Heading = "";
  public Address = "";
  public AddressNumber = "";
  public City = "";
  public PostCode = "";
  public Country = "";
  public VatCode = "";
  public DeliverySameAddress = true;
  public DeliveryHeading = "";
  public DeliveryAddress = "";
  public DeliveryAddressNumber = "";
  public DeliveryCity = "";
  public DeliveryPostCode = "";
  public DeliveryCountry = "";
  public DeliveryVatCode = "";

  public AccountHolder = "";
  public CreditCardNumber = "";

  public CreditCardMonth = "0";
  public CreditCardYear = "0";
  public CreditCardCVV = "";

  public productId = 0;
  public productDurationType = 0;
  public productName = "";

  public correctState = -1;

}
