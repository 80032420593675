import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CompanyService, PublicCategoryModel, PublicCategoryItem, PublicCompanyModel } from "../../../services/company/CompanyService";
import { GetLangSelectBoxDaata, GetSupportedLanguages, GetSupportedCurrences } from "../../../GlobalModels";
import { TranslateService } from "@ngx-translate/core";
import { OrderService, OrderItem } from "../../../services/user/OrderList";



@Component({
  templateUrl: "publiccompany.page.html"
})
export class PublicCompanyPage implements OnInit, AfterViewInit {

  public pageState = 1;

  public langArray = [ 'en', 'it','eu','us','chf','ge','sk','cs','pl','cn','ru','es','nl','fr','dk','se','hr','hu','pt','no','ar'];

  public companyId = "";
  public langId = -1 ;
  public type = "";
  public parentId = -1;

  public categoryItems = [];

  public parentCategory = -1;
  public productId = -1;

  public drinkCss = "content-public-base-bar-item";
  public foodCss = "content-public-base-bar-item";

  public PublicCategoryItem = new PublicCategoryItem();

  public allergens = [];
  public allergenValues = [];
  public allergenShowValues = [];
  public allergenItemValues = [];

  public nextId = -1;
  public prevId = -1;
  public nextIdCss = "-disabled";
  public prevIdCss = "-disabled";
  // { left: "78%", opacity: 0.5, transform: "translateX(-50%) scale(0.85)" }, { left: "100%", opacity: 0.15, transform: "translateX(-50%) scale(0.6)" }, { left: "65%", opacity: -0.2, transform: "translateX(-50%) scale(0.45)" }, { left: "70%", opacity: -0.55, transform: "translateX(-50%) scale(0.3)" }];
  public langItemCss = [{ left: "50%", opacity: 1, transform:"translateX(-50%)" },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
  { left: "50%", opacity: 1 },
    { left: "50%", opacity: 1 },
    { left: "50%", opacity: 1 }];

  public langItemInfo = [{ left: 50, opacity: 1, scale: 1 }, { left: 78, opacity: 0.5, scale: 0.85 }];
  public langItemStart = [0, -140, -2 * 140, 3 * -140, 4 * -140, 5 * -140, 6 * -140, 7 * -140, 8 * -140, 9 * -140, 10 * -140, 11 * -140, 12 * -140, 13 * -140, 14 * -140, 15 * -140, 16 * -140, 17 * -140, 18 * -140, 19 * -140,  20 * -140];
  public langItemPos = 20;
  public langItemMovment = 0;
  public langClicableIndex = -1;
  public langStartItemMovment = 0;

  public langStartTouch = 0;
  public langTouchAvg = 0;
  public langTouchStarted = false;
  public langCurrentTouchMove = 0;
  public AciveCompany = true;
  public DefautlLangId = -1;
  public CurrentInitText = "";

  public WhatsappActive = false;
  public WhatsappLink = "";

  private leftConst = [{ left: 0.2, opocity: 0.00325, scale: 0.00125 }, { left: 0.2, opocity: 0.0015, scale: 0.0015 } ];

  @ViewChild('profile_lang_id', { static: false }) ProfileLantId: ElementRef;

  public publicCompanyModel: PublicCompanyModel;
  public InitTexts: any[];
  public SupportedLanguage: [];
  public LogoPath = "/assets/images/company_log_def.jpeg";

  public RestaurantTexts: any[] = [];
  public CurrentRestaurantText = "";

  public ComponentLangItems: any[] = [];

  public Currency = "&euro";

  public FrozenProduct = false;

  public SpecialOffers = [];

  public orderItem: OrderItem;

  public listOrderItems: OrderItem[] = [];

  public listWhatsappCss = {};

  public ratingButton = false;

  constructor(public route: ActivatedRoute, public router: Router, public companyService: CompanyService, public translateService: TranslateService,
    public orderService: OrderService) {
    this.PublicCategoryItem.code = 0;
    this.companyService.GetAllergens().subscribe(result => {
      this.allergens = result;
      for (let i = 0; i < this.allergens.length; i++) {
        this.allergenValues.push(this.allergens[i]);
        this.allergenShowValues.push(false);
      }
      this.allergenItemValues = [];
    });
    this.langItemPos = 0;
    this.UpdateLangComponent();
  }

  ngAfterViewInit(): void {
    if (this.pageState === 1) {
      this.ProfileLantId.nativeElement
        .addEventListener('touchmove', (e) => {
          if (this.langTouchStarted === true) {
            this.langMove((this.langTouchAvg - e.touches[0].pageX));
          }
        });
      this.ProfileLantId.nativeElement.addEventListener('touchstart', (e) => {
        this.langTouchAvg = e.touches[0].pageX;
        this.langTouchStarted = true;
        if (this.langStartItemMovment  > 0)
          this.langItemMovment = this.langStartItemMovment;
        this.langStartItemMovment = 0;
      });

      this.ProfileLantId.nativeElement.addEventListener('touchend', (e) => {
        this.langItemMovment = this.langItemPos;
        this.langTouchStarted = false;
      });

      this.ProfileLantId.nativeElement
        .addEventListener('mousemove', (e) => {
          if (this.langTouchStarted === true) {
            this.langMove((this.langTouchAvg - e.pageX));
          }
        });
      this.ProfileLantId.nativeElement.addEventListener('mousedown', (e) => {
        this.langTouchAvg = e.pageX;
        this.langTouchStarted = true;

      });

      this.ProfileLantId.nativeElement.addEventListener('mouseup', (e) => {
        this.langItemMovment = this.langItemPos;
        this.langStartItemMovment = 0;
        this.langTouchStarted = false;
      });

    }
  }

  private langMove(step: number) {
    let _dir = (step > 0 ? 1 : -1);
    

   
    this.langItemPos =  this.langItemMovment + step;
    if (this.langItemPos < 0)
      this.langItemPos = 0;
    if (this.langItemPos > Math.abs(this.langItemStart[this.ComponentLangItems.length-1])) {
      this.langItemPos = Math.abs(this.langItemStart[this.ComponentLangItems.length-1]);
    }
    this.UpdateLangComponent();
  }

  private UpdateLangComponent() {
    this.langClicableIndex = -1;
    for (let j = 0; j < 21; j++) {
      let localLangItemPos = (this.langItemStart[j]) + this.langItemPos;
      let _dir = (localLangItemPos < 0 ? -1 : 1);
      //  if (step > 0 && step < step - this.langItemStart[j])
      //   _dir = 1;
      let _left = 50 - (localLangItemPos * 0.18);
      // console.log(_left);
      //if ((_left <= 22 || _left >= 78) && this.langItemPos === 0) {
      //  this.langItemPos = 1;
      //  _left = 50 - ((i * this.leftConst[this.langItemPos].left) * _dir);

      //}

      //if ((_left > 22 && _left < 78) && this.langItemPos === 1) {
      //  this.langItemPos = 0;
      //  _left = 50 - ((i * this.leftConst[this.langItemPos].left) * _dir);
      //}

      if (_left > 40 && _left < 60)
        this.langClicableIndex = j;

      const _opocity = 1 - (((localLangItemPos) * this.leftConst[0].opocity * _dir));
      const _scale = 1 - (((localLangItemPos) * this.leftConst[0].scale * _dir));

      this.langItemCss[j] = { left: _left + "%", opacity: _opocity, transform: "translateX(-50%) scale(" + _scale + ")" };
       // this.langItemPos += 0.001;
      }
  }

  private GetCurrency(_langId: number) {
    let curencies = GetSupportedCurrences();
    let current_currency = curencies[_langId];
    if (current_currency === "gbp")
      return "gbp";
    else if (current_currency === "euro")
      return "euro";
    else if (current_currency === "usd")
      return "dollar";
    else if (current_currency === "chf")
      return "chf";
    else if (current_currency === "rub")
      return "rub";
    else
      return "euro";

  }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.companyId = routeParams.companyId;
      this.langId = this.langArray.findIndex(function (item) { return item === routeParams.languageId; });

      this.type = routeParams.type;
      const parentId = routeParams.parentId;
      const productId = routeParams.productId;
      if (this.langId !== -1)
      {
        this.pageState = 2;
        this.translateService.use(routeParams.languageId);
        this.companyService.GetPublicCompany(this.companyId, this.langId).subscribe(result => {


          if ((result.yelpLink !== null && result.yelpLink !== "") ||
            (result.googleLink !== null && result.googleLink !== "") ||
            (result.theforkLink !== null && result.theforkLink !== "") ||
            (result.tripadvisorLink !== null && result.tripadvisorLink !== "")
          ) {
            this.ratingButton = true;
          }

          this.WhatsappLink = result.whatsappLink;
          this.WhatsappActive = result.whatsappActive;
          if (this.WhatsappActive === true) {
            this.listWhatsappCss = { position: 'relative', height: '5.5rem' };
          }
          let texts = result.publicInitMessages;
          if (typeof texts !== "undefined" && texts !== null && texts.startsWith("["))
            this.RestaurantTexts = JSON.parse(result.publicInitMessages);
          for (let i = this.RestaurantTexts.length; i < 21; i++)
            this.RestaurantTexts.push("");
          this.CurrentRestaurantText = this.RestaurantTexts[this.langId];
          this.Currency = this.GetCurrency(result.defautlLanguage);
          this.SpecialOffers = result.specialOffers;
          
        });
      }
      if (this.pageState === 2 && typeof productId !== "undefined") {
        this.pageState = 5;
        this.parentId = parseInt(parentId);
        this.parentCategory = this.parentId;
        this.productId = parseInt(productId);

        this.nextId = -1;
        this.prevId = -1;
        this.nextIdCss = "-disabled";
        this.prevIdCss = "-disabled";
        var detail = new PublicCategoryModel();
        detail.CategoryId = this.parentCategory;
        detail.CompanyShortId = this.companyId;
        detail.LanguageId = this.langId;
        this.Currency = this.GetCurrency(2);
        if (this.type === "drink") {
          detail.Type = 0;
        }
        else {
          detail.Type = 1;
        }

        for (let i = 0; i < this.allergenShowValues.length; i++) {
          this.allergenShowValues[i] = false;
        }

        this.companyService.PublicGetCategoryItems(detail).subscribe(result => {
          const _productId = this.productId;

          var _current_index = result.findIndex(function (item) { return item.id === _productId; });
          if (_current_index > 0) {
            this.prevId = result[_current_index - 1].id;
            this.prevIdCss = "";
          }
          if (_current_index < (result.length - 1)) {
            this.nextId = result[_current_index + 1].id;
            this.nextIdCss = "";
          }
        });


        this.companyService.PublicGetDetail(this.productId, this.langId).subscribe(result => {



          

          this.Currency = this.GetCurrency(result.languageId);
          this.FrozenProduct = result.detail.infoFrozenProduct;
          if (result.detail.templateType === 1) {
            this.pageState = 6;



            for (let j = 0; j < result.listItems.length; j++) {
              let orderList = this.orderService.GetProductList(this.companyId, this.productId, result.listItems[j].id);
              if (orderList === null) {
                orderList = new OrderItem();
                orderList.Count = 0;
                orderList.ProductId = this.productId;
                orderList.ProductListId = result.listItems[j].id;
                orderList.ProductPrice = result.listItems[j].price;
                orderList.ProductName = result.listItems[j].title;
                orderList.DefaultName = result.listItems[j].defaultName;
              }
              this.listOrderItems.push(orderList);
            }

          }
          if (result.detail.templateType === 0) {
            this.pageState = 7;

            this.orderItem = this.orderService.GetProduct(this.companyId, this.productId);
            if (this.orderItem === null) {
              this.orderItem = new OrderItem();
              this.orderItem.Count = 0;
              this.orderItem.ProductId = this.productId;
              this.orderItem.ProductPrice = result.detail.price;
              this.orderItem.ProductName = result.detail.title;
              this.orderItem.DefaultName = result.detail.defaultName;
            }

            this.allergenItemValues = [];
            let currentAllergens = [];
            if (result.detail.allergensJson !== null && result.detail.allergensJson.startsWith("["))
              currentAllergens = JSON.parse(result.detail.allergensJson);
            for (let i = 0; i < currentAllergens.length; i++) {
              this.allergenItemValues.push(this.allergenValues.find(function (item) { return item.name === currentAllergens[i] }));
            }
          }
          this.PublicCategoryItem = result;
          if (typeof this.PublicCategoryItem.detail.imageBase64 === "undefined" ||
            this.PublicCategoryItem.detail.imageBase64 === null ||
            this.PublicCategoryItem.detail.imageBase64 === "") {
            this.PublicCategoryItem.detail.imageBase64 = "/assets/images/default1.jpg"
          }
        });

      }
      else if (this.pageState === 2 && typeof parentId !== "undefined") {
        this.pageState = 4;

        this.companyService.GetPublicCompany(this.companyId, this.langId).subscribe(result => {
          this.WhatsappLink = result.whatsappLink;
          this.WhatsappActive = result.whatsappActive;
          this.Currency = this.GetCurrency(result.defautlLanguage);
        });

        
        this.parentId = parseInt(parentId);
        this.parentCategory = this.parentId;
        var detail = new PublicCategoryModel();
        detail.CategoryId = this.parentCategory;
        detail.CompanyShortId = this.companyId;
        detail.LanguageId = this.langId;
        if (this.type === "drink") {
          this.drinkCss = "content-public-base-bar-item-active";
          this.foodCss = "content-public-base-bar-item";
          detail.Type = 0;
        }
        else {
          this.foodCss = "content-public-base-bar-item-active";
          this.drinkCss = "content-public-base-bar-item";
          detail.Type = 1;
        }

        this.companyService.PublicGetCategoryItems(detail).subscribe(result => {
          for (let i = 0; i < result.length; i++) {
            if (typeof result[i].imageBase64 === "undefined" ||
              result[i].imageBase64 === null ||
              result[i].imageBase64 === "") {
              result[i].imageBase64 = "/assets/images/default1.jpg"
            }
          }
          this.categoryItems = result;
        });
      }
      else if (this.pageState === 2 && typeof this.type !== "undefined") {
        this.pageState = 3;
        var detail = new PublicCategoryModel();
        detail.CategoryId = this.parentId;
        detail.CompanyShortId = this.companyId;
        detail.LanguageId = this.langId;
        if (this.type === "drink") {
          this.drinkCss = "content-public-base-bar-item-active";
          this.foodCss = "content-public-base-bar-item";
          detail.Type = 0;
        }
        else {
          this.foodCss = "content-public-base-bar-item-active";
          this.drinkCss = "content-public-base-bar-item";
          detail.Type = 1;
        }
        this.companyService.PublicGetCategoryItems(detail).subscribe(result => {
          for (let i = 0; i < result.length; i++) {
            if (typeof result[i].imageBase64 === "undefined" ||
              result[i].imageBase64 === null ||
              result[i].imageBase64 === "") {
              result[i].imageBase64 = "/assets/images/default2.jpg"
            }
          }
          this.categoryItems = result;
        });
      }
      else if (this.pageState === 1) {
        this.companyService.GetPublicCompany(this.companyId,-1).subscribe(result => {
          if (result.exist === false) {
            this.router.navigate(['/']);
          }
          if (result.active === false) {
            this.AciveCompany = false;
          }
          else {
            if (typeof result.logo !== "undefined" && result.logo !== null && result.logo !== "") {
              this.LogoPath = result.logo;
            }
            this.WhatsappLink = result.whatsappLink;
            this.WhatsappActive = result.whatsappActive;

            let texts = result.initTexts;
            if (typeof texts !== "undefined" && texts !== null && texts.startsWith("["))
              this.InitTexts = JSON.parse(result.initTexts);
            for (let i = this.InitTexts.length; i < 21; i++)
              this.InitTexts.push("");
            this.DefautlLangId = result.defautlLanguage;
;
            this.CurrentInitText = this.InitTexts[this.DefautlLangId];

            let langs: any[] = [], temp_lang: any[] = [];

            if (typeof result !== "undefined" && result !== null && result.supportedLanguages.startsWith("[")) {
              temp_lang = JSON.parse(result.supportedLanguages);
            }
            for (let k = 0; k < temp_lang.length; k++) {
              if (temp_lang[k] !== null && typeof temp_lang[k] !== "undefined")
                langs.push(temp_lang[k]);
            }
            const _defLangId = result.defautlLanguage;
            if (langs.findIndex(function (_item) { return _item === _defLangId; }) === -1)
            {
              langs.push(this.DefautlLangId);
            }

         //   langs.sort(function());
            console.log(langs);
            let langObjects = GetLangSelectBoxDaata(langs as []);
            this.ComponentLangItems = langObjects;

            let _tempDefautlLangId = this.DefautlLangId;
            let indexToScroll = langs.findIndex(function (_item) { return _item === _tempDefautlLangId; });
            this.langItemPos = this.langStartItemMovment = Math.abs(this.langItemStart[indexToScroll]);

            this.UpdateLangComponent();



          }
        })
      }
    });

  }

  public SelectLang(langId: number, index: number) {
    if (this.langClicableIndex === index) {
      this.router.navigate([this.companyId, this.langArray[langId]]);
    }
    
  }

  public SelectType(type: string) {
    this.router.navigate([this.companyId, this.langArray[this.langId], type]);
  }

  public CategoryClick(index: number) {
    if (this.parentId === -1) {
      this.router.navigate([this.companyId, this.langArray[this.langId], this.type, this.categoryItems[index].id]);
    }
    else {
      this.router.navigate([this.companyId, this.langArray[this.langId], this.type, this.parentId, this.categoryItems[index].id]);
    }
  }

  public SpecialOfferClick(index: number) {


    this.router.navigate([this.companyId, this.langArray[this.langId], (this.SpecialOffers[index].categoryType === 0 ? "drink" :"food"), this.SpecialOffers[index].parentCategoryModelId, this.SpecialOffers[index].id]);
  }

  public CloseDetail() {
    this.router.navigate([this.companyId, this.langArray[this.langId], this.type, this.parentId]);
  }

  public BackButton() {
    if (this.pageState === 4)
      this.router.navigate([this.companyId, this.langArray[this.langId], this.type]);
    if (this.pageState === 3)
      this.router.navigate([this.companyId, this.langArray[this.langId]]);
    if (this.pageState === 2)
      this.router.navigate([this.companyId]);
  }

  public OpenOrderList() {
    if (this.productId > -1)
      this.router.navigate([this.companyId, this.langArray[this.langId], 'orders', this.type, this.parentId, this.productId]);
    else if (this.pageState === 4)
      this.router.navigate([this.companyId, this.langArray[this.langId], 'orders', this.type, this.parentId]);
    else if (this.pageState === 3)
      this.router.navigate([this.companyId, this.langArray[this.langId], 'orders', this.type]);
    else if (this.pageState === 2)
      this.router.navigate([this.companyId, this.langArray[this.langId], 'orders']);
    
  }

  public NextButton() {
    if (this.nextId > -1) {
      this.router.navigate([this.companyId, this.langArray[this.langId], this.type, this.parentId, this.nextId]);
    }
  }

  public PrevButton() {
    if (this.prevId > -1) {
      this.router.navigate([this.companyId, this.langArray[this.langId], this.type, this.parentId, this.prevId]);
    }
  }

  public ShowAllergen(index: number) {
    let hideSequence = false;
    for (let i = 0; i < this.allergenShowValues.length; i++) {
      if (this.allergenShowValues[i] === true && i === index)
        hideSequence = true;
      this.allergenShowValues[i] = false;
    }
    if (hideSequence === false)
      this.allergenShowValues[index] = true;
  }


  public OnClickOrder() {

    document.getElementById("orderItem").classList.toggle("order-list-item-box-open");
    document.getElementById("orderCounterItem").classList.toggle("detail-order-list-item-box-counter-open");
  }

  public OnClickListOrder(index: number) {

    document.getElementById("orderListItem"+index).classList.toggle("order-list-item-box-open");
    document.getElementById("orderCounterListItem" + index).classList.toggle("detail-order-list-item-box-counter-open");
  }

  

  public OnOrderDown() {
    this.orderItem.Count--;
    if (this.orderItem.Count < 0) {
      this.orderItem.Count = 0;
    }
    this.orderService.SaveItem(this.companyId,this.orderItem);
    //this.ShowNotificationRemove();
  }

  public OnOrderUp() {
    this.orderItem.Count++;
    this.orderService.SaveItem(this.companyId,this.orderItem);
  //  this.ShowNotificationAdd();
  }

  public OnOrderListDown(index) {
    this.listOrderItems[index].Count--;
    if (this.listOrderItems[index].Count < 0) {
      this.listOrderItems[index].Count = 0;
    }
    this.orderService.SaveListItem(this.companyId, this.listOrderItems[index]);
//    this.ShowNotificationRemoveList();
  }

  public OnOrderListUp(index) {
    this.listOrderItems[index].Count++;
    
    this.orderService.SaveListItem(this.companyId, this.listOrderItems[index]);
  //  this.ShowNotificationAddList();
  }

  public OrderSubmit() {
    this.OpenOrderList();
  }


  public addBoxDown() {
    document.getElementById("notificationBoxAdd").classList.remove("blueBoxShow1");
  }

  public addBoxUp() {
    this.OnOrderUp();
    document.getElementById("notificationBoxAdd").classList.add("blueBoxShow1");
  }

  public removeBoxDown() {
   
    document.getElementById("notificationBoxRemove").classList.remove("blueBoxShow1");
  }

  public removeBoxUp() {
    this.OnOrderDown();
    document.getElementById("notificationBoxRemove").classList.add("blueBoxShow1");
  }


  public addBoxListDown() {
    document.getElementById("notificationBoxAddList").classList.remove("blueBoxShow1");
  }

  public addBoxListUp(index: number) {
    this.OnOrderListUp(index);
    document.getElementById("notificationBoxAddList").classList.add("blueBoxShow1");
  }

  public removeBoxListDown() {

    document.getElementById("notificationBoxRemoveList").classList.remove("blueBoxShow1");
  }

  public removeBoxListUp(index: number) {
    this.OnOrderListDown(index);

    document.getElementById("notificationBoxRemoveList").classList.add("blueBoxShow1");
  }

  public ShowNotificationAdd() {
   // document.getElementById("notificationBoxAdd").classList.toggle("blueBoxShow1");
   // document.getElementById("notificationBoxAdd").classList.toggle("blueBoxShow2");
   // alert("The product has been added to the cart");
    //document.getElementById("notificationBoxAdd").style.display = "block";
    //setTimeout(function() {
    //  document.getElementById("notificationBoxAdd").style.display = "none";
    //}.bind(this), 2000);
  }

  public ShowNotificationRemove() {
    //alert("The product has been removed to the cart");
    document.getElementById("notificationBoxRemove").style.display = "block";
    setTimeout(function (){
      document.getElementById("notificationBoxRemove").style.display = "none";
    }.bind(this), 2000);
  }

  public ShowNotificationAddList() {
    // alert("The product has been added to the cart");
    document.getElementById("notificationBoxAddList").style.display = "block";
    setTimeout(function () {
      document.getElementById("notificationBoxAddList").style.display = "none";
    }.bind(this), 2000);
  }

  public ShowNotificationRemoveList() {
    //alert("The product has been removed to the cart");
    document.getElementById("notificationBoxRemoveList").style.display = "block";
    setTimeout(function () {
      document.getElementById("notificationBoxRemoveList").style.display = "none";
    }.bind(this), 2000);
  }

  public ratingClick() {
    
    this.router.navigate([this.companyId, this.langArray[this.langId],'rating']);
  }

  public openRanking() {
    this.router.navigate([this.companyId, this.langArray[this.langId], 'rating', this.type, this.parentId, this.productId]);
  }
}
