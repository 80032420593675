

<div class="category-items" id="list_items">
  <div class="list-item" *ngFor="let item_category of ListItems; let _tmp_item = index" id="category_item_{{_tmp_item}}">
    <div class="list-item-controls">
      <img class="mobile-hide"  src="/assets/images/drag-icon.svg" />

      <img *ngIf="_tmp_item > 0 " style=" position: relative; float: left;" (click)="UpStep(_tmp_item)" class="desktop-hide" src="/assets/images/arrow_big_up_black.svg" />
      <img *ngIf="_tmp_item == 0 " style=" position: relative; float: left;"  class="desktop-hide" src="/assets/images/arrow_big_up.svg" />


      <img *ngIf="_tmp_item < (ListItems.length -1)" style=" position: relative; float: left;"  (click)="DownStep(_tmp_item)" class="desktop-hide" src="/assets/images/arrow_big_down_black.svg" />
      <img *ngIf="_tmp_item == (ListItems.length -1)" style=" position: relative; float: left;"  class="desktop-hide" src="/assets/images/arrow_big_down.svg" />

      <img class="unsortable" src="/assets/images/visible-icon.svg" *ngIf="item_category.visible" (click)="ChangeVisible(item_category)" />
      <img class="unsortable" src="/assets/images/hidden-icon.svg" *ngIf="!item_category.visible" (click)="ChangeVisible(item_category)" />
      <img class="unsortable" src="/assets/images/remove.svg" style="width: 1rem;" (click)="RemoveItem(item_category,_tmp_item)" />
    </div>
    <div class="list-item-content unsortable">

      <div class="list-input-detail-div">
        <label for="detail_desc">{{ 'Title' |translate }}:</label>
        <input type="text" [(ngModel)]="item_category.title"/>
      </div>
      <div class="list-input-detail-div" style="    border-bottom: 2px solid #F2F2F2;    padding-bottom: 1.5rem;">
        <label for="detail_desc">{{ 'Price' |translate }}:</label>
        <input type="number" [(ngModel)]="item_category.price" style="width: 150px;" />
      </div>


    </div>
  </div>

</div>

<div class="list-item" style="    width: 75%;">
  <div class="category-item-add" (click)="AddItem()" style="    margin: 0px auto 4rem;    left: 0;"></div>
</div>
