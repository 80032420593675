
<TopMenu [LanguageId]="pageLanguageId" Text="" Active="invoices"></TopMenu>

<div class="content_640" style="padding-top: 2rem;">
  <span class="invoice_title">{{ 'Historical invoices' | translate}}:</span>
  <table class="invoice_table">
    <tr *ngFor="let item of invoices" class="invoice_tr">
      <td class="invoice_td">
        {{ item.created.replace('T', ' ').replace('Z', ' ')  }}<br>
        <span *ngFor="let line of item.lines" style="padding: 0.5rem 0rem; display: block;">
          - {{ line.description}}
        </span>
        <span>{{ 'total'|translate}}: {{ item.total / 100 }} &euro;</span>
      </td>
      <td>
        <a class="invoice_link" href="{{ item.invoicePdf }}" target="_blank">
          <img class="invoice_link_img" src="/assets/images/print.svg" />
          {{ 'print' | translate}}
        </a>
      </td>
    </tr>
  </table>

</div>

<div class="footer-black"></div>
