import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';

  public AppState = 3;

  public static Instance: AppComponent;

  constructor(private location: Location, private router: Router,private translate: TranslateService) {

    translate.setDefaultLang('eu');
    translate.addLangs(['en', 'it',"eu","us","ch","ru"])
    AppComponent.Instance = this;
    router.events.subscribe((val) => {

      if (val instanceof NavigationEnd) {
        if (localStorage.getItem('jwt_token_remember') !== null) {
          const exp_date = new Date(localStorage.getItem('jwt_token_remember'));
          const current_date = new Date();
          if (exp_date < current_date) {
            localStorage.removeItem('jwt_token');
            localStorage.removeItem('jwt_sys_role');
          }
        }


        const route = location.path() + "/";
        if (route.indexOf("/admin/") <= 5 && route.indexOf("/admin/") > -1) {

          if (route === "/admin/" || route === "/admin") {
            this.router.navigate(['/eu', 'admin']);
          }
          else {
            if (localStorage.getItem('jwt_token') !== null &&
              localStorage.getItem('jwt_sys_role').toLowerCase() === "true") {

              this.setAppState(1);
            }
            else {
              this.setAppState(3);
            }
          }
        }
        else if (route.indexOf("/company/") <= 5 && route.indexOf("/company/") > -1)
        {
          
          if (route === "/company/" || route === "/company") {
            this.router.navigate(['/eu', 'company']);
          }
          else {
            
            // this.AppState = 3;
            if (localStorage.getItem('jwt_token') !== null &&
              localStorage.getItem('jwt_sys_role').toLowerCase() === "false") {
              this.setAppState(2);
              // this.setAppState(2);

            }
            else {
              this.setAppState(3);
              //  this.AppState = 3;
            }
          }
        }
        else {

          if (route === "/resetpassword/" || route === "/resetpassword") {
            this.router.navigate(['/eu', 'resetpassword']);
          }
          else if (route === "/registration/" || route === "/registration") {
            this.router.navigate(['/eu', 'registration']);
          }
          else {
            this.setAppState(4);
          }
        }
      }
    });

  }

  ngOnInit() {
  }

  private setAppState(newState: number) {
    if (this.AppState !== newState) {
      this.AppState = newState;
      console.log("this.AppState = newState; = " + this.AppState);
    }
  }

  public useLanguage(language: string) {
    this.translate.use(language);
  }
}
