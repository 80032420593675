
<div style="display:block">
<div *ngIf="DataTableConfig.add" class="col-6" style="margin-bottom: 1rem;max-width:200px;float: right;margin-right: 1rem">
  <button  class="admin_button" (click)="AddButtonOnClick()">{{ 'Add new item'| translate}}</button>
</div>

<table class="data-teble-1">
  <tr class="data-teble-1-header">
    <td *ngFor="let item of DataSource.GetHeaderColumns()">{{ item }}</td>
    <td style="width: 225px;">{{ 'Actions'| translate}}</td>
  </tr>
  <tr *ngIf="loading">
    <td [attr.colspan]="headerCount">
      <div class="spinner1" style="margin-top: 2rem;margin-bottom: 2rem;"></div>
    </td>
  </tr>
  <tr *ngFor="let item of _dataSource" class="data-teble-1-row">

    <td *ngFor="let item2 of DataSource.GetItemColumns()">
      {{ item[item2] }}
    </td>
    <td>
      <a class="update-link" *ngIf="DataTableConfig.update" (click)="UpdateOnClick(item.id)">{{ 'Modify'| translate}}</a>&nbsp;
      <a class="delete-link" *ngIf="DataTableConfig.remove" (click)="RemoveOnClick(item.id)">{{ 'Remove'| translate}}</a>
    </td>
  </tr>
  <tr *ngIf="noData">
    <td [attr.colspan]="headerCount" style="text-align: center; font-size: 1.5rem;">
      <StaticBox Text="No data"></StaticBox>
    </td>
  </tr>
</table>
  </div>

