/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./orderhelp.page";
import * as i3 from "../../../services/user/OrderList";
import * as i4 from "@angular/router";
var styles_OrderHelpPage = [];
var RenderType_OrderHelpPage = i0.ɵcrt({ encapsulation: 2, styles: styles_OrderHelpPage, data: {} });
export { RenderType_OrderHelpPage as RenderType_OrderHelpPage };
export function View_OrderHelpPage_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "order-public-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "content-public-back"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.BackButton() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "order-public-header-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["class", "order-public-header-icon-svg"], ["src", "/assets/images/help.svg"], ["style", "width: 1.5rem;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [["class", "order-public-header-icon-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "order-help-message-box"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", "\n"])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("Help")); _ck(_v, 5, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("order-help-message")); _ck(_v, 8, 0, currVal_1); }); }
export function View_OrderHelpPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_OrderHelpPage_0, RenderType_OrderHelpPage)), i0.ɵdid(1, 114688, null, 0, i2.OrderHelpPage, [i3.OrderService, i4.ActivatedRoute, i4.Router, i1.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrderHelpPageNgFactory = i0.ɵccf("ng-component", i2.OrderHelpPage, View_OrderHelpPage_Host_0, {}, {}, []);
export { OrderHelpPageNgFactory as OrderHelpPageNgFactory };
