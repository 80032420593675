/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/ng2-select2/ng2-select2.component.ngfactory";
import * as i2 from "ng2-select2/ng2-select2.component";
import * as i3 from "./langselectbox";
import * as i4 from "@ngx-translate/core";
var styles_LangSelectBox = [];
var RenderType_LangSelectBox = i0.ɵcrt({ encapsulation: 2, styles: styles_LangSelectBox, data: {} });
export { RenderType_LangSelectBox as RenderType_LangSelectBox };
export function View_LangSelectBox_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "select2", [], null, [[null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChanged" === en)) {
        var pd_0 = (_co.changed($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Select2Component_0, i1.RenderType_Select2Component)), i0.ɵdid(1, 4964352, null, 0, i2.Select2Component, [i0.Renderer], { data: [0, "data"], value: [1, "value"], options: [2, "options"] }, { valueChanged: "valueChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._dataSource; var currVal_1 = _co._selectedLang; var currVal_2 = _co.options; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_LangSelectBox_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "LangSelectBox", [], null, null, null, View_LangSelectBox_0, RenderType_LangSelectBox)), i0.ɵdid(1, 4243456, null, 0, i3.LangSelectBox, [i4.TranslateService, i0.ChangeDetectorRef], null, null)], null, null); }
var LangSelectBoxNgFactory = i0.ɵccf("LangSelectBox", i3.LangSelectBox, View_LangSelectBox_Host_0, { Id: "Id", DataSource: "DataSource", SelectedValue: "SelectedValue", SelectedChange: "SelectedChange" }, {}, []);
export { LangSelectBoxNgFactory as LangSelectBoxNgFactory };
