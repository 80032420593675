import { Component, OnInit, AfterViewInit } from "@angular/core";
import { OrderService, OrderItem } from "../../../services/user/OrderList";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CompanyService } from "../../../services/company/CompanyService";
import { GetSupportedCurrences } from "../../../GlobalModels";





@Component({
  templateUrl: "orderlist.page.html"
})
export class OrderListPage implements OnInit, AfterViewInit {

  public langArray = ['en', 'it', 'eu', 'us', 'chf', 'ge', 'sk', 'cs', 'pl', 'cn', 'ru', 'es', 'nl', 'fr', 'dk', 'se', 'hr', 'hu', 'pt', 'no', 'ar'];

  public orderList: OrderItem[];
  public TotalSum: number = 0;

  public type =  "";
  public parentId = -1;
  public productId = -1;
  public companyId = -1;
  public langId = -1;

  public Currency = "euro";

  public FormName = "";
  public FormLocation = "";
  public FormText = "";

  public SendNameText = "Hi, I'm";
  public SendNameLocation = "Delivery address";
  public SendSpecialRequests = "Special requests";
  public SendTotal = "Total";
  public whatsappLink = "";

  constructor(private orderService: OrderService, public route: ActivatedRoute, public router: Router,
    public translateService: TranslateService, private companyService: CompanyService) {


  }

    ngAfterViewInit(): void {
       
  }


  private GetCurrency(_langId: number) {
    let curencies = GetSupportedCurrences();
    let current_currency = curencies[_langId];
    if (current_currency === "gbp")
      return "gbp";
    else if (current_currency === "euro")
      return "euro";
    else if (current_currency === "usd")
      return "dollar";
    else if (current_currency === "chf")
      return "chf";
    else if (current_currency === "rub")
      return "rub";
    else
      return "euro";

  }

  ngOnInit(): void {

    this.route.params.subscribe(routeParams => {

      this.companyId = routeParams.companyId;
      this.langId = this.langArray.findIndex(function (item) { return item === routeParams.languageId; });
      this.translateService.use(routeParams.languageId);
      this.type = routeParams.type;
      this.parentId = routeParams.parentId;
      this.productId = routeParams.productId;

      this.companyService.GetPublicCompany("" + this.companyId, this.langId).subscribe(result => {
        this.whatsappLink = result.whatsappLink;
        if (result.whatsappActive === false)
          this.router.navigate([this.companyId, this.langArray[this.langId]]);
        this.Currency = this.GetCurrency(result.defautlLanguage);
        this.companyService.GetWhatsappText(result.defautlLanguage).subscribe(result => {
          this.SendNameText = result.text1;
          this.SendNameLocation = result.text2;
          this.SendSpecialRequests = result.text3;
          this.SendTotal = result.text4;
        });
      });


    });


    this.orderList = this.orderService.GetItems("" + this.companyId);
    for (let i = 0; i < this.orderList.length; i++) {
      this.orderList[i].TmpCount = this.orderList[i].Count;
    }
    this.SumCalc();
   }


  public OnClickEdit(index: number) {
    this.orderList[index].TmpCount = this.orderList[index].Count;
    document.getElementById("orderItem" + index).classList.toggle("order-list-item-box-open");
    document.getElementById("orderCounterItem" + index).classList.toggle("order-list-item-box-counter-open");

  }

  public MinusItem(index: number) {
    this.orderList[index].TmpCount--;
    if (this.orderList[index].TmpCount < 1)
      this.orderList[index].TmpCount = 1;
  }

  public SumCalc() {
    this.TotalSum = 0;
    for (let i = 0; i < this.orderList.length; i++) {
      this.TotalSum += (this.orderList[i].Count * this.orderList[i].ProductPrice);
    }
  }

  public PlusItem(index: number) {
    this.orderList[index].TmpCount++;
   }

  public OkItem(index: number) {
    this.orderList[index].Count = this.orderList[index].TmpCount;
    document.getElementById("orderItem" + index).classList.toggle("order-list-item-box-open");
    document.getElementById("orderCounterItem" + index).classList.toggle("order-list-item-box-counter-open");
    this.orderService.SaveItem(""+this.companyId,this.orderList[index]);
    this.SumCalc();
  }

  public RemoveItem(index: number) {
    this.orderList[index].Count = 0;
    this.orderService.SaveItem("" + this.companyId,this.orderList[index]);
    this.orderList.slice(index, 1);
    this.SumCalc();
  }

  public BackButton() {
    if (this.productId > -1)
      this.router.navigate([this.companyId, this.langArray[this.langId], this.type, this.parentId, this.productId]);
    else if (this.parentId > -1)
      this.router.navigate([this.companyId, this.langArray[this.langId], this.type, this.parentId]);
    else if (typeof this.type !== "undefined" && this.type !== "")
      this.router.navigate([this.companyId, this.langArray[this.langId], this.type]);
    else
      this.router.navigate([this.companyId, this.langArray[this.langId]]);
  }

  public HelpButton() {
    if (this.productId > -1)
      this.router.navigate([this.companyId, this.langArray[this.langId],'order-help', this.type, this.parentId, this.productId]);
    else if (this.parentId > -1)
      this.router.navigate([this.companyId, this.langArray[this.langId], 'order-help', this.type, this.parentId]);
    else if (typeof this.type !== "undefined" && this.type !== "")
      this.router.navigate([this.companyId, this.langArray[this.langId], 'order-help', this.type]);
    else
      this.router.navigate([this.companyId, this.langArray[this.langId],'order-help']);
  }

  public SendOrder() {
    if (this.FormName === "") {
      alert("Name is required.");
      return;
    }
    else if (this.FormLocation === "") {
      alert("Location is required.");
      return;
    }
    else {
      var msg = encodeURI(this.SendNameText); 
      msg += encodeURI(" " + this.FormName) + "." + '%0a';
      msg += encodeURI(this.SendNameLocation + ": " + this.FormLocation) + "." + '%0a';
      msg += encodeURI(this.SendSpecialRequests + ": " + this.FormText) + "." +'%0a' + '%0a';
      for (let i = 0; i < this.orderList.length; i++) {
        msg += encodeURI("- " + this.orderList[i].Count + " x " + this.orderList[i].DefaultName) + '%0a';
      }
      msg += '%0a' + encodeURI(this.SendTotal + " " + this.TotalSum);

      if (this.Currency === 'gbp')
        msg += " POUNDS";
      else if (this.Currency === 'dollar')
        msg += " DOLLARS";
      else if (this.Currency === 'chf')
        msg += " CHF";
      else if (this.Currency === 'rub')
        msg += " RUB";
      else
        msg += " EUR";
      this.orderService.Clear();
      this.orderList = this.orderService.GetItems("" + this.companyId);
      for (let i = 0; i < this.orderList.length; i++) {
        this.orderList[i].TmpCount = this.orderList[i].Count;
      }
      this.SumCalc();
      window.open("https://api.whatsapp.com/send/?phone=" + this.whatsappLink + "&text=" + msg);
      
    }
  }
}
