import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserService, PasswordRecoverModel } from "../../../services/user/UserService";



@Component({
  templateUrl: 'resetpassword.page.html'
})
export class ResetPasswordPage implements OnInit {

  public pageLanguageId = "eu";
  public PageState = 1;
  private hashValue = "";
  public UserEmail = "";
  public UserNameClass = "default_input";
  public sing_box = "";

  public StaticBoxText = "To recover your password we need  your email address";
  public StaticBoxText2 = "Put your a new password"

  public NewPassword = "";
  public ConfirmNewPassword = "";

  public NewPasswordClass = "default_input";
  public ConfirmPasswordClass = "default_input";

  public ShowButton = false;
  public ShowButton1 = false;


  constructor(private route: ActivatedRoute, public translateService: TranslateService,
    public userService: UserService, public router: Router) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.pageLanguageId = routeParams.languageId;
      this.translateService.use(this.pageLanguageId);
      const _pageState = routeParams.pageState;
      this.hashValue = routeParams.hashValue;
      if (_pageState === "recover") {
        this.PageState = 2;
      }
      

    });
  }

  public Recover() {
    if (this.UserEmail === "") {
      this.UserNameClass += " input_error";
    }
    else {
      this.userService.CreatePasswordRecoverToken(this.UserEmail).subscribe(restult => {
        this.sing_box = " hide_item";
        this.StaticBoxText = "Recover instructions have been sended to your email.";
        this.ShowButton1 = true;
      });
    }
  }

  public RecoverStep2() {
    if (this.NewPassword === "") {
      this.NewPasswordClass += " input_error";
    }
    else if (this.NewPassword !== this.ConfirmNewPassword) {
      this.ConfirmPasswordClass += " input_error";
    }
    else {
      let new_item = new PasswordRecoverModel();
      new_item.NewPassword = this.NewPassword;
      new_item.Token = this.hashValue;
      this.userService.PasswordRecover(new_item).subscribe(restult => {
        this.ShowButton = true;
        this.sing_box = " hide_item";
        if (restult.code === 0) {
          this.StaticBoxText2 = "Unknow error";
        }
        else {
          this.StaticBoxText2 = "Password has been restored successfully.";
        }

      });
    }
  }

  public ReturnToSignIn() {
    this.router.navigate(['/' + this.pageLanguageId,"company"]);
  }

}
