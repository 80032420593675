import { CompanyService, PublicCompanyRegistrationModel } from "./CompanyService";
import { Injectable } from "@angular/core";


@Injectable()
export class CompanyStateService {
  private LoadedData = false;
  public SelectedLanguageId = 0;

  constructor(private companyService: CompanyService) {

  }

}
