

<div class="category-items{{ long_postfix }}" id="category_items">
  <ng-container  *ngFor="let item_category of CategoryItems; let _tmp_item = index">
    <div  *ngIf="SpecialOffer == false || item_category.highliting == true" class="category-item{{ long_postfix }}" id="category_item_{{_tmp_item}}">
      <div class="category-item-controls">
        <img class="mobile-hide" src="/assets/images/drag-icon.svg" />
        <img *ngIf="_tmp_item > 0 " (click)="UpStep(_tmp_item)" class="desktop-hide" src="/assets/images/arrow_big_up_black.svg" />
        <img *ngIf="_tmp_item == 0 " class="desktop-hide" src="/assets/images/arrow_big_up.svg" />


        <img *ngIf="_tmp_item < (CategoryItems.length -1)" (click)="DownStep(_tmp_item)" class="desktop-hide" src="/assets/images/arrow_big_down_black.svg" />
        <img *ngIf="_tmp_item == (CategoryItems.length -1)" class="desktop-hide" src="/assets/images/arrow_big_down.svg" />

        <img class="unsortable" src="/assets/images/edit-icon.svg" *ngIf="item_category.id > 0" (click)="OpenSubcategory(item_category)" />

        <img class="unsortable" src="/assets/images/visible-icon.svg" *ngIf="item_category.visible" (click)="ChangeVisible(item_category)" />
        <img class="unsortable" src="/assets/images/hidden-icon.svg" *ngIf="!item_category.visible" (click)="ChangeVisible(item_category)" />
        <img *ngIf="Highliting == true && item_category.highliting == true" class="unsortable" src="/assets/images/fire.svg" (click)="ChangeHighliting(item_category)" alt="Special offer" />
        <img *ngIf="Highliting == true && item_category.highliting == false" class="unsortable" src="/assets/images/fire-hidden.svg" (click)="ChangeHighliting(item_category)" alt="Special offer" />
        <img class="unsortable" src="/assets/images/remove.svg" style="width: 1rem;    margin-left: 4px;" (click)="RemoveItem(item_category)" />
      </div>
      <div class="unsortable  category-item-content{{ long_postfix }}">
        <div style="position: relative;">
          <label for="file-input-{{_tmp_item}}" class="file-input-label">
            <img class="file-input-real{{ long_postfix }}" [src]="item_category.imageBase64" />
            <div class="file-input-text">{{ 'add image' | translate }}</div>
            <!--<img class="file-input-mask" src="/assets/images/add-image-1.png" />-->
          </label>
          <input id="file-input-{{_tmp_item}}" type="file" accept=".jpg,.png,.jpeg" (change)="handleUpload($event,item_category)" />
        </div>

        <textarea [(ngModel)]="item_category.title">

      </textarea>
      </div>
    </div>
  </ng-container>
  <div class="category-item unsortable">
    <div class="category-item-add" (click)="AddItem()"></div>
  </div>
</div>


<div class="crop-window" style=" z-index: 1000;" *ngIf="showCropWindow == true">
  <div class="crop-window-controls">
    <button class="button_black" (click)="OkWindow()">{{ 'save' | translate}}</button>
    <button class="button_black" (click)="CloseWindow()">{{ 'cancel' | translate}}</button>
  </div>
  <image-cropper [imageChangedEvent]="imageChangedEvent"
                 [aspectRatio]="AspectRatio"
                 [maintainAspectRatio]="true"
                 [resizeToWidth]="ImageWidth"
                 [resizeToHeight]="ImageHeight"
                 [cropperMinWidth]="ImageWidth"
                 [cropperMinHeight]="ImageHeight"
                 format="png"
                 (imageCropped)="imageCropped($event)"></image-cropper>

</div>
