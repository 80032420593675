import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { SignInPage } from './pages/admin/signin/signin.page';
import { LangBoxComponent } from './components/langbox/langbox.component';
import { StaticBoxComponent } from './components/statictext/staticbox.component';
import { CheckBoxComponent } from './components/checkbox/checkbox.component';
import { UserService } from './services/user/UserService';
import { DashboardAdminPage } from './pages/admin/dashboard/dashboard.page';
import { LeftMenuComponent } from './components/LeftMenu/leftmenu.component';
import { TopBarComponent } from './components/TopBar/topbar.component';
import { SignOutComponent } from './components/SignOut/signout.component';
import { UsersAdminPage } from './pages/admin/users/users.page';
import { TokenInterceptor, AuthErrorHandler } from './token.interceptor';
import { DataTableComponent } from './components/DataTable/datatable.component';
import { CompaniesAdminPage } from './pages/admin/companies/companies.page';
import { CompanyService } from './services/company/CompanyService';
import { RegistrationPage } from './pages/public/registration/registration.page';
import { ProfilePage } from './pages/restaurant/profile/profile.page';
import { TopMenuComponent } from './components/TopMenu/topmenu.component';
import { UserCompanyPage } from './pages/restaurant/user/usercompany.page';
import { MenuPage } from './pages/restaurant/Menu/menu.page';
import { CategoryItemsComponent } from './components/CategoryItems/categoryitems.component';
import { RegistrationStateService } from './services/registrationState/RegistrationStateService';
import { CompanyStateService } from './services/company/CompanyStateService';
import { NgxImageCompressService } from 'ngx-image-compress';
import { CategoryCarusalComponent } from './components/CategoryCarusal/categorycarusal.component';
import { ListItemsComponent } from './components/ListItems/listitems.component';
import { PublicCompanyPage } from './pages/public/publiccompany/publiccompany.page';
import { Select2Module } from 'ng2-select2';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ToastComponent } from './components/Toast/toast.component';
import { InvoicePage } from './pages/restaurant/Invoice/invoice.page';
import { LangSelectBox } from './components/langselectbox/langselectbox';
import { ResetPasswordPage } from './pages/public/resetpassword/resetpassword.page';
import { DefaulAppPage } from './pages/public/main/main.page';

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { MenuSubCategoryPage } from './pages/restaurant/Menu/menu_subcatrogry';
import { MenuDetailPage } from './pages/restaurant/Menu/menu_detail';
import { AllergenSelectBox } from './components/allergenselectbox/allergenselectbox';
import { OrderListPage } from './pages/public/orderlist/orderlist.page';
import { OrderService } from './services/user/OrderList';
import { OrderHelpPage } from './pages/public/orderhelp/orderhelp.page';
import { AccessoryPage } from './pages/restaurant/Accessory/accessory.page';
import { RatingPage } from './pages/public/rating/rating.page';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    SignInPage,
    LangBoxComponent,
    StaticBoxComponent,
    CheckBoxComponent,
    DashboardAdminPage,
    LeftMenuComponent,
    TopBarComponent,
    SignOutComponent,
    UsersAdminPage,
    DataTableComponent,
    StaticBoxComponent,
    CompaniesAdminPage,
    RegistrationPage,
    ProfilePage,
    TopMenuComponent,
    UserCompanyPage,
    MenuPage,
    CategoryItemsComponent,
    CategoryCarusalComponent,
    ListItemsComponent,
    PublicCompanyPage,
    ToastComponent,
    InvoicePage,
    LangSelectBox,
    ResetPasswordPage,
    DefaulAppPage,
    MenuDetailPage,
    MenuSubCategoryPage,
    AllergenSelectBox,
    
    OrderListPage,
    OrderHelpPage,
    AccessoryPage,
    RatingPage
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    Select2Module,
    ImageCropperModule,
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    RouterModule.forRoot([
      { path: 'admin', component: DashboardAdminPage, pathMatch: 'full' },
      { path: ':languageId/admin', component: DashboardAdminPage, pathMatch: 'full' },
      { path: ':languageId/admin/users/:formState/:id', component: UsersAdminPage },
      { path: ':languageId/admin/users/:formState', component: UsersAdminPage },
      { path: ':languageId/admin/users', component: UsersAdminPage },
      { path: ':languageId/admin/companies/:formState/:id', component: CompaniesAdminPage },
      { path: ':languageId/admin/companies/:formState', component: CompaniesAdminPage },
      { path: ':languageId/admin/companies', component: CompaniesAdminPage },
      { path: 'admin', component: DashboardAdminPage, pathMatch: 'full' },
      { path: ':languageId/registration/:pageState', component: RegistrationPage },
      { path: ':languageId/registration', component: RegistrationPage },
      { path: 'registration', component: RegistrationPage },
      { path: ':languageId/company/accessory', component: AccessoryPage },
      { path: ':languageId/company/invoice', component: InvoicePage },
      { path: ':languageId/company/user', component: UserCompanyPage },
      { path: ':languageId/company/menu/detail/:type/:subCategory/:detailId/:translateLangId', component: MenuDetailPage },
      { path: ':languageId/company/menu/sub-category/:type/:subCategory/:translateLangId', component: MenuSubCategoryPage },
      { path: ':languageId/company/menu/:type/:translateLangId', component: MenuPage },
      { path: ':languageId/company/menu', component: MenuPage },
      { path: ':languageId/company/:pageState', component: ProfilePage },
      { path: ':languageId/company', component: ProfilePage },

      { path: ':companyId/:languageId/rating/:type/:parentId/:productId', component: RatingPage },
      { path: ':companyId/:languageId/rating', component: RatingPage },

      { path: 'company', component: ProfilePage },
      { path: ':companyId/:languageId/orders/:type/:parentId/:productId', component: OrderListPage },
      { path: ':companyId/:languageId/orders/:type/:parentId', component: OrderListPage },
      { path: ':companyId/:languageId/orders/:type', component: OrderListPage },
      { path: ':companyId/:languageId/orders', component: OrderListPage },


      { path: ':companyId/:languageId/order-help/:type/:parentId/:productId', component: OrderHelpPage },
      { path: ':companyId/:languageId/order-help/:type/:parentId', component: OrderHelpPage },
      { path: ':companyId/:languageId/order-help/:type', component: OrderHelpPage },
      { path: ':companyId/:languageId/order-help', component: OrderHelpPage },

      { path: ':languageId/resetpassword/:pageState/:hashValue', component: ResetPasswordPage },
      { path: ':languageId/resetpassword', component: ResetPasswordPage },
      { path: ':companyId/:languageId/:type/:parentId/:productId', component: PublicCompanyPage },
      { path: ':companyId/:languageId/:type/:parentId', component: PublicCompanyPage },
      { path: ':companyId/:languageId/:type', component: PublicCompanyPage },
      { path: ':companyId/:languageId', component: PublicCompanyPage },
      { path: ':companyId', component: PublicCompanyPage },
      { path: '', component: DefaulAppPage, pathMatch: 'full' }


      //  { path: 'admin', component: SignInPage },
    //], { onSameUrlNavigation: 'reload' })
      ])
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AuthErrorHandler
    },
    UserService,
    CompanyService,
    RegistrationStateService,
    CompanyStateService,
    NgxImageCompressService,
    OrderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

