
<div *ngIf="PageState == 0">
  <h2>{{ 'Users'|translate }}</h2>
  <DataTable [DataSource]="dataTableSource" [DataTableConfig]="dataTableConfig">

  </DataTable>
</div>

<div *ngIf="PageState == 1" class="col-6">
  <a class="back-button" [routerLink]="['/'+pageLanguageId,'admin','users']">
    <i class="si icon-arrow-left" style="font-size: 0.75rem;"></i>
    {{ 'back'|translate }}
  </a>

  <h2 style="    margin-top: 1rem;">{{ 'New user'|translate }}</h2>
  <div class="col-12">
    <div class="col-6">
      <label for="UserName">
        {{ 'User name'|translate }}
      </label>
      <input type="text" [(ngModel)]="UserName" id="UserName" />
    </div>
  </div>
  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="Password">{{ 'Password'|translate }}</label>
      <input type="password" [(ngModel)]="Password" id="Password" />
    </div>
    <div class="col-6">
      <label for="RePassword">{{ 'Confirm password'|translate }} </label>
      <input type="password" [(ngModel)]="RePassword" id="RePassword" />
    </div>
  </div>
  <div class="col-12">
    <div class="col-4" style="max-width:200px;    margin-top: 3rem;">
      <button class="admin_button" (click)="AddSubmit()">{{ 'Add user'|translate }}</button>
    </div>
  </div>
</div>

<div *ngIf="PageState == 2" class="col-6">
  <a class="back-button" [routerLink]="['/'+pageLanguageId,'admin','users']">
    <i class="si icon-arrow-left" style="font-size: 0.75rem;"></i>
    {{ 'back'|translate }}
  </a>
  <h2 style="    margin-top: 1rem;">Update user</h2>
  <div class="col-12">
    <div class="col-6">
      <label for="UserName">
        {{ 'User name'|translate }}
      </label>
      <input type="text" [(ngModel)]="UserName" id="UserName" disabled />
    </div>
  </div>
  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="Password">{{ 'Password'|translate }}</label>
      <input type="password" [(ngModel)]="Password" id="Password" />
    </div>
    <div class="col-6">
      <label for="RePassword">{{ 'Confirm password'|translate }}</label>
      <input type="password" [(ngModel)]="RePassword" id="RePassword" />
    </div>
  </div>
  <div class="col-12">
    <div class="col-4" style="max-width:200px;    margin-top: 3rem;">
      <button class="admin_button" (click)="UpdateSubmit()">{{ 'Update user'|translate }}</button>
    </div>
  </div>
</div>
