import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { GetSupportedLanguages } from '../../GlobalModels';


@Component({
  selector: 'LeftMenuComponent',
  templateUrl: './leftmenu.component.html',

})
export class LeftMenuComponent  {

  public LanguageId = 'en';

  constructor(private location: Location) {
    const route = location.path() + "/";

    const langs = GetSupportedLanguages();
    for (let i = 0; i < langs.length; i++) {
      if (route.startsWith("/"+langs[i]+"/")) {
        this.LanguageId = langs[i];
      }
    }
  }

}
