import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResponseCodeModel } from "../../GlobalModels";


export class AddCompanyModel {
  public id: number;

  public userName: string;
  public password: string;

  public name: string;
  public surname: string;
  public street: string;
  public streetNumber: string;
  public city: string;
  public postCode: string;
  public country: string;
  public companyName: string;

  public companyId: string;

  public vat: boolean;
  public companyVAT: string;
  public companyVAT_ID: string;

  public deliveryName: string;
  public deliverySurname: string;
  public deliveryStreet: string;
  public deliveryStreetNumber: string;
  public deliveryCity: string;
  public deliveryPostCode: string;
  public deliveryCountry: string;
  public deliveryCompanyName: string;

  public languageDefault: number;
  public defaultMessage: string;

  public expirationDate: string;

  public updateExpirationDate: boolean;

  public shortcutId: string;
}

export class CompanyListModel {
  public id: number;
  public companyName: string;
  public state: string;
  public shortcutId: string;
  public email: string;
}

export class PublicCompanyRegistrationModel {
  public userName = "";
          public password = "";

          public name = "";
          public surname = "";
          public street = "";
          public streetNumber = "";
          public city = "";
          public postCode = "";
          public country = "";
          public companyName = "";

          public companyId = "";

  public vat = true;
          public companyVAT = "";
          public companyVAT_ID = "";

          public deliveryName = "";
          public deliverySurname = "";
          public deliveryStreet = "";
          public deliveryStreetNumber = "";
          public deliveryCity = "";
          public deliveryPostCode = "";
          public deliveryCountry = "";
          public deliveryCompanyName = "";


          public logoBase64 = "";

  public languageDefault = 0;
          public defaultMessage = "";

          public expirationDate = "";

          public creditCardAccountHolder = "";
    public creditCardNumber = "";
    public creditCardMonth = 1
  public creditCardYear = 1;
  public creditCardCVV = 0;

  public numberOfTables = 0;

  public productName = "";
  public captchaToken = "";
    }

export class CategoryModel {
  public id: number;

  public companyProfileModel: number;

  public title: string;
  public order: number;
  public visible: boolean;

  public imageBase64: string;

  public parentCategoryModelId: number;

  public categoryType: number;

  public languageId: number;
  public highliting: boolean;
  public price: number;
  public showPrice: boolean;
}


export class CategoryItemsModel {

  public id: number;

  public categoryModelId: number;

  public title: string
  public desciption: string

  public order: number;
  public visible: boolean

  public imageBase64: string;

  public price: number;

  public templateType: number;

  public infoFrozenProduct: boolean;

  public allergensJson: string;

  public showPrice: boolean;

  public defaultName: string;
}

export class CategoryListItemModel {
  public id: number;

  public categoryModelId: number;


  public order: number;
  public visible: boolean;

  public title: string;
  public price: number;
  public defaultName: string;
}

export class PublicCategoryItem {
  public detail: CategoryItemsModel;
  public listItems: CategoryListItemModel[];
  public code: number;
  public languageId: number;

}

export class Allergen{
  public name: string;
  public img: string;
}

export class PublicCategoryModel {
  public LanguageId: number;
  public CategoryId: number;
  public CompanyShortId: string
  public Type: number;
}

export class CompanyProfilModel {
  public companyName: string
  public numberOfTables: number;
  public imageBase64: string;
  public expireDate: string;
  public translateLanguages: string;
  public defaultLanguage: number;
  public initialText: string;
  public companyId: string;
  public publicInitMessages: string;
  public whatsappActivate: boolean;
  public whatsappLink: string;

  public tripadvisorLink: string;
  public googleLink: string;
  public yelpLink: string;
  public theforkLink: string;
}

export class UpdateInitialText {
  public initialText: string;
}

export class PublicUpdatePassword {
  public currentPassword: string;
  public newPassword: string;
}

export class StripeProduct {
  public name: string;
  public price: number;
  public country: string;
  public currency: string;
}

export class PublicCompanyModel {
  public exist: boolean;
  public active: boolean;
  public logo: string;
  public initTexts: string;
  public supportedLanguages: string;
  public defautlLanguage: number;
  public publicInitMessages: string;
  public specialOffers: CategoryModel[];
  public whatsappActive: boolean;
  public whatsappLink: string;

  public tripadvisorLink: string;
  public googleLink: string;
  public yelpLink: string;
  public theforkLink: string;

}

export class CompanyNewCreditCard {
  public creditCardAccountHolder: string;
  public creditCardNumber: string;
  public creditCardMonth: number;
  public creditCardYear: number;
  public creditCardCVV: number;
}

export class SystemVersion {
  public version: string;
}

export class WhatsappItem {
  public langId: number;
  public text1: string;
  public text2: string;
  public text3: string;
  public text4: string;
}

export class  CompanyAccessoryModel {
  public whatsappActivate: boolean;
  public whatsappLink: string;

  public tripadvisorLink: string;
  public googleLink: string;
  public yelpLink: string;
  public theforkLink: string;
}

@Injectable()
export class CompanyService {

  constructor(private Http: HttpClient) {

  }


  public GetCompanyList(page: number, count: number): Observable<CompanyListModel[]> {
    return this.Http.get<CompanyListModel[]>("/Api/Company/GetCompanyList/?page=" + page + "&count=" + count);

  }

  public AddCompany(companyModel: AddCompanyModel): Observable<ResponseCodeModel> {
    return this.Http.post<ResponseCodeModel>("/Api/Company/AddCompany/", companyModel);
  }

  public UpdateCompany(companyModel: AddCompanyModel): Observable<ResponseCodeModel> {
    return this.Http.post<ResponseCodeModel>("/Api/Company/UpdateCompany/", companyModel);
  }

  public GetCompany(id: number): Observable<AddCompanyModel> {
    return this.Http.get<AddCompanyModel>("/Api/Company/GetCompany/?id=" + id);
  }

  public RemoveCompany(id: number): Observable<ResponseCodeModel> {
    return this.Http.delete<ResponseCodeModel>("/Api/Company/RemoveCompany/?id=" + id);
  }

  public PublicCompanyRegistration(model: PublicCompanyRegistrationModel): Observable<ResponseCodeModel> {
    return this.Http.post<ResponseCodeModel>("/Api/Company/CompanyRegistration/", model);
  }

  public GetCompanyInfo(): Observable<PublicCompanyRegistrationModel> {
    return this.Http.get<PublicCompanyRegistrationModel>("/Api/Company/GetCompanyInfo/");
  }

  public GetCategoryItems(categoryId: number, LanguageId: number, type: number): Observable<CategoryModel[]> {
    return this.Http.get<CategoryModel[]>("/Api/Company/GetCategoryItems/?CategoryId=" + categoryId + "&LanguageId=" + LanguageId + "&type=" + type);
  }

  public RemoveCategoryItem(categoryId: number): Observable<CategoryModel[]> {
    return this.Http.delete<CategoryModel[]>("/Api/Company/RemoveCategoryItem/?categoryId=" + categoryId);
  }

  public AddCategoryItems(items: CategoryModel[]): Observable<ResponseCodeModel> {
    return this.Http.post<ResponseCodeModel>("/Api/Company/AddCategoryItems/", items);
  }

  public UpdateCategoryItems(items: CategoryModel[]): Observable<ResponseCodeModel> {
    return this.Http.put<ResponseCodeModel>("/Api/Company/UpdateCategoryItems/", items);
  }

  public GetCategoryItemDetail(ItemId: number,LangId): Observable<PublicCategoryItem> {
    return this.Http.get<PublicCategoryItem>("/Api/Company/GetCategoryItemDetail/?ItemId=" + ItemId + "&LangId=" + LangId);
  }

  public UpdateCategoryItemDetail(detail: PublicCategoryItem): Observable<ResponseCodeModel> {
    return this.Http.put<ResponseCodeModel>("/Api/Company/UpdateCategoryItemDetail/", detail);
  }

  public RemoveCategoryListItem(itemId: number): Observable<ResponseCodeModel> {
    return this.Http.delete<ResponseCodeModel>("/Api/Company/RemoveCategoryListItem/?itemId="+itemId);
  }

  public GetAllergens(): Observable<Allergen[]> {
    return this.Http.get<Allergen[]>("/Api/Company/GetAllergenList/");
  }

  public PublicGetCategoryItems(category:PublicCategoryModel): Observable<CategoryModel[]> {
    return this.Http.post<CategoryModel[]>("/Api/Company/PublicGetCategoryItems", category);
  }

  public PublicGetDetail(itemId: number,langId: number): Observable<PublicCategoryItem> {
    return this.Http.get<PublicCategoryItem>("/Api/Company/PublicGetDetail/?ItemId=" + itemId + "&langId=" + langId);
  }

  public GetCompanyProfil(): Observable<CompanyProfilModel>  {
    return this.Http.get<CompanyProfilModel>("/Api/Company/GetCompanyProfil/");
  }

  public UpdateCompanyProfil(newData: CompanyProfilModel): Observable<ResponseCodeModel> {
    return this.Http.put<ResponseCodeModel>("/Api/Company/UpdateCompanyProfil/", newData);
  }

  public UpdateInitialText(newData: UpdateInitialText): Observable<ResponseCodeModel> {
    return this.Http.put<ResponseCodeModel>("/Api/Company/UpdateInitialText/", newData);
  }

  public PublicGetCompany(): Observable<AddCompanyModel> {
    return this.Http.get<AddCompanyModel>("/Api/Company/PublicGetCompany/");
  }

  public PublicUpdateCompany(data: AddCompanyModel): Observable<ResponseCodeModel> {
    return this.Http.put<ResponseCodeModel>("/Api/Company/PublicUpdateCompany/", data);
  }


  public UpdateCompanyPassword(data: PublicUpdatePassword): Observable<ResponseCodeModel> {
    return this.Http.put<ResponseCodeModel>("/Api/Company/UpdateCompanyPassword/", data);
  }

  public GetProducts(langId: string): Observable<StripeProduct[]> {
    return this.Http.get<StripeProduct[]>("/Api/Company/GetProducts/?LangId=" + langId)
  }

  public RemoveSubscription(): Observable<ResponseCodeModel> {
    return this.Http.delete<ResponseCodeModel>("/Api/Company/RemoveSubscription/");
  }

  public CreateSubscription(ProductName: string): Observable<ResponseCodeModel> {
    return this.Http.get<ResponseCodeModel>("/Api/Company/CreateSubscription/?ProductName=" + ProductName);
  }

  public GetAllInvoices(): Observable<any> {
    return this.Http.get<any>("/Api/Company/GetAllInvoices/");
  }

  public GetPublicCompany(CompanyShortId: string, LanguageId: number): Observable<PublicCompanyModel> {
    return this.Http.get<PublicCompanyModel>("/Api/Company/GetPublicCompany/?CompanyShortId=" + CompanyShortId + "&LanguageId=" + LanguageId);
  }

  public CreateNewCreditCard(companyNewCreditCard: CompanyNewCreditCard) {
    return this.Http.put<ResponseCodeModel>("/Api/Company/CreateNewCreditCard/", companyNewCreditCard);
  }

  public GetPaymentIntents(): Observable<any> {
    return this.Http.get<any>("/Api/Company/GetPaymentIntents/");
  }

  public GetVersion(): Observable<SystemVersion> {
    return this.Http.get<SystemVersion>("/Api/Company/GetVersion/");
  }

  public GetWhatsappText(langId:number): Observable<WhatsappItem> {
    return this.Http.get<WhatsappItem>("/Api/Company/GetWhatsappText/?LangId=" + langId);
  }

  public GetAccessory(): Observable<CompanyAccessoryModel> {
    return this.Http.get<CompanyAccessoryModel>("/Api/Company/GetAccessory/");
  }

  public SetAccessory(newItem: CompanyAccessoryModel): Observable<any> {
    return this.Http.post<any>("/Api/Company/SetAccessory/", newItem);
  }
}
