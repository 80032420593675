
<TopMenu [LanguageId]="pageLanguageId" Text="" Active="menu"></TopMenu>


<div class="content_full" >
  <div style="width: 230px;    margin: 0px auto;    padding: 2rem;padding-bottom: 1rem;">
    <LangSelectBox [SelectedValue]="selectedLangId" [DataSource]="LangSelectBoxDataSource" [SelectedChange]="ChangeLangEvent"> </LangSelectBox>

  </div>
  <div>
    <div style=" margin: 0px auto;text-align: center;padding-bottom: 2rem;">
      <a class="sign-in-href" href="https://bit.ly/stocko2e" target="_blank">{{ 'You can download images from our library'| translate }}</a>
    </div>
  </div>
  <a  (click)="BackButton()" [routerLink]="[]" class="reqistration_button_prev_a mobile-hide" style="margin-top:1.5rem"><div class="reqistration_button_prev"></div></a>

  <div class="categories-div">

    <CategoryCarusal [DataSource]="carusalItemSource" style="display:flex;margin:0px auto;" id="category_items_carusal" [SelectedIndex]="carusalIndex" [OnSelectedChanged]="CarusalOnSelectedChanged"></CategoryCarusal>
    <div style=" display: table; margin: 1rem auto; text-transform: uppercase; letter-spacing: 1px;">
      <input type="checkbox" [(ngModel)]="SpecialOfferCheck"/> {{ 'Show only special offers'| translate }}
    </div>
  </div>

  <div class="content_680">
    <CategoryItems [LanguageId]="selectedLangId" [SpecialOffer]="SpecialOfferCheck" [AspectRatio]="AspectRatio" [Long]="Long" [ImageHeight]="ImageHeight" [ImageWidth]="ImageWidth" [RemoveItemEvent]="RemoveCategoryItemEvent" [CategoryItems]="categoryItemSource" [OpenSubcategoryEvent]="OpenSubcategoryEvent"></CategoryItems>
    <div  style="margin:2rem auto;display: table;">
      <button class="button_black" (click)="SaveCategory()">{{ 'save'| translate }}</button>
    </div>
    <div *ngIf="selectedLangId != defaultLanguageId" style="margin:2rem auto;display: table;">
      <button class="button_black" (click)="GetTranslationCategory()">{{ 'translate'| translate }}</button>
    </div>
  </div>
  <div class="legenda">
    <div class="legenda_item">
      <img class="legenda_img" style=" margin-top: 0.25rem;" src="/assets/images/drag-icon.svg" />
      <div> {{ 'reorder sequrnce'| translate }} </div>
    </div>
    <div class="legenda_item_100">
      <img class="legenda_img" src="/assets/images/edit-icon.svg" />
      <div> {{ 'edit'| translate }} </div>
    </div>
    <div class="legenda_item">
      <img class="legenda_img" style=" margin-top: 0.25rem;" src="/assets/images/visible-icon.svg" />
      <div> {{ 'enable/disable'| translate }} </div>
    </div>
    <div class="legenda_item_100">
      <img class="legenda_img" src="/assets/images/remove.svg" />
      <div> {{ 'remove'| translate }} </div>
    </div>
    <div class="legenda_item">
      <img class="legenda_img" src="/assets/images/fire.svg" />
      <div> {{ 'special offer'| translate }} </div>
    </div>
  </div>
  <a (click)="BackButton()" [routerLink]="[]" class="reqistration_button_next_a desktop-hide "><div class="menu_button_prev"></div></a>
</div>


  <!--<a *ngIf="PageState == 0 && ParentCategoryModelId > 0" [routerLink]="['/' + this.pageLanguageId,'company','menu','categories']" class="reqistration_button_next_a"><div class="menu_button_next"></div></a>-->
  <div class="footer-black"></div>

  <Toast Message="Item updated"></Toast>
