/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../SignOut/signout.component.ngfactory";
import * as i2 from "../SignOut/signout.component";
import * as i3 from "./topbar.component";
var styles_TopBarComponent = [];
var RenderType_TopBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TopBarComponent, data: {} });
export { RenderType_TopBarComponent as RenderType_TopBarComponent };
export function View_TopBarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "top-bar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "SignOut", [], null, null, null, i1.View_SignOutComponent_0, i1.RenderType_SignOutComponent)), i0.ɵdid(3, 49152, null, 0, i2.SignOutComponent, [], null, null)], null, null); }
export function View_TopBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "TopBar", [], null, null, null, View_TopBarComponent_0, RenderType_TopBarComponent)), i0.ɵdid(1, 49152, null, 0, i3.TopBarComponent, [], null, null)], null, null); }
var TopBarComponentNgFactory = i0.ɵccf("TopBar", i3.TopBarComponent, View_TopBarComponent_Host_0, {}, {}, []);
export { TopBarComponentNgFactory as TopBarComponentNgFactory };
