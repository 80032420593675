
<div class="rating-public-header">
  <div  class="content-public-back" (click)="BackButton()">
  </div>
  <div class="order-public-header-icon">
    <div class="ration-green-button" style=" width: auto;">
      <img src="/assets/images/white-star.svg" />
      <img src="/assets/images/white-star.svg" />
      <img src="/assets/images/white-star.svg" />
      <img src="/assets/images/white-star.svg" />
      <img src="/assets/images/mid-white-star.svg" />
    </div>
    <span class="order-public-header-icon-text" style=" text-align: center; display: block;">{{ 'review' |translate }}</span>
  </div>

</div>
<div class="order-help-message-box">
  {{ 'rating-help-message'|translate}}
</div>
<div>
  <div class="ration-icon-button" *ngIf="showPripadvisor" (click)="OpenUrl(1)">
    <img src="/assets/images/tripadvisor.svg" />
  </div>
  <div class="ration-icon-button"  *ngIf="showGoogle" (click)="OpenUrl(2)">
    <img src="/assets/images/google.svg" />
  </div>
  <div class="ration-icon-button"  *ngIf="showYelp" (click)="OpenUrl(3)">
    <img src="/assets/images/yelp.svg" />
  </div>
  <div class="ration-icon-button"  *ngIf="showThefork" (click)="OpenUrl(4)">
    <img src="/assets/images/thefork.svg" />
  </div>
</div>
