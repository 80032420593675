import { Component, Input, AfterViewInit } from "@angular/core";
import { NgxImageCompressService } from "ngx-image-compress";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

declare function ListItemsInitDragAndDrop(): any;
declare function GetListItemsDragAndDropOrder(): any;

export class ListItem {
  public title: string;
  public visible: boolean;
  public order: number;
  public price: number;
  public id: number;
}

@Component({
  selector: "ListItems",
  templateUrl: "listitems.component.html"
})
export class ListItemsComponent implements AfterViewInit {


  @Input()
  ListItems: ListItem[] = [];

  @Input()
  OpenSubcategoryEvent: Subject<ListItem>;

  @Input()
  RemoveItemEvent: Subject<ListItem>;

  public max_order = 0;

  constructor(public translateService: TranslateService) {
  }
  ngAfterViewInit(): void {
    ListItemsInitDragAndDrop();
  }

  public GetRealOrder() {
    return GetListItemsDragAndDropOrder();
  }

  public AddItem() {

    for (let i = 0; i < this.ListItems.length; i++) {
      if (this.ListItems[i].order > this.max_order)
        this.max_order = this.ListItems[i].order;
    }
    this.max_order++;
    const new_item = new ListItem();
    new_item.price = 0;
    new_item.title = "";
    new_item.id = -1;
    new_item.visible = true;
    new_item.order = this.max_order;
    this.ListItems.push(new_item);
  }

  public OpenSubcategory(item: ListItem) {
    if (typeof this.OpenSubcategoryEvent !== "undefined") {
      this.OpenSubcategoryEvent.next(item);
    }
  }


  public ChangeVisible(item_category: ListItem) {
    item_category.visible = !item_category.visible;
  }

  public RemoveItem(item_category: ListItem, _index: number) {
    if (item_category.id === -1) {
      this.translateService.get("Do you realy want to reamove this item ?").subscribe(trans => {
        if (confirm(trans)) {

          this.ListItems.splice(_index, 1);
        }
      });
    }
    else {
      this.translateService.get("Do you realy want to reamove this item ?").subscribe(trans => {
        if (confirm(trans)) {
          if (typeof this.RemoveItemEvent !== "undefined") {
            this.RemoveItemEvent.next(item_category);
          }
        }
      });
    }

  }

  public UpStep(index: number) {

    const swap = this.ListItems[index];
    this.ListItems[index] = this.ListItems[index - 1];
    this.ListItems[index - 1] = swap;
  }

  public DownStep(index: number) {
    const swap = this.ListItems[index];
    this.ListItems[index] = this.ListItems[index + 1];
    this.ListItems[index + 1] = swap;
  }
}
