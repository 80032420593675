
<div class="content-public-header" *ngIf="pageState != 5 && pageState != 6 && pageState != 7">
  <div *ngIf="pageState != 1" class="content-public-back" (click)="BackButton()">
  </div>
  <div *ngIf="pageState != 1 && WhatsappActive == true" class="content-public-eshop-flag" (click)="OpenOrderList()">
    <img src="/assets/images/shop.svg"/>
  </div>
</div>

<div class="content-public" *ngIf="pageState == 1">
  <div>
    <div class="content-public-welcome-text" style="padding-top:2rem;">{{ 'Welcome to' |translate }}</div>
    <img class="content-public-welcome-img" [src]="LogoPath" />
    <div class="content-public-welcome-text">{{ CurrentInitText }}</div>
  </div>
  <div class="content-public-lang-box-wrap" *ngIf="AciveCompany == true" >
    <div class="content-public-lang-box" #profile_lang_id>

      <div *ngFor="let lang_item of ComponentLangItems;let _index = index;" class="content-public-lang-box-item" id="langItem{{ lang_item.id }}" [ngStyle]="langItemCss[_index]">
        <img src="/assets/images/langs/{{ lang_item.shortcut }}.svg" (click)="SelectLang(lang_item.id,_index)" />
        <span class="content-public-lang-box-text"> {{ lang_item.text |translate }} </span>
      </div>


      <!--<div class="content-public-lang-box-item" id="langItem1" [ngStyle]="langItemCss[0]">
        <img src="/assets/images/langs/eu.svg" (click)="SelectLang(2,0)" />
        <span class="content-public-lang-box-text"> {{ 'european'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem2" [ngStyle]="langItemCss[1]">
        <img src="/assets/images/langs/italy.svg" (click)="SelectLang(1,1)" />
        <span class="content-public-lang-box-text"> {{ 'italian'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem3" [ngStyle]="langItemCss[2]">
        <img src="/assets/images/langs/english.svg" (click)="SelectLang(0,2)" />
        <span class="content-public-lang-box-text"> {{ 'english'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem4" [ngStyle]="langItemCss[3]">
        <img src="/assets/images/langs/us.svg" (click)="SelectLang(3,3)" />
        <span class="content-public-lang-box-text"> {{ 'united state'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[4]">
        <img src="/assets/images/langs/chf.svg" (click)="SelectLang(4,4)" />
        <span class="content-public-lang-box-text"> {{ 'switzerland'| translate }}</span>
      </div>

      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[5]">
        <img src="/assets/images/langs/ge.svg" (click)="SelectLang(5,5)" />
        <span class="content-public-lang-box-text"> {{ 'germany'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[6]">
        <img src="/assets/images/langs/sk.svg" (click)="SelectLang(6,6)" />
        <span class="content-public-lang-box-text"> {{ 'slovakia'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[7]">
        <img src="/assets/images/langs/cs.svg" (click)="SelectLang(7,7)" />
        <span class="content-public-lang-box-text"> {{ 'czech republic'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[8]">
        <img src="/assets/images/langs/pl.svg" (click)="SelectLang(8,8)" />
        <span class="content-public-lang-box-text"> {{ 'poland'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[9]">
        <img src="/assets/images/langs/cn.svg" (click)="SelectLang(9,9)" />
        <span class="content-public-lang-box-text"> {{ 'china'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[10]">
        <img src="/assets/images/langs/ru.svg" (click)="SelectLang(10,10)" />
        <span class="content-public-lang-box-text"> {{ 'russia'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[11]">
        <img src="/assets/images/langs/es.svg" (click)="SelectLang(11,11)" />
        <span class="content-public-lang-box-text"> {{ 'spain'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[12]">
        <img src="/assets/images/langs/nl.svg" (click)="SelectLang(12,12)" />
        <span class="content-public-lang-box-text"> {{ 'netherlands '| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[13]">
        <img src="/assets/images/langs/fr.svg" (click)="SelectLang(13,13)" />
        <span class="content-public-lang-box-text"> {{ 'france'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[14]">
        <img src="/assets/images/langs/dk.svg" (click)="SelectLang(14,14)" />
        <span class="content-public-lang-box-text"> {{ 'denmark'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[15]">
        <img src="/assets/images/langs/se.svg" (click)="SelectLang(15,15)" />
        <span class="content-public-lang-box-text"> {{ 'sweden'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[16]">
        <img src="/assets/images/langs/hr.svg" (click)="SelectLang(16,16)" />
        <span class="content-public-lang-box-text"> {{ 'croatia'| translate }}</span>
      </div>

      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[17]">
        <img src="/assets/images/langs/hu.svg" (click)="SelectLang(17,17)" />
        <span class="content-public-lang-box-text"> {{ 'hungary'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[18]">
        <img src="/assets/images/langs/pt.svg" (click)="SelectLang(18,18)" />
        <span class="content-public-lang-box-text"> {{ 'portugal'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[19]">
        <img src="/assets/images/langs/no.svg" (click)="SelectLang(19,19)" />
        <span class="content-public-lang-box-text"> {{ 'norway'| translate }}</span>
      </div>
      <div class="content-public-lang-box-item" id="langItem5" [ngStyle]="langItemCss[20]">
        <img src="/assets/images/langs/ar.svg" (click)="SelectLang(20,20)" />
        <span class="content-public-lang-box-text"> {{ 'arabic'| translate }}</span>
      </div>-->

    </div>
  </div>
</div>

<div class="content-public-black" *ngIf="pageState == 2">
  <div class="content-public-base-bar">
    <div class="content-public-base-bar-item" style="padding-left: 3rem;">
      <img src="/assets/images/drink.svg" style="height: 3rem"  (click)="SelectType('drink')" />
      <div class="content-public-base-bar-item-text">{{ 'drink' | translate }}</div>
    </div>
    <div class="content-public-base-bar-item" style="padding-right: 3rem;">
      <img src="/assets/images/food.svg" style="height: 3rem;" (click)="SelectType('food')" />
      <div class="content-public-base-bar-item-text">{{ 'food' | translate }}</div>
    </div>
  </div>
  <div class="content-public-black-content" style=" color: #E6E6E6; padding: 3rem; text-align: justify; letter-spacing: 1px;">
    {{ CurrentRestaurantText }}
    <!--<ol>
    <li>
      {{ 'CHOOSE WHAT DO YOU WANT TO ORDER.' |translate }}
    </li>
    <li>
      {{ 'ADD YOUR CHOICE AT THE LIST.' |translate }}
    </li>
    <li>
      {{ 'menu_text_3' |translate }}
    </li>
    <li>
      {{ 'menu_text_4' |translate }}
    </li>
    <li>
      {{ 'menu_text_5' |translate }}
    </li>
  </ol>-->
  </div>
  <div class="rating" *ngIf="ratingButton" (click)="ratingClick()">
    <div class="ration-green-button">
      <img src="/assets/images/white-star.svg" />
      <img src="/assets/images/white-star.svg" />
      <img src="/assets/images/white-star.svg" />
      <img src="/assets/images/white-star.svg" />
      <img src="/assets/images/mid-white-star.svg" />
      <span>{{ 'review' |translate }}</span>
    </div>
  </div>
  <div class="special-offers" *ngIf=" SpecialOffers.length > 0">
    <div style="color: #E6E6E6; padding: 1.5rem; text-align: center; letter-spacing: 3px; text-transform: uppercase; font-size: 1.2rem;">{{ 'Special offers' |translate }}</div>
    <div class="content-public-category-content-long" >
      <div *ngFor="let item of SpecialOffers; let _index = index" class="content-public-category-item-long" (click)="SpecialOfferClick(_index)">
        <img [src]="item.imageBase64" />
        <img src="/assets/images/shadow.svg" class="content-public-category-item-shadow" />
        <div class="content-public-category-item-text-long">{{ item.title }}</div>
        <div *ngIf="Currency == 'gbp' && item.showPrice == true && item.templateType == 0" class="content-public-category-item-text-long-price">{{ item.price }} &pound;</div>
        <div *ngIf="Currency == 'euro' && item.showPrice == true && item.templateType == 0" class="content-public-category-item-text-long-price">{{ item.price }} &euro;</div>
        <div *ngIf="Currency == 'dollar' && item.showPrice == true && item.templateType == 0" class="content-public-category-item-text-long-price">{{ item.price }} $</div>
        <div *ngIf="Currency == 'chf' && item.showPrice == true && item.templateType == 0" class="content-public-category-item-text-long-price">{{ item.price }} CHF</div>
        <div *ngIf="Currency == 'rub' && item.showPrice == true && item.templateType == 0" class="content-public-category-item-text-long-price">{{ item.price }} RUB</div>
      </div>
    </div>
  </div>
</div>

<div class="content-public-black" *ngIf="pageState == 3 || pageState == 4">
  <div class="content-public-base-bar">
    <div [ngClass]="drinkCss">
      <img src="/assets/images/drink.png" style="height: 3rem" (click)="SelectType('drink')" />
      <div class="content-public-base-bar-item-text">{{ 'drink' | translate }}</div>
    </div>
    <div [ngClass]="foodCss">
      <img src="/assets/images/food.png" style="height: 3rem;" (click)="SelectType('food')" />
      <div class="content-public-base-bar-item-text">{{ 'food' | translate }}</div>
    </div>
  </div>

  <div class="content-public-category-content" *ngIf="pageState == 3">
    <div *ngFor="let item of categoryItems; let _index = index" class="content-public-category-item" (click)="CategoryClick(_index)">
      <img [src]="item.imageBase64" />
      <div class="content-public-category-item-text">{{ item.title }}</div>
    </div>
  </div>
  <div class="content-public-category-content-long" *ngIf="pageState == 4">
    <div *ngFor="let item of categoryItems; let _index = index" class="content-public-category-item-long" (click)="CategoryClick(_index)">
      <img [src]="item.imageBase64" />
      <img src="/assets/images/shadow.svg" class="content-public-category-item-shadow" />
      <div class="content-public-category-item-text-long">{{ item.title }}</div>
      <div *ngIf="Currency == 'gbp' && item.showPrice == true && item.templateType == 0" class="content-public-category-item-text-long-price">{{ item.price }} &pound;</div>
      <div *ngIf="Currency == 'euro' && item.showPrice == true && item.templateType == 0" class="content-public-category-item-text-long-price">{{ item.price }} &euro;</div>
      <div *ngIf="Currency == 'dollar' && item.showPrice == true && item.templateType == 0" class="content-public-category-item-text-long-price">{{ item.price }} $</div>
      <div *ngIf="Currency == 'chf' && item.showPrice == true && item.templateType == 0" class="content-public-category-item-text-long-price">{{ item.price }} CHF</div>
      <div *ngIf="Currency == 'rub' && item.showPrice == true && item.templateType == 0" class="content-public-category-item-text-long-price">{{ item.price }} RUB</div>
    </div>
  </div>

</div>

<div *ngIf="pageState == 6" class="content-public-category-item-detail-wrap content-public-category-item-detail-wrap-box">

  <img [src]="PublicCategoryItem.detail.imageBase64" class="content-public-category-item-detail" />
  <img src="/assets/images/shadow.svg" class="content-public-category-item-detail-shadow" />
  <div class="rating-detail" (click)="openRanking()" *ngIf="ratingButton">
    <img src="/assets/images/green-star.svg" />
    <img src="/assets/images/green-star.svg" />
    <img src="/assets/images/green-star.svg" />
    <img src="/assets/images/green-star.svg" />
    <img src="/assets/images/mid-green-star.svg" />
  </div>
  <div class="content-public-category-item-detail-content">
    <div class="content-public-category-item-detail-box">
      <div class="content-public-category-item-detail-contols">
        <div class="content-public-category-item-detail-prev{{ prevIdCss }}" (click)="PrevButton()"></div>
        <div class="content-public-category-item-detail-close" (click)="CloseDetail()"></div>
        <div class="content-public-category-item-detail-next{{ nextIdCss }}" (click)="NextButton()"></div>
      </div>
      <div class="content-public-category-item-detail-list">
        <!--<div class="content-public-category-item-detail-list-title">
          {{ PublicCategoryItem.detail.title }}
        </div>-->
        <ul>
          <li *ngFor="let item of PublicCategoryItem.listItems;let _index = index" [ngStyle]="listWhatsappCss">
            <div class="content-public-category-item-detail-list-title2">{{ item.title }}</div>
            <span *ngIf="Currency == 'gbp'" class="content-public-category-item-detail-list-price">{{ item.price}} &pound;</span>
            <span *ngIf="Currency == 'euro'" class="content-public-category-item-detail-list-price">{{ item.price}} &euro;</span>
            <span *ngIf="Currency == 'dollar'" class="content-public-category-item-detail-list-price">{{ item.price}} $</span>
            <span *ngIf="Currency == 'chf'" class="content-public-category-item-detail-list-price">{{ item.price}} CHF</span>
            <span *ngIf="Currency == 'rub'" class="content-public-category-item-detail-list-price">{{ item.price}} RUB</span>

            <div class="detail-list-item-box " id="orderListItem{{_index}}" *ngIf="WhatsappActive == true">
              <img src="/assets/images/delivery-car.svg" class="order-list-item-box-car" (click)="OnClickListOrder(_index)" />
              <div class="order-list-item-box-counter" id="orderCounterListItem{{_index}}">
                <div class="order-list-item-box-counter-box" style="width: calc(100% - 8rem);">
                  <div class="order-list-item-box-counter-box-left" (mousedown)="removeBoxListDown(_index)" (mouseup)="removeBoxListUp(_index)">-</div>
                  <div class="order-list-item-box-counter-box-mid">{{ listOrderItems[_index].Count }}</div>
                  <div class="order-list-item-box-counter-box-right" (mousedown)="addBoxListDown(_index)" (mouseup)="addBoxListUp(_index)">+</div>
                </div>
                <img src="/assets/images/add-to-card.svg" class="order-list-item-box-add" (click)="OrderSubmit()" />
              </div>
            </div>




          </li>
        </ul>
      </div>
      <div id="notificationBoxAddList" class="notification-box" style="opacity: 0;visibility: hidden;">
        {{ 'The product has been added to the cart'|translate}}
      </div>

      <div id="notificationBoxRemoveList" class="notification-box" style="opacity: 0; visibility: hidden;">
        {{ 'The product has been removed to the cart'|translate}}
      </div>
    </div>
  </div>
</div>

<div *ngIf="pageState == 7" class="content-public-category-item-detail-wrap content-public-category-item-detail-wrap-box" >
    <img [src]="PublicCategoryItem.detail.imageBase64" class="content-public-category-item-detail" />
    <img src="/assets/images/shadow.svg" class="content-public-category-item-detail-shadow" />
    <div class="rating-detail" (click)="openRanking()" *ngIf="ratingButton">
      <img src="/assets/images/white-star.svg" />
      <img src="/assets/images/white-star.svg" />
      <img src="/assets/images/white-star.svg" />
      <img src="/assets/images/white-star.svg" />
      <img src="/assets/images/mid-white-star.svg" />
    </div>
    <div class="content-public-category-item-detail-content">
      <div class="content-public-category-item-detail-box">
        <div class="content-public-category-item-detail-contols">
          <div class="content-public-category-item-detail-prev{{ prevIdCss }}" (click)="PrevButton()"></div>
          <div class="content-public-category-item-detail-close" (click)="CloseDetail()"></div>
          <div class="content-public-category-item-detail-next{{ nextIdCss }}" (click)="NextButton()"></div>
        </div>
        <div class="content-public-category-item-detail-detail">
          <div class="content-public-category-item-detail-title">
            {{ PublicCategoryItem.detail.title }}
          </div>
          <div class="content-public-category-item-detail-desc">
            {{ PublicCategoryItem.detail.desciption }}
          </div>

          <div *ngIf="FrozenProduct" class="content-public-category-item-detail-desc" style="margin-top:1rem">
            <!--{{ 'Frozen product'| translate}}-->
            <img src="/assets/allergens/frozen_product.svg" style=" width: 2rem;" />
          </div>

        </div>
        <div style="padding: 2rem;padding-bottom: 0;">
          <div class="content-public-category-item-detail-allergens">
            <div *ngIf="allergenValues.length > 0">
              <div *ngIf="allergenItemValues.length > 0" class="content-public-category-item-detail-allergens-title">{{ "Allergens" | translate}}:</div>
              <div *ngIf="allergenItemValues.length == 0" class="content-public-category-item-detail-allergens-title">&nbsp;</div>
              <div class="content-public-category-item-detail-allergens-imgs" *ngIf="allergenItemValues.length > 0">
                <div *ngFor="let allergen of allergenItemValues;let _index_allergen = index" style="position:relative;">
                  <img class="content-public-category-item-detail-allergens-img" src="/assets/allergens/{{ allergen.img }}" (click)="ShowAllergen(_index_allergen)" />
                  <span class="allergen-tooltip" *ngIf="allergenShowValues[_index_allergen] == true" id="tooltip_{{_index_allergen}}">{{ allergen.name | translate }}</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="Currency == 'gbp'" class="content-public-category-item-detail-price">{{ PublicCategoryItem.detail.price }} &pound;</div>
          <div *ngIf="Currency == 'euro'" class="content-public-category-item-detail-price">{{ PublicCategoryItem.detail.price }} &euro;</div>
          <div *ngIf="Currency == 'dollar'" class="content-public-category-item-detail-price">{{ PublicCategoryItem.detail.price }} $</div>
          <div *ngIf="Currency == 'chf'" class="content-public-category-item-detail-price">{{ PublicCategoryItem.detail.price }} CHF</div>
          <div *ngIf="Currency == 'rub'" class="content-public-category-item-detail-price">{{ PublicCategoryItem.detail.price }} RUB</div>
        </div>
      </div>

      <div id="notificationBoxAdd" class="notification-box" style="opacity: 0; visibility: hidden;" >
        {{ 'The product has been added to the cart'|translate}}
      </div>

      <div id="notificationBoxRemove" class="notification-box" style="opacity: 0;visibility: hidden;">
        {{ 'The product has been removed to the cart'|translate}}
      </div>

      <div class="detail-order-list-item-box" id="orderItem" *ngIf="WhatsappActive == true">
        <img src="/assets/images/delivery-car.svg" class="order-list-item-box-car"  (click)="OnClickOrder()" />
        <div class="order-list-item-box-counter" id="orderCounterItem">
          <div class="order-list-item-box-counter-box" style="width: calc(100% - 8rem);">
            <div class="order-list-item-box-counter-box-left" (mousedown)="removeBoxDown()" (mouseup)="removeBoxUp()">-</div>
            <div class="order-list-item-box-counter-box-mid">{{ orderItem.Count }}</div>
            <div class="order-list-item-box-counter-box-right" (mousedown)="addBoxDown()" (mouseup)="addBoxUp()">+</div>
          </div>
          <img src="/assets/images/add-to-card.svg" class="order-list-item-box-add" (click)="OrderSubmit()" />
        </div>
      </div>

    </div>


</div>

  <div class="content-public-footer" style="position: fixed;" *ngIf="pageState == 1">
    <div class="footer-black-logo">
      <div class="footer-black-logo-div">
        <img src="/assets/images/logo.svg" class="footer-black-logo-img" onclick="window.open('https://bit.ly/ord2eatcom', '_blank');" />
      </div>
    </div>
  </div>
