import { Component } from '@angular/core';


@Component({
  selector: 'SignOut',
  templateUrl: './signout.component.html',

})
export class SignOutComponent {


  public OnClick() {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('jwt_sys_role');
    location.reload();
  }
}
