import { Component, ViewChild, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { CompanyService, AddCompanyModel, PublicUpdatePassword, CompanyNewCreditCard } from "../../../services/company/CompanyService";
import { ToastComponent } from "../../../components/Toast/toast.component";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  templateUrl: 'usercompany.page.html'
})
export class UserCompanyPage implements OnInit {

  public step2CheckBoxEvent = new Subject<boolean>();

  public step2registration_style = { maxHeight: "0px" };

  public months: number[] = [];
  public years: number[] = [];

  public companyDetail = new AddCompanyModel();
  @ViewChild(ToastComponent, { static: false }) toast: ToastComponent;

  public newPassword = new PublicUpdatePassword();
  public ConfirmPassword = "";
  public pageLanguageId = 'en';

  public CreditCardYear = "";
  public CreditCardMonth = "";
  public AconutHolder = "";
  public CreditCardNumber = "";
  public CreditCardCVV = "";

  public CreditCardCVVClass = "";
  public CreditCardNumberClass = "";
  public AccountHolderClass = "";

  constructor(public companyService: CompanyService, public translateService: TranslateService,
    public route: ActivatedRoute) {

    this.AccountHolderClass = "default_input";
    this.CreditCardNumberClass = "default_input";
    this.CreditCardCVVClass = "default_input";

    for (let i = 1; i < 13; i++)
      this.months.push(i);
    let _year = (new Date()).getFullYear() % 2000;
    this.CreditCardYear = "" + _year;
    this.CreditCardMonth = "" + ((new Date()).getMonth() + 1);
    for (let i = 0; i < 10; i++)
      this.years.push(_year + i);

    this.companyService.PublicGetCompany().subscribe(result => {
      this.companyDetail = result;
    })

    this.step2CheckBoxEvent.subscribe(data => {
      if (data) {
        this.step2registration_style = { maxHeight: "1000px" };
      }
      else {
        this.step2registration_style = { maxHeight: "0px" };
      }
    });



  }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.pageLanguageId = routeParams.languageId;
      this.translateService.use(this.pageLanguageId);

    });
  }

  public SaveDetail() {
    this.companyService.PublicUpdateCompany(this.companyDetail).subscribe(result => {
      if (result.code === 1) {
        this.toast.Message = "Item updated";
        this.toast.Show();
      }

      else {
        this.toast.Message = "Error";
        this.toast.Show();
      }
    });
  }

  public SaveCreditCard() {

    let new_item = new CompanyNewCreditCard()
    new_item.creditCardAccountHolder = this.AconutHolder;
    new_item.creditCardYear = parseInt(this.CreditCardYear);
    new_item.creditCardNumber = this.CreditCardNumber.replace(/ /g, '');
    new_item.creditCardCVV = parseInt(this.CreditCardCVV);
    new_item.creditCardMonth = parseInt(this.CreditCardMonth);

    this.companyService.CreateNewCreditCard(new_item).subscribe(result => {
      if (result.code === 1) {
        this.toast.Message = "Item updated";
        this.toast.Show();
        this.AconutHolder = "";
        this.CreditCardYear = "";
        this.CreditCardNumber = "";
        this.CreditCardCVV = "";
        this.CreditCardMonth = "";
      }

      else {
        this.toast.Message = "Error";
        this.toast.Show();
      }
    });
  }

  public SaveNewPassword() {

    if (this.newPassword.newPassword !== this.ConfirmPassword) {
      this.translateService.get("Passwords must be same").subscribe(trans => {
        alert(trans);
      });
      
    }
    else {
      this.companyService.UpdateCompanyPassword(this.newPassword).subscribe(result => {
        if (result.code === 1) {
          this.toast.Message = "Item updated";
          this.toast.Show();
        }

        else {
          this.toast.Message = "Error";
          this.toast.Show();
        }
      });
    }
  }
  public CreditCardNumberFormat() {
    let tmp = "";;
    let only_text = this.CreditCardNumber.replace(/ /g, '');
    for (let i = 0; i < only_text.length; i++) {
      if (only_text[i] >= '0' && only_text[i] <= '9') {
        if (i > 0 && i % 4 === 0) {
          tmp += " ";
        }
        tmp += only_text[i];
      }
    }
    this.CreditCardNumber = tmp.substring(0, 19);
  }

}
