<div class="header-black-logo">
  <div class="header-black-logo-div">
    <img src="/assets/images/logo.svg" class="header-black-logo-img" onclick="window.open('https://bit.ly/ord2eatcom', '_blank');" />
  </div>
</div>
<div class="content_480" *ngIf="PageState == 1">

  <h1 class="reqistration_title">{{ 'Password recover'| translate }}</h1>
  <StaticBox [Text]="StaticBoxText" CssClass="sing-in-login"></StaticBox>
  <div style="margin:3.5rem auto 0rem;display: table;" *ngIf="ShowButton1">
    <button class="button_black" (click)="ReturnToSignIn()">{{ 'Back to sign in'| translate }}</button>
  </div>
  <div class="sign-in-form-box" [ngClass]="sing_box">
    <div class="default_input_box">
      <input type="text" [(ngModel)]="UserEmail" [ngClass]="UserNameClass" placeholder="{{ 'Your email'| translate }}*" autcomplete="false" />
      <!--<img *ngIf="EmailError" class="error_validation" src="/assets/images/error.svg" />-->
    </div>

    <div class="registration_white_text">
      * {{ 'necessary data'| translate }}
    </div>
    <div style="margin:3.5rem auto 0rem;display: table;">
      <button class="button_black" (click)="Recover()">{{ 'Recover'| translate }}</button>
    </div>

  </div>
</div>

<div class="content_480" *ngIf="PageState == 2">

  <h1 class="reqistration_title">{{ 'Password recover'| translate }}</h1>
  <StaticBox [Text]="StaticBoxText2" CssClass="sing-in-login"></StaticBox>
  <div class="sign-in-form-box" [ngClass]="sing_box">
    <div class="default_input_box">
      <input type="password" [(ngModel)]="NewPassword" [ngClass]="NewPasswordClass" placeholder="{{ 'Your new password'| translate }}*" autcomplete="false" />
      <!--<img *ngIf="EmailError" class="error_validation" src="/assets/images/error.svg" />-->
    </div>
    <div class="default_input_box">
      <input type="password" [(ngModel)]="ConfirmNewPassword" [ngClass]="ConfirmPasswordClass" placeholder="{{ 'Confirm password'| translate }}*" autcomplete="false" />
      <!--<img *ngIf="EmailError" class="error_validation" src="/assets/images/error.svg" />-->
    </div>

    <div class="registration_white_text">
      * {{ 'necessary data'| translate }}
    </div>
    <div style="margin:3.5rem auto 0rem;display: table;">
      <button class="button_black" (click)="RecoverStep2()">{{ 'Recover'| translate }}</button>
    </div>
  </div>
  <div style="margin:3.5rem auto 0rem;display: table;" *ngIf="ShowButton">
    <button class="button_black" (click)="ReturnToSignIn()">{{ 'Back to sign in'| translate }}</button>
  </div>
</div>

<div class="footer-black"></div>
