

export class ResponseCodeModel {
  public code: number;
}

export function GetSupportedLanguages() {
  return ['en', 'it', 'eu','us','ch','ru'];
}

export function GetSupportedCurrences() {
  return ['gbp', 'eur', 'eur', 'usd', 'chf', 'eur', 'eur', 'eur', 'eur', 'eur','rub'];
}





export function GetLangSelectBoxDaata(_langs: []) {

  let all_langs = [{
    id: 0,
    text: "english",
    img: "en",
    shortcut: 'en',
    shortcutLang: 'en'
  },
  {
    id: 1,
    text: "italy",
    img: "it",
    shortcut: 'it',
    shortcutLang: 'it'
  },
  {
    id: 2,
    text: "european",
    img: "eu",
    shortcut: 'eu',
    shortcutLang: 'en'
  },
  {
    id: 3,
    text: "united state",
    img: "us",
    shortcut: 'us',
    shortcutLang: 'en'
  },
  {
    id: 4,
    text: "switzerland",
    img: "chf",
    shortcut: 'ch',
    shortcutLang: 'it'
    },

  {
    id: 5,
    text: "germany",
    img: "ge",
    shortcut: 'ge',
    shortcutLang: 'de'
  },
  {
    id: 6,
    text: "slovakia",
    img: "sk",
    shortcut: 'sk',
    shortcutLang: 'sk'
  },
  {
    id: 7,
    text: "czech republic",
    img: "cs",
    shortcut: 'cs',
    shortcutLang: 'cs'
  },
  {
    id: 8,
    text: "poland",
    img: "pl",
    shortcut: 'pl',
    shortcutLang: 'pl'
  },
  {
    id: 9,
    text: "china",
    img: "cn",
    shortcut: 'cn',
    shortcutLang: 'zh-TW'
  },
    {
      id: 10,
      text: "russia",
      img: "ru",
      shortcut: 'ru',
      shortcutLang: 'ru'
    },
  {
    id: 11,
    text: "spain",
    img: "es",
    shortcut: 'es',
    shortcutLang: 'es'
  },
  {
    id: 12,
    text: "NETHERLANDS",
    img: "nl",
    shortcut: 'nl',
    shortcutLang: 'nl'
  },
  {
    id: 13,
    text: "france",
    img: "fr",
    shortcut: 'fr',
    shortcutLang: 'fr'
  },
  {
    id: 14,
    text: "denmark",
    img: "dk",
    shortcut: 'dk',
    shortcutLang: 'da'
  },
  {
    id: 15,
    text: "sweden",
    img: "se",
    shortcut: 'se',
    shortcutLang: 'sv'
  },
  {
    id: 16,
    text: "croatia",
    img: "hr",
    shortcut: 'hr',
    shortcutLang: 'hr'
  },
  {
    id: 17,
    text: "hungary",
    img: "hu",
    shortcut: 'hu',
    shortcutLang: 'hu'
  },
  {
    id: 18,
    text: "portugal",
    img: "pt",
    shortcut: 'pt',
    shortcutLang: 'pt'
  },
  {
    id: 19,
    text: "norway",
    img: "no",
    shortcut: 'no',
    shortcutLang: 'no'
  },
  {
    id: 20,
    text: "arabic",
    img: "ar",
    shortcut: 'ar',
    shortcutLang: 'ar'
  }

  ];

  if (_langs.length === 0)
    return all_langs;
  let return_array = [];
  for (let i = 0; i < _langs.length; i++) {
    let _item = all_langs.find(function (item) { return item.id === _langs[i]; });
    if (typeof _item !== "undefined") {
      return_array.push(_item);
    }
  }
  return return_array;
}
