/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./dashboard.page";
import * as i3 from "../../../services/company/CompanyService";
var styles_DashboardAdminPage = [];
var RenderType_DashboardAdminPage = i0.ɵcrt({ encapsulation: 2, styles: styles_DashboardAdminPage, data: {} });
export { RenderType_DashboardAdminPage as RenderType_DashboardAdminPage };
export function View_DashboardAdminPage_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ": ", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Current version")); var currVal_1 = _co.currnetVersion; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_DashboardAdminPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DashboardAdminPage_0, RenderType_DashboardAdminPage)), i0.ɵdid(1, 49152, null, 0, i2.DashboardAdminPage, [i3.CompanyService], null, null)], null, null); }
var DashboardAdminPageNgFactory = i0.ɵccf("ng-component", i2.DashboardAdminPage, View_DashboardAdminPage_Host_0, {}, {}, []);
export { DashboardAdminPageNgFactory as DashboardAdminPageNgFactory };
