<div class="header-black"></div>
<div class="content_480">
  <img src="/assets/images/logo.svg" class="sign-in-logo" onclick="window.open('https://bit.ly/ord2eatcom', '_blank');" />
  <LangBox [OnChangeLangEvent]="OnChangeLang" [selectedLang]="selectedLang"></LangBox>
  <StaticBox [Text]="LoginMessage" [CssClass]="sing_in_login"></StaticBox>
  <div class="sign-in-form-box">
    <div class="default_input_box">
      <input type="text" [(ngModel)]="UserName" class="default_input" placeholder="{{'Login.UserName' | translate}}" />
    </div>
    <div class="default_input_box">
      <input type="password" [(ngModel)]="Password" class="default_input" placeholder="{{'Login.Password' | translate}}" />
    </div>
    <div class="default_input_box">
      <CheckBox DefaultValue="true" Text="remember me on this device" [OnChange]="OnChangeRemember"></CheckBox>
    </div>
    <div style="margin:0px auto;display: table;">
      <button class="button_light" (click)="SignInClick()">{{ 'enter' |translate }}</button>
    </div>
    <div style="    text-align: center;    margin-top: 0.75rem;    margin-bottom: 1.75rem;">
      <a class="sign-in-href" [routerLink]="['/'+this.pageLanguageId,'resetpassword']">{{ 'forgot password or user ?'| translate }}</a>
      </div>
    <div style="margin:0.5rem auto;display: table;">
      <button class="button_black" (click)="NewUser()">{{ 'new user? sign in'| translate }}</button>
    </div>
  </div>
</div>
<div class="footer-black"></div>
