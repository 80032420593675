import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../services/user/UserService";
import { ValidationModel, ValidationGroup } from "../../../components/ValidationGroup/ValidationGroup";
import { Router, ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { GetSupportedLanguages } from "../../../GlobalModels";


@Component({
  selector:'sign-in-page',
  templateUrl: 'signin.page.html'
})
export class SignInPage implements OnInit {

  public validationModels: ValidationModel[] = [];
  public validationGroup: ValidationGroup;

  public UserName = "";
  public Password = "";


  public LoginMessage = "LOGIN";
  public sing_in_login = "sing-in-login";

  public InvalidSignInCredentials = false;

  public OnChangeLang: Subject<number>;
  public selectedLang = 0;

  public pageLanguageId = 'en';

  public OnChangeRemember: Subject<boolean>;
  public RemeberVal = true;

  constructor(public userService: UserService, public router: Router, public translate: TranslateService,
    public route: ActivatedRoute) {
    this.OnChangeLang = new Subject<number>();
    this.OnChangeLang.subscribe(newVal => {

      const langs = GetSupportedLanguages();

      location.href = location.href.replace("/" + this.pageLanguageId + "/", "/" + langs[newVal] + "/");
      this.translate.use(langs[newVal]);
      this.pageLanguageId = langs[newVal];

    });

    this.OnChangeRemember = new Subject<boolean>();
    this.OnChangeRemember.subscribe(newVal => {
      this.RemeberVal = newVal;
    });
  }
  ngOnInit(): void {

    const langs = GetSupportedLanguages();

    for (let i = 0; i < langs.length; i++) {
      if (location.href.indexOf("/" + langs[i]+"/") > -1) {
        this.selectedLang = i;
        this.pageLanguageId = langs[i];
        this.translate.use(this.pageLanguageId);
      }
    }

    }

  public SignInClick(): void {
    if (this.UserName === "") {
      this.LoginMessage = "User name is required";
      this.sing_in_login = "sing-in-login error-text";
    }
    else if (this.Password === "") {
      this.LoginMessage = "Password is required";
      this.sing_in_login = "sing-in-login error-text";
    }
    else  {
      this.userService.SingIn(this.UserName, this.Password).subscribe(data => {
        if (data.code === 0) {
          this.LoginMessage = "Invalid credentials";
          this.sing_in_login = "sing-in-login error-text";
        }
        else if (data.code === 1) {
          if (this.RemeberVal === true) {
            const date = new Date();
            date.setDate(date.getDate() + 3650);
            localStorage.setItem('jwt_token_remember', date.toISOString());
          }
          else {
            const date = new Date();
            date.setHours(date.getHours() + 4);
            localStorage.setItem('jwt_token_remember', date.toISOString());
          }
          localStorage.setItem('jwt_token', data.jwt);
          localStorage.setItem('jwt_sys_role', (data.admin === true ? "true" : "false"));
          location.reload();
        }
      });
    }

  }

  public NewUser() {
    this.router.navigate(['/'+this.pageLanguageId,'registration']);
  }

}
