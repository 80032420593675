
<div *ngIf="PageState == 0">
  <h2>{{ 'Companies'|translate}}</h2>
  <DataTable [DataSource]="dataTableSource" [DataTableConfig]="dataTableConfig">

  </DataTable>
</div>

<div *ngIf="PageState == 1" class="col-9">
  <a class="back-button" [routerLink]="['/'+pageLanguageId,'admin','companies']">
    <i class="si icon-arrow-left" style="font-size: 0.75rem;"></i>
    {{ 'back'|translate}}
  </a>

  <h2 style="    margin-top: 1rem;">{{ 'New company'|translate}}</h2>
  <div class="col-12">
    <div class="col-6">
      <label for="UserName">
        {{ 'User name'|translate}}
      </label>
      <input type="text" [(ngModel)]="UserName" id="UserName" />
    </div>

  </div>
  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="Password">{{ 'Password'|translate}}</label>
      <input type="password" [(ngModel)]="Password" id="Password" />
    </div>
    <div class="col-6">
      <label for="RePassword">{{ 'Confirm password'|translate}}</label>
      <input type="password" [(ngModel)]="RePassword" id="RePassword" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="companyName">{{ 'Company name'|translate}}</label>
      <input type="text" [(ngModel)]="_model.companyName" id="companyName" />
    </div>
    <div class="col-6">
      <label for="companyId">{{ 'Company Id'|translate}}</label>
      <input type="text" [(ngModel)]="_model.companyId" id="companyId" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="street">{{ 'Street'|translate}}</label>
      <input type="text" [(ngModel)]="_model.street" id="street" />
    </div>
    <div class="col-6">
      <label for="streetNumber">{{ 'Street number'|translate}}</label>
      <input type="text" [(ngModel)]="_model.streetNumber" id="streetNumber" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="city">{{ 'City'|translate}}</label>
      <input type="text" [(ngModel)]="_model.city" id="city" />
    </div>
    <div class="col-6">
      <label for="postCode">{{ 'Post code'|translate}}</label>
      <input type="text" [(ngModel)]="_model.postCode" id="postCode" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="country">{{ 'country'|translate}}</label>
      <input type="text" [(ngModel)]="_model.country" id="country" />
    </div>
    <div class="col-6">
      <label for="companyVAT">{{ 'Company VAT'|translate}}</label>
      <input type="text" [(ngModel)]="_model.companyVAT" id="companyVAT" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="companyVAT_ID">{{ 'Company VAT ID'|translate}}</label>
      <input type="text" [(ngModel)]="_model.companyVAT_ID" id="companyVAT_ID" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="deliveryName">{{ 'Delivery name'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryName" id="deliveryName" />
    </div>
    <div class="col-6">
      <label for="deliverySurname">{{ 'Delivery surname'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliverySurname" id="deliverySurname" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="deliveryStreet">{{ 'Delivery street'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryStreet" id="deliveryStreet" />
    </div>
    <div class="col-6">
      <label for="deliveryStreetNumber">{{ 'Delivery street number'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryStreetNumber" id="deliveryStreetNumber" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="deliveryCity">{{ 'Delivery city'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryCity" id="deliveryCity" />
    </div>
    <div class="col-6">
      <label for="deliveryPostCode">{{ 'Delivery post code'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryPostCode" id="deliveryPostCode" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="deliveryCountry">{{ 'Delivery country'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryCountry" id="deliveryCountry" />
    </div>
    <div class="col-6">
      <label for="deliveryCompanyName">{{ 'Delivery company name'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryCompanyName" id="deliveryCompanyName" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="languageDefault">{{ 'Default language'|translate}}</label>
      <select [(ngModel)]="_model.languageDefault" id="languageDefault">
        <option value="0">{{ 'english'|translate}}</option>
        <option value="1">{{ 'italy'|translate}}</option>
        <option value="2" selected="selected">{{ 'european'|translate}}</option>
        <option value="3">{{ 'united state'|translate}}</option>
        <option value="4">{{ 'switzerland'|translate}}</option>
      </select>
    </div>
    <!--<div class="col-6">
      <label for="defaultMessage">{{ 'Default message'|translate}}</label>
      <textarea [(ngModel)]="_model.defaultMessage" id="defaultMessage"></textarea>
    </div>-->
  </div>

  <!--<div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="expirationDate">Expiration date</label>
      <input type="date" [(ngModel)]="_model.expirationDate" id="expirationDate"/>
    </div>
  </div>-->


  <div class="col-12">
    <div class="col-4" style="max-width:200px;    margin-top: 3rem;">
      <button class="admin_button" (click)="AddSubmit()">{{ 'Add company'|translate}}</button>
    </div>
  </div>
</div>

<div *ngIf="PageState == 2" class="col-9">
  <a class="back-button" [routerLink]="['/'+pageLanguageId,'admin','companies']">
    <i class="si icon-arrow-left" style="font-size: 0.75rem;"></i>
    {{ 'back'|translate}}
  </a>
  <h2 style="    margin-top: 1rem;">Update company</h2>
  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="Password">{{ 'Password'|translate}}</label>
      <input type="password" [(ngModel)]="Password" id="Password" />
    </div>
    <div class="col-6">
      <label for="RePassword">{{ 'Confirm password'|translate}}</label>
      <input type="password" [(ngModel)]="RePassword" id="RePassword" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="companyName">{{ 'Company name'|translate}}</label>
      <input type="text" [(ngModel)]="_model.companyName" id="companyName" />
    </div>
    <div class="col-6">
      <label for="ShortcutId">
        {{ 'Shorcut id'|translate}}
      </label>
      <input type="text" [(ngModel)]="_model.shortcutId" id="ShortcutId" disabled />
    </div>

  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="street">{{ 'Street'|translate}}</label>
      <input type="text" [(ngModel)]="_model.street" id="street" />
    </div>
    <div class="col-6">
      <label for="streetNumber">{{ 'Street number'|translate}}</label>
      <input type="text" [(ngModel)]="_model.streetNumber" id="streetNumber" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="city">{{ 'City'|translate}}</label>
      <input type="text" [(ngModel)]="_model.city" id="city" />
    </div>
    <div class="col-6">
      <label for="postCode">{{ 'Post code'|translate}}</label>
      <input type="text" [(ngModel)]="_model.postCode" id="postCode" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="country">{{ 'country'|translate}}</label>
      <input type="text" [(ngModel)]="_model.country" id="country" />
    </div>
    <div class="col-6">
      <label for="companyId">{{ 'Company Id'|translate}}</label>
      <input type="text" [(ngModel)]="_model.companyId" id="companyId" />
    </div>

  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="companyVAT">{{ 'Company VAT'|translate}}</label>
      <input type="text" [(ngModel)]="_model.companyVAT" id="companyVAT" />
    </div>
    <div class="col-6">
      <label for="companyVAT_ID">{{ 'Company VAT ID'|translate}}</label>
      <input type="text" [(ngModel)]="_model.companyVAT_ID" id="companyVAT_ID" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="deliveryName">{{ 'Delivery name'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryName" id="deliveryName" />
    </div>
    <div class="col-6">
      <label for="deliverySurname">{{ 'Delivery surname'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliverySurname" id="deliverySurname" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="deliveryStreet">{{ 'Delivery street'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryStreet" id="deliveryStreet" />
    </div>
    <div class="col-6">
      <label for="deliveryStreetNumber">{{ 'Delivery street number'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryStreetNumber" id="deliveryStreetNumber" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="deliveryCity">{{ 'Delivery city'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryCity" id="deliveryCity" />
    </div>
    <div class="col-6">
      <label for="deliveryPostCode">{{ 'Delivery post code'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryPostCode" id="deliveryPostCode" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="deliveryCountry">{{ 'Delivery country'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryCountry" id="deliveryCountry" />
    </div>
    <div class="col-6">
      <label for="deliveryCompanyName">{{ 'Delivery company name'|translate}}</label>
      <input type="text" [(ngModel)]="_model.deliveryCompanyName" id="deliveryCompanyName" />
    </div>
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="languageDefault">{{ 'Default language'|translate}}</label>
      <select [(ngModel)]="_model.languageDefault" id="languageDefault">
        <option value="0">{{ 'english'|translate}}</option>
        <option value="1">{{ 'italy'|translate}}</option>
        <option value="2" >{{ 'european'|translate}}</option>
        <option value="3">{{ 'united state'|translate}}</option>
        <option value="4">{{ 'switzerland'|translate}}</option>

      </select>
    </div>
    <!--<div class="col-6">
      <label for="defaultMessage">{{ 'Default message'|translate}}</label>
      <textarea [(ngModel)]="_model.defaultMessage" id="defaultMessage"></textarea>
    </div>-->
  </div>

  <div class="col-12 flex-div" style="margin-top: 2rem;">
    <div class="col-6">
      <label for="expirationDate">{{ 'Expiration date'|translate}}</label>
      <input type="text"  id="expirationDate2" />
    </div>
  </div>
  <div class="col-12">
    <div class="col-4" style="max-width:200px;    margin-top: 3rem;">
      <button class="admin_button" (click)="UpdateSubmit(false)">{{ 'Update company'|translate}}</button>
    </div>
    <div class="col-4" style="max-width:200px;    margin-top: 3rem;">
      <button class="admin_button" (click)="UpdateSubmit(true)">{{ 'Upd. company with exp. date'|translate}}</button>
    </div>
    <div class="col-4" style="max-width:200px;    margin-top: 3rem;">
      <button class="admin_button" (click)="SingInAsCompany()">{{ 'Sing as company'|translate}}</button>
    </div>
  </div>
</div>


