
<TopMenu [LanguageId]="pageLanguageId" Text="" Active="media"></TopMenu>

<div class="content_640">
  <div class="sign-in-form-box">
    <div class="default_input_box" style="display: table; width: 100%; max-width: 300px;">
      <span class="default-lang-text">{{ 'Whatsapp activation' | translate}}:</span><br />
      <input type="checkbox" class="default_input" [(ngModel)]="companyDetail.whatsappActivate" style="width: 25px;    margin-left: 4rem;">
    </div>
    <div class="default_input_box" style="display: table; width: 100%; max-width: 300px;">
      <span class="default-lang-text">{{ 'Whatsapp link' | translate}}:</span>
      <input type="text" class="default_input" [(ngModel)]="companyDetail.whatsappLink">
    </div>
    <div class="default_input_box" style="display: table; width: 100%; max-width: 300px;">
      <span class="default-lang-text">{{ 'Tripadvisor link' | translate}}:</span>
      <input type="text" class="default_input" [(ngModel)]="companyDetail.tripadvisorLink">
    </div>
    <div class="default_input_box" style="display: table; width: 100%; max-width: 300px;">
      <span class="default-lang-text">{{ 'Google link' | translate}}:</span>
      <input type="text" class="default_input" [(ngModel)]="companyDetail.googleLink">
    </div>
    <div class="default_input_box" style="display: table; width: 100%; max-width: 300px;">
      <span class="default-lang-text">{{ 'Yelp link' | translate}}:</span>
      <input type="text" class="default_input" [(ngModel)]="companyDetail.yelpLink">
    </div>
    <div class="default_input_box" style="display: table; width: 100%; max-width: 300px;">
      <span class="default-lang-text">{{ 'Thefork link' | translate}}:</span>
      <input type="text" class="default_input" [(ngModel)]="companyDetail.theforkLink">
    </div>
    <div style="">
      <button class="button_black" (click)="SaveDetail()">{{ 'Save'|translate }}</button>
    </div>
  </div>

</div>

<div class="footer-black"></div>


<Toast Message="Item updated"></Toast>
