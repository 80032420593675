
  <div class="lang-box">
    <div class="lang-box-div">
      <ng-template [ngIf]="selectedLang == 0 && ShowLanguage[0] == true">
        <div style="width: 75px;">
          <img class="lang-box-img" src="/assets/images/langs/english.svg">
          <img src="/assets/images/langs/arrow_down.svg" class="lang-box-arrow" (click)="OpenOthers()">
        </div>
        <span class="lang-box-span" style="margin-left: -11px;">{{ 'english'| translate }}</span>

      </ng-template>
      <ng-template [ngIf]="selectedLang == 1 && ShowLanguage[1] == true">
        <div style="width: 75px;">
          <img class="lang-box-img" src="/assets/images/langs/italy.svg">
          <img src="/assets/images/langs/arrow_down.svg" class="lang-box-arrow" (click)="OpenOthers()">
        </div>
        <span class="lang-box-span" style="margin-left: -3px;">{{ 'italy'| translate }}</span>

      </ng-template>
      <ng-template [ngIf]="selectedLang == 2 && ShowLanguage[2] == true">
        <div style="width: 75px;">
          <img class="lang-box-img" src="/assets/images/langs/eu.svg">
          <img src="/assets/images/langs/arrow_down.svg" class="lang-box-arrow" (click)="OpenOthers()">
        </div>
        <span class="lang-box-span" style="margin-left: -8px;">{{ 'eu'| translate }}</span>

      </ng-template>
      <ng-template [ngIf]="selectedLang == 3 && ShowLanguage[3] == true">
        <div style="width: 75px;">
          <img class="lang-box-img" src="/assets/images/langs/us.svg">
          <img src="/assets/images/langs/arrow_down.svg" class="lang-box-arrow" (click)="OpenOthers()">
        </div>
        <span class="lang-box-span" style="margin-left: -8px;">{{ 'usa'| translate }}</span>

      </ng-template>
      <ng-template [ngIf]="selectedLang == 4 && ShowLanguage[4] == true">
        <div style="width: 75px;">
          <img class="lang-box-img" src="/assets/images/langs/chf.svg">
          <img src="/assets/images/langs/arrow_down.svg" class="lang-box-arrow" (click)="OpenOthers()">
        </div>
        <span class="lang-box-span" style="margin-left: -10px;">{{ 'switzerland'| translate }}</span>
      </ng-template>
      <ng-template [ngIf]="selectedLang == 5 && ShowLanguage[5] == true">
        <div style="width: 75px;">
          <img class="lang-box-img" src="/assets/images/langs/ru.svg">
          <img src="/assets/images/langs/arrow_down.svg" class="lang-box-arrow" (click)="OpenOthers()">
        </div>
        <span class="lang-box-span" style="margin-left: -10px;">{{ 'russia'| translate }}</span>
      </ng-template>
      <div class="lang-box-div-others" *ngIf="ShowOthers == true">
        <div class="lang-box-div-others-div" *ngIf="selectedLang != 0 && ShowLanguage[0] == true" (click)="ChangeLang(0)">
          <img class="lang-box-others-img" src="/assets/images/langs/english.svg"><br />
          <span class="lang-box-others-span" style="margin-left: 7px;">{{ 'english'| translate }}</span>
        </div>
        <div class="lang-box-div-others-div" *ngIf="selectedLang != 1 && ShowLanguage[1] == true" (click)="ChangeLang(1)">
          <img class="lang-box-others-img" src="/assets/images/langs/italy.svg"><br />
          <span class="lang-box-others-span" style=" margin-left: 12px;">{{ 'italy'| translate }}</span>
        </div>
        <div class="lang-box-div-others-div" *ngIf="selectedLang != 2 && ShowLanguage[2] == true" (click)="ChangeLang(2)">
          <img class="lang-box-others-img" src="/assets/images/langs/eu.svg"><br />
          <span class="lang-box-others-span" style="margin-left: 6px;">{{ 'eu'| translate }}</span>
        </div>
        <div class="lang-box-div-others-div" *ngIf="selectedLang != 3 && ShowLanguage[3] == true" (click)="ChangeLang(3)">
          <img class="lang-box-others-img" src="/assets/images/langs/us.svg"><br />
          <span class="lang-box-others-span" style="    margin-left: 6px;">{{ 'usa'| translate }}</span>
        </div>
        <div class="lang-box-div-others-div" *ngIf="selectedLang != 4 && ShowLanguage[4] == true" (click)="ChangeLang(4)">
          <img class="lang-box-others-img" src="/assets/images/langs/chf.svg"><br />
          <span class="lang-box-others-span" style="    margin-left: 4px;">{{ 'switzerland'| translate }}</span>
        </div>
        <div class="lang-box-div-others-div" *ngIf="selectedLang != 5 && ShowLanguage[5] == true" (click)="ChangeLang(5)">
          <img class="lang-box-others-img" src="/assets/images/langs/ru.svg"><br />
          <span class="lang-box-others-span" style="    margin-left: 11px;">{{ 'russia'| translate }}</span>
        </div>
      </div>
    </div>
    <!--<img src="/assets/images/langs/arrow_down.svg" class="lang-box-arrow" (click)="OpenOthers()">-->
  </div>
