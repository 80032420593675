import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";



@Component({
  selector: 'LangBox',
  templateUrl: 'langbox.component.html'
})
export class LangBoxComponent {

  @Input()
  selectedLang = 0;

  public ShowOthers = false;

  @Input()
  OnChangeLangEvent: Subject<number>;

  @Input()
  ShowLanguage = [true, true, true, true, true,true];

  public OpenOthers() {
    this.ShowOthers = ( this.ShowOthers === true ? false : true);
  }

  public ChangeLang(langId: number) {
     if (this.selectedLang !== langId) {
      if (typeof this.OnChangeLangEvent !== "undefined") {
        this.OnChangeLangEvent.next(langId);
      }
    }
    this.selectedLang = langId;
    this.ShowOthers = false;

  }
}
