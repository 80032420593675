/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./signout.component";
var styles_SignOutComponent = [];
var RenderType_SignOutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SignOutComponent, data: {} });
export { RenderType_SignOutComponent as RenderType_SignOutComponent };
export function View_SignOutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.OnClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Sign out")); _ck(_v, 1, 0, currVal_0); }); }
export function View_SignOutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "SignOut", [], null, null, null, View_SignOutComponent_0, RenderType_SignOutComponent)), i0.ɵdid(1, 49152, null, 0, i2.SignOutComponent, [], null, null)], null, null); }
var SignOutComponentNgFactory = i0.ɵccf("SignOut", i2.SignOutComponent, View_SignOutComponent_Host_0, {}, {}, []);
export { SignOutComponentNgFactory as SignOutComponentNgFactory };
