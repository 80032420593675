/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../components/statictext/staticbox.component.ngfactory";
import * as i3 from "../../../components/statictext/staticbox.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/router";
import * as i7 from "./main.page";
import * as i8 from "@angular/common/http";
var styles_DefaulAppPage = [];
var RenderType_DefaulAppPage = i0.ɵcrt({ encapsulation: 2, styles: styles_DefaulAppPage, data: {} });
export { RenderType_DefaulAppPage as RenderType_DefaulAppPage };
function View_DefaulAppPage_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 30, "div", [["class", "content_480"], ["style", " min-height: calc(100vh - 6rem);"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["class", "sign-in-logo"], ["onclick", "window.open('https://bit.ly/ord2eatcom', '_blank');"], ["src", "/assets/images/logo.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h1", [["class", "reqistration_title"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 1, "StaticBox", [["CssClass", "sing-in-login"]], null, null, null, i2.View_StaticBoxComponent_0, i2.RenderType_StaticBoxComponent)), i0.ɵdid(6, 49152, null, 0, i3.StaticBoxComponent, [], { Text: [0, "Text"], CssClass: [1, "CssClass"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 23, "div", [["class", "sign-in-form-box"]], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(9, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 9, "div", [["class", "default_input_box"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 8, "input", [["autcomplete", "false"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 14).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 14)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 14)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.CompanyId = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(13, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(14, 16384, null, 0, i5.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i0.ɵdid(16, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i0.ɵdid(18, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(20, 0, null, null, 2, "div", [["class", "registration_white_text"]], null, null, null, null, null)), (_l()(), i0.ɵted(21, null, [" * ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(23, 0, null, null, 3, "div", [["style", "margin:1.5rem auto 0rem;display: table;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 2, "button", [["class", "button_black"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.OpenCompany() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(25, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(27, 0, null, null, 3, "div", [["style", "margin:3.5rem auto 0rem;display: table;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(28, 0, null, null, 2, "button", [["class", "button_black"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.QrCode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(29, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.StaticBoxText; var currVal_2 = "sing-in-login"; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = "sign-in-form-box"; var currVal_4 = _co.sing_box; _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_13 = _co.RestauranCodeCss; _ck(_v, 13, 0, currVal_13); var currVal_14 = _co.CompanyId; _ck(_v, 16, 0, currVal_14); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("Welcome")); _ck(_v, 3, 0, currVal_0); var currVal_5 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 19).transform("Restaurant code")), "*"); var currVal_6 = i0.ɵnov(_v, 18).ngClassUntouched; var currVal_7 = i0.ɵnov(_v, 18).ngClassTouched; var currVal_8 = i0.ɵnov(_v, 18).ngClassPristine; var currVal_9 = i0.ɵnov(_v, 18).ngClassDirty; var currVal_10 = i0.ɵnov(_v, 18).ngClassValid; var currVal_11 = i0.ɵnov(_v, 18).ngClassInvalid; var currVal_12 = i0.ɵnov(_v, 18).ngClassPending; _ck(_v, 11, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_15 = i0.ɵunv(_v, 21, 0, i0.ɵnov(_v, 22).transform("necessary data")); _ck(_v, 21, 0, currVal_15); var currVal_16 = i0.ɵunv(_v, 25, 0, i0.ɵnov(_v, 26).transform("Open restaurant")); _ck(_v, 25, 0, currVal_16); var currVal_17 = i0.ɵunv(_v, 29, 0, i0.ɵnov(_v, 30).transform("Read QR code")); _ck(_v, 29, 0, currVal_17); }); }
export function View_DefaulAppPage_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "header-black-logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "header-black-logo-div"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["class", "header-black-logo-img"], ["onclick", "window.open('https://bit.ly/ord2eatcom', '_blank');"], ["src", "/assets/images/logo.svg"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DefaulAppPage_1)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 7, "div", [["class", "content_480"], ["style", " min-height: calc(100vh - 6rem);"]], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(7, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 0, "img", [["class", "sign-in-logo"], ["onclick", "window.open('https://bit.ly/ord2eatcom', '_blank');"], ["src", "/assets/images/logo.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "video", [["id", "qr_video"], ["style", "max-width: 80vw;;max-height: 60vh; margin: 2rem auto; display: block;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "a", [["class", "reqistration_button_next_a "]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.BackButton() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 0, "div", [["class", "menu_button_prev"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "div", [["class", "footer-black"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.PageState == 1); _ck(_v, 4, 0, currVal_0); var currVal_1 = "content_480"; var currVal_2 = _co.QrClass; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_5 = i0.ɵEMPTY_ARRAY; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = i0.ɵnov(_v, 11).target; var currVal_4 = i0.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_3, currVal_4); }); }
export function View_DefaulAppPage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DefaulAppPage_0, RenderType_DefaulAppPage)), i0.ɵdid(1, 4243456, null, 0, i7.DefaulAppPage, [i8.HttpClient, i6.Router], null, null)], null, null); }
var DefaulAppPageNgFactory = i0.ɵccf("ng-component", i7.DefaulAppPage, View_DefaulAppPage_Host_0, {}, {}, []);
export { DefaulAppPageNgFactory as DefaulAppPageNgFactory };
