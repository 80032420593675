import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { CompanyService, PublicCompanyRegistrationModel } from "../../../services/company/CompanyService";
import { UserService } from "../../../services/user/UserService";
import { RegistrationStateService } from "../../../services/registrationState/RegistrationStateService";
import { TranslateService } from "@ngx-translate/core";
import { GetSupportedLanguages } from "../../../GlobalModels";

export class Product
{
  public price30: number;
  public price180: number;
  public price365: number;
  public name: string;
  public imagePath: string;
  public desc: string;
  public id: number;
  public currency: string;

}

@Component({
  templateUrl:"registration.page.html"
})
export class RegistrationPage implements OnInit {

  public EmailError = true;
  public PageState = 0;

  public step2registration_style = { maxHeight: "0px" };
  public step2CheckBoxEvent = new Subject<boolean>();
  public AcceptPolicyEvent = new Subject<boolean>();

  public months: number[] = [];
  public years: number[] = [];

  public calculatedTotalPrice = 0;

  //public UserName = "";
  //public Password = "";
  //public ConfirmPassword = "";
  //public AcceptPolicy = false;

  //public Heading = "";
  //public Address = "";
  //public AddressNumber = "";
  //public City = "";
  //public PostCode = "";
  //public Country = "";
  //public VatCode = "";
  //public DeliverySameAddress = true;
  //public DeliveryHeading = "";
  //public DeliveryAddress = "";
  //public DeliveryAddressNumber = "";
  //public DeliveryCity = "";
  //public DeliveryPostCode = "";
  //public DeliveryCountry = "";
  //public DeliveryVatCode = "";

  //public AccountHolder = "";
  //public CreditCardNumber = "";

  //public CreditCardMonth = 0;
  //public CreditCardYear = 0;
  //public CreditCardCVV = "";

  //public productId = 0;
  //public productDurationType = 0;

  public UserNameClass = "default_input";
  public PasswordClass = "default_input";
  public ConfirmPasswordClass = "default_input";
  public AcceptPolicyClass = "";

  public HeadingClass = "default_input";
  public AddressClass = "default_input";
  public AddressNumberClass = "default_input";
  public CityClass = "default_input";
  public PostCodeClass = "default_input";
  public CountryClass = "default_input";
  public VatCodeClass = "default_input";
  public DeliveryHeadingClass = "default_input";
  public DeliveryAddressClass = "default_input";
  public DeliveryAddressNumberClass = "default_input";
  public DeliveryCityClass = "default_input";
  public DeliveryPostCodeClass = "default_input";
  public DeliveryCountryClass = "default_input";
  public DeliveryVatCodeClass = "default_input";
  public AccountHolderClass = "default_input";
  public CreditCardNumberClass = "default_input";
  public CreditCardCVVClass = "default_input";
  public captchaToken = "";


  public product: Product;
  public pageLanguageId = 'en';


  constructor(public route: ActivatedRoute, public router: Router, public companyService: CompanyService,
    public userService: UserService, public registrationStateService: RegistrationStateService, public translateService: TranslateService) {

    this.product = new Product();
    this.product.imagePath = "/assets/images/product1.png";
    this.translateService.get("Online menu").subscribe(trans => {
      this.product.name = trans;
    });
    
    this.product.price30 = 3;
    this.product.price180 = 18;
    this.product.price365 = 36.5;
    this.translateService.get("ORDERIT, THE EASY SYSTEM TO MANAGE YOUR ONLINE MENU FOR SMARTPHONES.").subscribe(trans => {
      this.product.desc = trans;
    });
    

    for (let i = 1; i < 13; i++)
      this.months.push(i);
    let _year = (new Date()).getFullYear() % 2000;
    this.registrationStateService.CreditCardYear = ""+_year;
    this.registrationStateService.CreditCardMonth = "" +( (new Date()).getMonth()+1);
    for (let i = 0; i < 10; i++)
      this.years.push(_year+i);
    this.step2CheckBoxEvent.subscribe(data => {
      if (data) {
        this.step2registration_style = { maxHeight: "1000px" };
        this.registrationStateService.DeliverySameAddress = false;
      }
      else {
        this.step2registration_style = { maxHeight: "0px" };
        this.registrationStateService.DeliverySameAddress = true;
      }
    });

    this.AcceptPolicyEvent.subscribe(data => {
      this.registrationStateService.AcceptPolicy = data;
    });


  }

  public validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
  }

  public SelectedProduct(id, type) {
    this.registrationStateService.productId = id;
    this.registrationStateService.productDurationType = type;
    if (type === 1)
      this.registrationStateService.productName = "eMenu30";
    //else if (type === 2)
    //  this.registrationStateService.productName = "eMenu180";
    else if (type === 3)
      this.registrationStateService.productName = "eMenu365";

    this.router.navigate(["/"+this.pageLanguageId,'registration','step2']);
  }

  public ValidationPageState0() {
    let _validate = true;
    this.ValidCss();
    if (this.registrationStateService.UserName === "" || this.validateEmail(this.registrationStateService.UserName) === false) {
      _validate = false;
      this.UserNameClass += " input_error";
    }
    if (this.registrationStateService.Password === "" || this.registrationStateService.Password !== this.registrationStateService.ConfirmPassword) {
      _validate = false;
      this.PasswordClass += " input_error";
    }
    if (this.registrationStateService.ConfirmPassword === "" || this.registrationStateService.Password !== this.registrationStateService.ConfirmPassword) {
      _validate = false;
      this.ConfirmPasswordClass += " input_error";
    }
    if (this.registrationStateService.AcceptPolicy === false) {
      _validate = false;
      this.AcceptPolicyClass = "input_error";
    }
    if (_validate === true) {
      this.registrationStateService.correctState = 0;
      this.router.navigate(["/" + this.pageLanguageId,'registration','step1']);
    }
  }

  public calculateTotalPrice(): number {
    this.registrationStateService.correctState = 1;
    if (this.registrationStateService.productDurationType === 1)
      return this.product.price30;
    else if (this.registrationStateService.productDurationType === 2)
      return this.product.price180;
    else if (this.registrationStateService.productDurationType === 3)
      return this.product.price365;
  }

  public ValidationPageState2() {
    let _validate = true;
    this.ValidCss();
    if (this.registrationStateService.Heading === "") {
      _validate = false;
      this.HeadingClass += " input_error";
    }
    if (this.registrationStateService.Address === "") {
      _validate = false;
      this.AddressClass += " input_error";
    }
    if (this.registrationStateService.AddressNumber === "") {
      _validate = false;
      this.AddressNumberClass += " input_error";
    }
    if (this.registrationStateService.City === "") {
      _validate = false;
      this.CityClass += " input_error";
    }
    if (this.registrationStateService.PostCode === "") {
      _validate = false;
      this.PostCodeClass += " input_error";
    }
    if (this.registrationStateService.Country === "") {
      _validate = false;
      this.CountryClass += " input_error";
    }
    if (this.registrationStateService.VatCode === "") {
      _validate = false;
      this.VatCodeClass += " input_error";
    }
    if (this.registrationStateService.DeliverySameAddress === false) {
      if (this.registrationStateService.DeliveryHeading === "") {
        _validate = false;
        this.DeliveryHeadingClass += " input_error";
      }
      if (this.registrationStateService.DeliveryAddress === "") {
        _validate = false;
        this.DeliveryAddressClass += " input_error";
      }
      if (this.registrationStateService.DeliveryAddressNumber === "") {
        _validate = false;
        this.DeliveryAddressNumberClass += " input_error";
      }
      if (this.registrationStateService.DeliveryCity === "") {
        _validate = false;
        this.DeliveryCityClass += " input_error";
      }
      if (this.registrationStateService.DeliveryPostCode === "") {
        _validate = false;
        this.DeliveryPostCodeClass += " input_error";
      }
      if (this.registrationStateService.DeliveryCountry === "") {
        _validate = false;
        this.DeliveryCountryClass += " input_error";
      }
      if (this.registrationStateService.DeliveryVatCode === "") {
        _validate = false;
        this.DeliveryVatCodeClass += " input_error";
      }
    }
    if (_validate === true) {
      this.registrationStateService.correctState = 2;
      this.router.navigate(["/" + this.pageLanguageId,'registration','step3']);
    }
  }

  public RegistrationProcess() {

    const model = new PublicCompanyRegistrationModel();
    model.userName = this.registrationStateService.UserName;

    model.companyName = model.deliveryCompanyName = this.registrationStateService.Heading;
    model.street = model.deliveryStreet = this.registrationStateService.Address;
    model.streetNumber = model.deliveryStreet = this.registrationStateService.AddressNumber;
    model.city = model.deliveryCity = this.registrationStateService.City;
    model.country = model.deliveryCountry = this.registrationStateService.Country;
    model.postCode = model.deliveryPostCode = this.registrationStateService.PostCode;
    model.vat = true;
    model.companyVAT = this.registrationStateService.VatCode;
    const _defLangs = GetSupportedLanguages();
    const _langId = this.pageLanguageId;
    const _index = _defLangs.findIndex(function (item) { return item === _langId; });
    if (_index === -1) {
      model.languageDefault = 2
    } else {
      model.languageDefault = _index;
    }
   
    model.productName = this.registrationStateService.productName;

    if (this.registrationStateService.DeliverySameAddress === false) {

      model.deliveryCompanyName = this.registrationStateService.DeliveryHeading;
      model.deliveryStreet = this.registrationStateService.DeliveryAddress;
      model.deliveryStreet = this.registrationStateService.DeliveryAddressNumber;
      model.deliveryCity = this.registrationStateService.DeliveryCity;
      model.deliveryCountry = this.registrationStateService.DeliveryCountry;
      model.deliveryPostCode = this.registrationStateService.DeliveryPostCode;
    }

    model.password = this.registrationStateService.Password;
    model.creditCardAccountHolder = this.registrationStateService.AccountHolder;
    model.creditCardNumber = this.registrationStateService.CreditCardNumber.replace(/ /g, '')
    model.creditCardMonth = parseInt(this.registrationStateService.CreditCardMonth);
    model.creditCardYear = parseInt(this.registrationStateService.CreditCardYear);
    model.creditCardCVV = parseInt(this.registrationStateService.CreditCardCVV);
    model.expirationDate = "2020-01-01T00:00:01";
    model.captchaToken = this.captchaToken;
    this.companyService.PublicCompanyRegistration(model).subscribe(result => {
      if (result.code === -1) {
        this.translateService.get("Used email is already registed.").subscribe(trans => {
          alert(trans);
        });

      }
      else if (result.code === 1) {

        this.userService.SingIn(this.registrationStateService.UserName, this.registrationStateService.Password).subscribe(data => {
          if (data.code === 1) {
            localStorage.setItem('jwt_token', data.jwt);
            localStorage.setItem('jwt_sys_role', (data.admin === true ? "true" : "false"));
            const date = new Date();
            date.setDate(date.getDate() + 3650);
            localStorage.setItem('jwt_token_remember', date.toISOString());
            this.router.navigate(["/" + this.pageLanguageId,'company']);
          }
          else {
            this.router.navigate(["/" + this.pageLanguageId, 'company']);
            
          }
        });
      }
      else {
        this.translateService.get("Unknow error. Try to register later.").subscribe(trans => {
          alert(trans);
        });

      }
    });

  }


  public ValidationPageState3() {
    let _validate = true;
    this.ValidCss();
    if (this.registrationStateService.AccountHolder === "") {
      _validate = false;
      this.AccountHolderClass += " input_error";
    }
    if (this.registrationStateService.CreditCardNumber === "") {
      _validate = false;
      this.CreditCardNumberClass += " input_error";
    }
    if (this.registrationStateService.CreditCardCVV === "" || parseInt(this.registrationStateService.CreditCardCVV) === 0) {
      _validate = false;
      this.CreditCardCVVClass += " input_error";
    }
    if (_validate === true) {
      this.RegistrationProcess();
    }
  }

  public ValidCss() {
    this.UserNameClass = "default_input";
    this.PasswordClass = "default_input";
    this.ConfirmPasswordClass = "default_input";
    this.AcceptPolicyClass = "";

    this.AddressClass = "default_input";
    this.AddressNumberClass = "default_input";
    this.CityClass = "default_input";
    this.CountryClass = "default_input";
    this.DeliveryAddressClass = "default_input";
    this.DeliveryAddressNumberClass = "default_input";
    this.DeliveryCityClass = "default_input";
    this.DeliveryCountryClass = "default_input";
    this.DeliveryPostCodeClass = "default_input";
    this.DeliveryVatCodeClass = "default_input";
    this.DeliveryHeadingClass = "default_input";
    this.HeadingClass = "default_input";
    this.PostCodeClass = "default_input";
    this.VatCodeClass = "default_input";

    this.AccountHolderClass = "default_input";
    this.CreditCardNumberClass = "default_input";
    this.CreditCardCVVClass = "default_input";
  }


  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      const _pageState = routeParams.pageState;
      this.pageLanguageId = routeParams.languageId;
      this.translateService.use(this.pageLanguageId);
      console.log(this.pageLanguageId);

      if (this.registrationStateService.correctState > -1) {
        if (_pageState === "step1") {
          this.PageState = 1;
          this.companyService.GetProducts(this.pageLanguageId.toLowerCase()).subscribe(results => {
            this.product.price30 = results[results.findIndex(function (item) { return item.name === 'eMenu30'; })].price / 100;
            //this.product.price180 = results[results.findIndex(function (item) { return item.name === 'eMenu180'; })].price / 100;;
            this.product.price365 = results[results.findIndex(function (item) { return item.name === 'eMenu365'; })].price / 100;;
            this.product.currency = results[results.findIndex(function (item) { return item.name === 'eMenu30'; })].currency;
          });

        }
        else if (_pageState === "step2") {
          this.PageState = 2;
        }

        else if (_pageState === "step3") {
          this.PageState = 3;

          this.calculatedTotalPrice = this.calculateTotalPrice();
        }
      }
      else {
        this.router.navigate(["/" + this.pageLanguageId, 'registration']);
      }
    })

  }

  public BackToState0() {
    this.router.navigate(["/" + this.pageLanguageId, 'registration']);
  }

  public BackToState2() {
    this.router.navigate(['/' + this.pageLanguageId, 'registration', 'step2']);
  }

  public CreditCardNumberFormat() {
    let tmp = "";;
    let only_text = this.registrationStateService.CreditCardNumber.replace(/ /g, '');
    for (let i = 0; i < only_text.length; i++) {
      if (only_text[i] >= '0' && only_text[i] <= '9') {
        if (i > 0 && i % 4 === 0) {
          tmp += " ";
        }
        tmp += only_text[i];
      }
    }
    this.registrationStateService.CreditCardNumber = tmp.substring(0,19);
  }

  public captchaResolved(captchaResponse: string, res) {
    this.captchaToken = captchaResponse;
    console.log(`Resolved response token: ${captchaResponse}`);

  }

}
