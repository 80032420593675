import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxImageCompressService } from 'ngx-image-compress';
import { CategoryItemsComponent, CategoryItem } from "../../../components/CategoryItems/categoryitems.component";
import { CompanyService, CategoryModel, PublicCategoryItem, CategoryItemsModel, CategoryListItemModel, UpdateInitialText } from "../../../services/company/CompanyService";
import { Subject, VirtualAction } from "rxjs";
import { CategoryCarusalComponent } from "../../../components/CategoryCarusal/categorycarusal.component";
import { ListItemsComponent, ListItem } from "../../../components/ListItems/listitems.component";
import { CheckBoxChangeEvent } from "../../../components/checkbox/checkbox.component";
import { ToastComponent } from "../../../components/Toast/toast.component";
import { CompanyStateService } from "../../../services/company/CompanyStateService";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { GetLangSelectBoxDaata, GetSupportedLanguages } from "../../../GlobalModels";
import { LangSelectBox } from "../../../components/langselectbox/langselectbox";

declare function CategoryItemsInitCarusal(): any;
declare function ClearAllergnesBox(): any;

@Component({
  templateUrl: 'menu_subcategory.page.html'
})
export class MenuSubCategoryPage implements OnInit, AfterViewInit {

  public PageState = 1;
  public CategoryType = 0;
  public LanguageId = 0;
  public ParentCategoryModelId = -1;
  public SelectedProductId = -1;

  public categoryItemSource = [];

  public carusalItemSource = [];
  public carusalDetailItemSource = [];

  public slideConfig = { "slidesToShow": 1, "slidesToScroll": 1 };

  public drinkCss = "categories-div-drink-active";
  public foodCss = "categories-div-food";

  public template1css = "categories-div-template-active";
  public template2css = "categories-div-template"

  public carusalIndex = 0;
  public carusalDetailIndex = 0;
  public VisibleTemplate = 1;

  public OpenSubcategoryEvent: Subject<CategoryItem>;
  public CarusalOnSelectedChanged: Subject<number>;
  public CarusalLongOnSelectedChanged: Subject<number>;

  public FrozenProductEvent: Subject<boolean>;

  public RemoveListItemEvent: Subject<ListItem>;

  public OnChangeWithId: Subject<CheckBoxChangeEvent>;

  public RemoveCategoryItemEvent: Subject<CategoryItem>;

  public ListItems = [];


  public detailTitle: string;
  public detailPrice: number;
  public detailDesc: string;
  public detailId: number;
  public detailFrozenProduct = false;

  public allergens = [];
  public allergenDefaultValues = [];

  public ImageHeight = 300;
  public ImageWidth = 500;

  public AspectRatio = 5 / 3;

  public Long = true;
  public LongCarusal = true;
  public defaultLanguageId = 0;

  private langTimer1: any = null;

  public options = {
    multiple: true,
    closeOnSelect: true,
    width: '100%',

   // templateSelection: this.formatState
  }

  @ViewChild(ListItemsComponent, { static: false }) listItemComponent: ListItemsComponent;
  @ViewChild(CategoryItemsComponent, { static: false }) categoryItems: CategoryItemsComponent;
  @ViewChild(CategoryCarusalComponent, { static: false }) carusalItem: CategoryCarusalComponent;
  @ViewChildren('carusalCategories') carusalCategories: QueryList<ElementRef>;
  @ViewChildren('allergenList') allergenList: QueryList<ElementRef>;
  @ViewChild(ToastComponent, { static: false }) toast: ToastComponent;


  // LANG_COUNT = 2, pri zmeneny poctu treba pridat tu dalsie polozky poly 
  public ShowLanguage = [true, true];
  public InitialTexts = ["", ""];
  //

  public selectedLangId = -1;

  public selectedAllergens = [];

  public ChangeLangEvent: Subject<number>;
  public ChangeLangEvent2: Subject<number>;
  

  public InitTextArea = "";

  public pageLanguageId = 'en';

  public LangSelectBoxDataSource = [];

  public SpecialOfferCheck = false;


  public PriceError = "Price must be a number";

  public urlType = "drink";
  public urlSubCategory = 0;



  constructor(public route: ActivatedRoute, public companyService: CompanyService, public router: Router,
    public companyStateService: CompanyStateService, public http: HttpClient, public translateService: TranslateService) {


    this.ChangeLangEvent = new Subject<number>();
    this.ChangeLangEvent.subscribe(new_val => {
        this.selectedLangId = new_val;
      this.companyStateService.SelectedLanguageId = this.selectedLangId;
       //
        this.RefreshCategoryData();

    });


    this.CarusalOnSelectedChanged = new Subject<number>();
    this.CarusalOnSelectedChanged.subscribe(index => {
      this.router.navigate([this.pageLanguageId, "company", "menu", "sub-category", this.urlType.toLowerCase(), this.carusalItemSource[index].id, this.selectedLangId]);
    });

    this.OpenSubcategoryEvent = new Subject<CategoryItem>();
    this.OpenSubcategoryEvent.subscribe(data => {
      this.router.navigate([this.pageLanguageId, "company", "menu", "detail", this.urlType.toLowerCase(), this.urlSubCategory, data.id, this.selectedLangId]);       
    });



    this.RemoveCategoryItemEvent = new Subject<CategoryItem>();
    this.RemoveCategoryItemEvent.subscribe(result => {
      this.companyService.RemoveCategoryItem(result.id).subscribe(resultServer => {
        this.RefreshCategoryData();
      });
      
    });

  }

  ngAfterViewInit(): void {
    this.carusalCategories.changes.subscribe(data => {
      if (data.length === this.carusalItemSource.length) {
      //  CategoryItemsInitCarusal();
      }
    });


    }

  public ChangeTemplage(type: number) {
    this.VisibleTemplate = type;
    if (this.VisibleTemplate === 1) {
      this.template2css = "categories-div-template";
      this.template1css = "categories-div-template-active";
    }
    if (this.VisibleTemplate === 2) {

      this.template2css = "categories-div-template-active";
      this.template1css = "categories-div-template";
    }
  }


  public RefreshCategoryData() {
    // this.LangBox1.SetValue(this.selectedLangId);
    this.companyService.GetCategoryItems(this.urlSubCategory, this.selectedLangId, this.CategoryType).subscribe(data => {
      
      this.categoryItemSource = data;

    });
  }

  public SetMainCategory(category: number) {
  //  this.ParentCategoryModelId = -1;
    this.CategoryType = category;
    this.companyService.GetCategoryItems(-1, this.selectedLangId, this.CategoryType).subscribe(data => {
      this.carusalItemSource = data;
      const _urlSubCategory = this.urlSubCategory
      this.carusalIndex = this.carusalItemSource.findIndex(function (item) { return item.id === _urlSubCategory });
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      window.scrollTo(0, 0);
      this.pageLanguageId = routeParams.languageId;
      this.translateService.use(this.pageLanguageId);
      this.urlType = routeParams.type;
      this.selectedLangId = parseInt(routeParams.translateLangId);
      this.urlSubCategory = parseInt(routeParams.subCategory);
      if (this.urlType.toLowerCase() === "food")
        this.SetMainCategory(1);
      else
        this.SetMainCategory(0);

      this.companyService.GetCompanyProfil().subscribe(data => {
        if (this.selectedLangId === -1) {
          this.selectedLangId = data.defaultLanguage;
        }
        this.defaultLanguageId = data.defaultLanguage;
        this.RefreshCategoryData();
        // this.LangBox2.SetValue(this.selectedLangId);
        if (data.translateLanguages.startsWith("[") === true) {
          const langs = JSON.parse(data.translateLanguages);
          if (langs.length === 0)
            langs.push(data.defaultLanguage);
          this.LangSelectBoxDataSource = GetLangSelectBoxDaata(langs);
        }
        else {
          const langs: any = [];
          langs.push(data.defaultLanguage);
          this.LangSelectBoxDataSource = GetLangSelectBoxDaata(langs);
        }

      });

    });
    
  }


  public FindIndex(realOrder: [], old_index: number): number {
    return realOrder.findIndex(function (item) {
      return item === "category_item_" + old_index;
    });
  }

  public BackButton() {
    this.router.navigate([this.pageLanguageId, "company", "menu", this.urlType.toLowerCase(), this.selectedLangId]); 
  }


  public SaveCategory(): void
  {
    if (this.categoryItems.CategoryItems.length > 0) {
      var categories = [];

      var realOrder = this.categoryItems.GetRealOrder();
      

      for (let i = 0; i < this.categoryItems.CategoryItems.length; i++) {
        let new_item = new CategoryModel();
        new_item.categoryType = this.CategoryType;
        new_item.imageBase64 = this.categoryItems.CategoryItems[i].imageBase64;
        new_item.languageId = this.selectedLangId;
        new_item.order = this.FindIndex(realOrder, i);//this.categoryItems.CategoryItems[i].order;
        new_item.parentCategoryModelId = this.urlSubCategory;
        new_item.title = this.categoryItems.CategoryItems[i].title;
        new_item.visible = this.categoryItems.CategoryItems[i].visible;
        new_item.id = this.categoryItems.CategoryItems[i].id;
        new_item.highliting = this.categoryItems.CategoryItems[i].highliting;
        categories.push(new_item);
        

      }
      
      this.companyService.UpdateCategoryItems(categories).subscribe(result => {
        this.RefreshCategoryData();
          this.toast.Message = "Item updated";
          this.toast.Show();
       

      });
    }
  }


  public GetTranslationCategory() {


    let langs = [];
    langs.push(this.selectedLangId);
    let lang_item = GetLangSelectBoxDaata(langs as []);
    let langtarget = lang_item[0].shortcutLang;

    let textQ = "";
    for (let i = 0; i < this.categoryItems.CategoryItems.length; i++) {
      if (textQ !== "")
        textQ += "&";
      textQ += "q=" + encodeURIComponent(this.categoryItems.CategoryItems[i].title);
    }

    this.http.get("https://translation.googleapis.com/language/translate/v2?" + textQ + "&target=" + langtarget + "&format=text&key=AIzaSyAYRgDYz9R8GnvvdPr7hSPpLoXELZnJbPc").subscribe((result: any) => {
      for (let j = 0; j < this.categoryItems.CategoryItems.length; j++) {
        this.categoryItems.CategoryItems[j].title = result.data.translations[j].translatedText;
      }
    });
   
  }


 


}
