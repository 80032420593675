import { Component, Input, OnInit, QueryList, ViewChildren  } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { TranslateService } from '@ngx-translate/core';
import { GetSupportedLanguages } from '../../GlobalModels';
import { Location } from '@angular/common';


export class DateTableResponse {
  public count: number;
  public items: any[];
}

export interface DataTableSource {
  GetHeaderColumns(): string[];
  GetItems(Page: number, Count: number): Observable<DateTableResponse>;
  GetItemColumns(): string[];
  GetCountPerPage(): number;
}

export class DataTableConfig {

  public remove = false;
  public removeEvent: Subject<string>;

  public update = false;
  public updateUrl = [];
  public updateEvent: Subject<string>;

  public add = false;
  public addUrl = [];
  public addEvent: Subject<void>;

}


@Component({
  selector: 'DataTable',
  templateUrl: './datatable.component.html',

})
export class DataTableComponent implements OnInit {

  public _dataSource = [];
  public loading = true;
  public headerCount = 1;
  public noData = true;
  public LanguageId = 'en';

  @Input() public DataSource: DataTableSource = null;

  @Input() public DataTableConfig = new DataTableConfig();


  constructor(private router: Router, private translateService: TranslateService,private location: Location) {
    const route = location.path() + "/";

    const langs = GetSupportedLanguages();
    for (let i = 0; i < langs.length; i++) {
      if (route.startsWith("/" + langs[i] + "/")) {
        this.LanguageId = langs[i];
      }
    }
  }




  ngOnInit()
  {

    this.ReloadData();
  }

  public ReloadData() {
    if (this.DataSource !== null && typeof this.DataSource !== "undefined") {
      this.loading = true;
      this.headerCount = this.DataSource.GetHeaderColumns().length + 1;
      this.DataSource.GetItems(0, this.DataSource.GetCountPerPage()).subscribe(data => {
        if (data.count > 0) {
          this.noData = false;
          this._dataSource = data.items;

        }
        else
          this.noData = true;
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }


  public TransformDataMethod(item: any,code:string): any {
    return eval(code);
  }

  public ChangePage(Page: number, Count: number): void {

  }

  public AddButtonOnClick() {
    if (this.DataTableConfig.addUrl.length > 0) {
      let url = ['/' + this.LanguageId];
      for (let i = 0; i < this.DataTableConfig.addUrl.length; i++)
        url.push(this.DataTableConfig.addUrl[i]);
      this.router.navigate(url);
    }
    if (this.DataTableConfig.addEvent !== null && typeof this.DataTableConfig.addEvent !== "undefined")
      this.DataTableConfig.addEvent.next();
  }

  public UpdateOnClick(_id: string) {
    if (this.DataTableConfig.updateUrl.length > 0) {
      let url = ['/' + this.LanguageId];
      for (let i = 0; i < this.DataTableConfig.updateUrl.length; i++)
        url.push(this.DataTableConfig.updateUrl[i].replace("{id}", _id));
      this.router.navigate( url);
    }
    if (this.DataTableConfig.updateEvent !== null && typeof this.DataTableConfig.updateEvent !== "undefined")
      this.DataTableConfig.updateEvent.next(_id);
  }

  public RemoveOnClick(_id: string) {
    if (this.DataTableConfig.removeEvent !== null) {
      this.translateService.get("Do you realy want remove this item ?").subscribe(trans => {
        if (confirm(trans) === true) {
          this.DataTableConfig.removeEvent.next(_id);
        }

      });

    }
  }

}
