
<div class="order-public-header">
  <div  class="content-public-back" (click)="BackButton()">
  </div>
  <div  class="content-public-eshop-flag-2" (click)="HelpButton()">
    <img src="/assets/images/help.svg" />
  </div>
  <div class="order-public-header-icon">
    <img src="/assets/images/shop.svg" class="order-public-header-icon-svg" />
    <span class="order-public-header-icon-text">{{ 'Delivery' |translate }}</span>
  </div>
</div>
<div class="order-lists">
  <div *ngFor="let item of orderList; let _index = index">
    <div class="order-list-item">
      <div class="order-list-item-number">
        {{ item.Count }}
      </div>
      <div class="order-list-item-text">
        {{ item.ProductName }}
      </div>
      <div class="order-list-item-box" id="orderItem{{ _index }}">
        <img src="/assets/images/order-edit.svg" class="order-list-item-box-edit" (click)="OnClickEdit(_index)" />
        <div class="order-list-item-box-counter" id="orderCounterItem{{ _index }}">
          <div class="order-list-item-box-counter-box">
            <div class="order-list-item-box-counter-box-left" (click)="MinusItem(_index)">-</div>
            <div class="order-list-item-box-counter-box-mid">{{ item.TmpCount }}</div>
            <div class="order-list-item-box-counter-box-right"  (click)="PlusItem(_index)">+</div>
          </div>
          <img src="/assets/images/order-item-ok.svg" class="order-list-item-box-ok" (click)="OkItem(_index)" />
        </div>
        <img src="/assets/images/order-remove.svg" class="order-list-item-box-remove" (click)="RemoveItem(_index)" />
      </div>
      <div *ngIf="Currency == 'gbp'" class="order-list-item-price">{{ item.ProductPrice * item.Count }} &pound;</div>
      <div *ngIf="Currency == 'euro'" class="order-list-item-price">{{ item.ProductPrice * item.Count }} &euro;</div>
      <div *ngIf="Currency == 'dollar'" class="order-list-item-price">{{ item.ProductPrice * item.Count }} $</div>
      <div *ngIf="Currency == 'chf'" class="order-list-item-price">{{ item.ProductPrice * item.Count }} CHF</div>
      <div *ngIf="Currency == 'rub'" class="order-list-item-price">{{ item.ProductPrice * item.Count }} RUB</div>
    </div>
  </div>
  <div class="order-list-item">
    <label for="name" class="blue-input-label" >{{ 'Name'|translate}}</label>
    <input id="name" type="text" class="blue-input" name="FormName"  [(ngModel)]="FormName"/>
    <label for="location" class="blue-input-label" >{{ 'Location'|translate}}</label>
    <div class="location-input">
      <input id="location" type="text" name="FormLocation" class="blue-input" [(ngModel)]="FormLocation"/>
      <img src="/assets/images/pin.svg" />
    </div>
    <label for="name" class="blue-input-label">{{ 'Text'|translate}}</label>
    <textarea id="name" class="blue-input" name="FormText" [(ngModel)]="FormText"></textarea>
    <div class="blue-send-button" (click)="SendOrder()">{{ 'Send order'|translate}}</div>
  </div>
  <div *ngIf="Currency == 'gbp'" class="order-list-item" style="border:0">{{ TotalSum }} &pound;</div>
  <div *ngIf="Currency == 'euro'" class="order-list-item" style="border:0">{{ TotalSum }} &euro;</div>
  <div *ngIf="Currency == 'dollar'" class="order-list-item" style="border:0">{{ TotalSum }} $</div>
  <div *ngIf="Currency == 'chf'" class="order-list-item" style="border:0">{{ TotalSum }} CHF</div>
  <div *ngIf="Currency == 'rub'" class="order-list-item" style="border:0">{{ TotalSum }} RUB</div>


</div>
