import { Component, Input, OnInit, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { Subject } from "rxjs";
import { GetLangSelectBoxDaata } from "../../GlobalModels";
import { TranslateService } from "@ngx-translate/core";

declare function InitSelect2Box(id: string, options: any): any;
declare function SetDataSelect2Box(id: string, options: any):any

@Component({
  selector: 'LangSelectBox',
 // template: '<select  [id]="Id"></select>'
  template:'<select2  [data]="_dataSource" [value]="_selectedLang" [options]="options" (valueChanged)="changed($event)" ></select2>'
})
export class LangSelectBox implements AfterViewInit {

  public _dataSource = [];

  @Input()
  Id = "SelectBox"

  private dataSourceChanging = false;

  @Input("DataSource")
  set DataSource(value: any[]) {
    this.dataSourceChanging = true;
  //  this.SetDataSourceInit = true;
    setTimeout(() => { this.TimerFunction(value); }, 100);
  }

  private _lastSelectedValue = -2;

  @Input("SelectedValue")
  set SelectedValue(value: number) {
    this._lastSelectedValue = value;
    setTimeout(() => { this.SetValueTimer(); }, 100);
  //  this._selectedLang = value;
  }

 // DataSource = GetLangSelectBoxDaata([]);

  public _selectedLang = -1;
  private manualSelectedLang = -1;

  //@Input("selectedLang")
  //set selectedLang(value: number) {
  //  //if (value === 5) {
  //  //  debugger;
  //  //}
  //  if (this._selectedLang !== value && typeof value !== "undefined") {
  //   // if (this._dataSource.length > 0 && value > -1) {
  //    //  const exist = this._dataSource.find(function (item) { return item.id === value; });
  //    //  if (typeof exist !== "undefined") {
  //    //    debugger;
  //    this.SetDataSourceInit = false;
  //        this._selectedLang = value;
  //    //  }
  //   // }
      
  //  }
      
  //}
  private SetDataSourceInit = false;

  @Input()
  SelectedChange: Subject<number>;

  private TimerFunction(value: any) {
    if (this.translateService.langs.length === 6) {
      let _langs = value;
      let _translated = _langs;
      for (let i = 0; i < _langs.length; i++) {

        _translated[i].text = this.translateService.instant(_langs[i].text);
      }
      let sameValues = true;
      if (this._dataSource.length === _translated.length) {
        for (let i = 0; i < _translated.length && sameValues === true; i++) {
          const _index = this._dataSource.findIndex(function (item) { return item.id === _translated[i].id; });
          if (_index === -1)
            sameValues = false;
        }
      }
      else {
        sameValues = false;
      }
      if (sameValues === false)
        this._dataSource = _translated;
      else {
        this.dataSourceChanging = false;
      }
      //console.log(_translated);
     // const _swap = this._selectedLang;
    //  this._selectedLang = -1;
      //if (this.SetDataSourceInit === false) {
    //  this.options.data = _translated;
    //  SetDataSelect2Box(this.Id, this.options);
      //  this._dataSource = _translated;
     // }
    //  this.cdref.detectChanges();

    ////  console.log("TimerFunction:" + _swap);
    //  if (this.manualSelectedLang > -1)
    //    setTimeout(() => {
    //      this._selectedLang = this.manualSelectedLang; this.cdref.detectChanges(); this.SetDataSourceInit = false;
    //    }, 150);
    //  else
    //    this.SetDataSourceInit = false;

    }
    else {
      setTimeout(() => { this.TimerFunction(value); },100);
    }
  }

  public options = {
    multiple: false,
    closeOnSelect: true,
    width: '100%',
    templateSelection: function(state) {
      if (!state.id) {
        return state.text;
      }

      let baseUrl = "/assets/images/langs";
      let span_state = $(
        '<span><img class="img-flag" /> <span class="text-flag"></span></span>'
      );
      // Use .text() instead of HTML string concatenation to avoid script injection issues
      span_state.find("span").text(state.text.toUpperCase());
      span_state.find("img").attr("src", baseUrl + "/" + state.img + ".svg");

      return span_state;
    }
  }

  //public  formatState(state) {
  //  if (!state.id) {
  //    return state.text;
  //  }

  //  let baseUrl = "/assets/images/langs";
  //  let span_state = $(
  //    '<span><img class="img-flag" /> <span class="text-flag"></span></span>'
  //  );
  //  // Use .text() instead of HTML string concatenation to avoid script injection issues
  //  span_state.find("span").text(state.text.toUpperCase());
  //  span_state.find("img").attr("src", baseUrl + "/" + state.img + ".svg");

  //  return span_state;
  //};

  public SetValue(value: number) {
  //  this.SetDataSourceInit = false;
    this.manualSelectedLang = value;
  }

  public SetValueForce(value: number) {
    //  this.SetDataSourceInit = false;
    // this._selectedLang = value;
    this.manualSelectedLang = value;
  }

  private SetValueTimer() {
    if (this._dataSource.length > 0 && this.dataSourceChanging === false && this._selectedLang !== this._lastSelectedValue) {
      this._selectedLang = this._lastSelectedValue;
    }
    else {

      setTimeout(() => { this.SetValueTimer(); }, 100);
    }
  }

  constructor(public translateService: TranslateService,private cdref: ChangeDetectorRef)
  {

  }
  ngAfterViewInit(): void
 {

   // InitSelect2Box(this.Id, this.options);

      //for (let i = 0; i < this._dataSource.length; i++) {
      //  const _local = this._datbaSource[i];

      //  debugger;
      //  this.translateService.get(_local.text, (result) => {
      //    debugger;
      //    _local.text = result;
      //  //  this._dataSource.push(_local);
      //  });
      //}

    }


  changed(data: { value: string }) {
    //;
    if (data.value === "-1000") {
      this.dataSourceChanging = false;
    }
    else {
      const _numValue = parseInt("" + data.value);
      if (typeof this.SelectedChange !== "undefined" && this.SelectedChange !== null)
      this.SelectedChange.next(_numValue);
    }

    //if (this.SetDataSourceInit === false && typeof this.SelectedChange !== "undefined" && this.SelectedChange !== null) {//

     // if (this.manualSelectedLang !== _numValue)
     // {

        //if (this._dataSource.length > 0 && typeof data.value !== "undefined" && _numValue > -1) {
        //  const exist = this._dataSource.find(function (item) { return item.id === _numValue; });
        //  if (typeof exist !== "undefined") {

        //    this.SelectedChange.next(_numValue);
        //  }
        //}
      
        // this.SelectedChange.next(parseInt(data.value));
     // }
   // }
  
  }

}
