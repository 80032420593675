import { Injectable, ErrorHandler, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf("translation.googleapis.com") == -1) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`
        }
      });
    }
    return next.handle(request);
  }
}

@Injectable()
export class AuthErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) { }

  handleError(error) {
    if (typeof error !== "undefined" && typeof error.status !== "undefined" &&
      (error.status === 401 || error.status === 403)) {
      localStorage.removeItem('jwt_token');
      location.reload();
    }

    throw error;
  }
}
