import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";

export class CheckBoxChangeEvent {
  public id: string;
  public state: boolean;
}

@Component({
  selector: 'CheckBox',
  templateUrl: 'checkbox.component.html'
})
export class CheckBoxComponent {

  @Input()
  Text = ""

  @Input()
  OnChange: Subject<boolean>;

  @Input()
  OnChangeWithId: Subject<CheckBoxChangeEvent>;

  @Input()
  InputClass = ""

  @Input()
  Id = ""

  @Input()
  DefaultValue = false


  public onChange(event) {
    if (typeof this.OnChange !== "undefined" && this.OnChange !== null) {
      this.OnChange.next(event.checked);
    }
    if (typeof this.OnChangeWithId !== "undefined" && this.OnChangeWithId !== null) {
      const _item = new CheckBoxChangeEvent();
      _item.id = this.Id;
      _item.state = event.checked;
      this.OnChangeWithId.next(_item);
    }
  }
  
}
