import { Component, AfterViewInit } from "@angular/core";

//import QrScanner from '../qr-scanner.min.js';
import QrScanner from 'qr-scanner'
import { Router } from "@angular/router";
import { CompanyService } from "../../../services/company/CompanyService.js";
import { HttpClient } from "@angular/common/http";

declare function OpenQrReader(): any;

@Component({
  templateUrl: "main.page.html"
})
export class DefaulAppPage implements AfterViewInit {

  public QrClass = "hide_item";
  public CompanyId = "";

  public RestauranCodeCss = "default_input";
  public scanner: QrScanner;
  private companyService: CompanyService;
  public PageState = 1;
  public StaticBoxText = "";
  constructor(private http: HttpClient, private router: Router) {
    this.companyService = new CompanyService(http);
    QrScanner.WORKER_PATH = '/assets/qr-scanner-worker.min.js';
  }

  public QrCodeRead(text: string) {
    const pattern = "o2e.io/";
    var _index = text.indexOf(pattern);
    if (_index > -1) {
      const ShorcutId = text.substring(_index + pattern.length);
      this.companyService.GetPublicCompany(ShorcutId,-1).subscribe(result => {
        if (result.exist === true && result.active === true) {
          this.router.navigate(["/" + ShorcutId]);
        }
      });
      
    }
  }

  public OpenCompany() {
    if (this.CompanyId === "") {
      this.RestauranCodeCss += " input_error";

    }
    else {
      this.companyService.GetPublicCompany(this.CompanyId,-1).subscribe(result => {
        if (result.exist === true && result.active === true) {
          this.router.navigate(["/" + this.CompanyId]);
        }
        else {
          this.StaticBoxText = "Unknow company id";
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.scanner = new QrScanner(document.getElementById("qr_video") as any, (result) => { this.QrCodeRead(result); });

  }

  public BackButton() {
    this.PageState = 1;
    this.QrClass = "hide_item";
    this.scanner.stop();
    
  }

  public QrCode() {
    this.PageState = 2;
    this.QrClass = "";
    this.scanner.start();
   // OpenQrReader();
  }



}
