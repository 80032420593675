<div class="header-black-logo">
  <div class="header-black-logo-div">
    <img onclick="window.open('https://bit.ly/ord2eatcom', '_blank');" src="/assets/images/logo.svg" class="header-black-logo-img" />
  </div>
</div>
<div class="content_480" *ngIf="PageState == 1" style=" min-height: calc(100vh - 6rem);">
  <img onclick="window.open('https://bit.ly/ord2eatcom', '_blank');" src="/assets/images/logo.svg" class="sign-in-logo" />
  <h1 class="reqistration_title">{{ 'Welcome'| translate }}</h1>
  <StaticBox  [Text]="StaticBoxText" CssClass="sing-in-login"></StaticBox>
  <div class="sign-in-form-box" [ngClass]="sing_box">
    <div class="default_input_box">
      <input type="text" [(ngModel)]="CompanyId" [ngClass]="RestauranCodeCss" placeholder="{{ 'Restaurant code'| translate }}*" autcomplete="false" />
      <!--<img *ngIf="EmailError" class="error_validation" src="/assets/images/error.svg" />-->
    </div>

    <div class="registration_white_text">
      * {{ 'necessary data'| translate }}
    </div>
    <div style="margin:1.5rem auto 0rem;display: table;">
      <button class="button_black" (click)="OpenCompany()">{{ 'Open restaurant'| translate }}</button>
    </div>
    <div style="margin:3.5rem auto 0rem;display: table;">
      <button class="button_black" (click)="QrCode()">{{ 'Read QR code'| translate }}</button>
    </div>
  </div>
</div>

<div class="content_480" [ngClass]="QrClass" style=" min-height: calc(100vh - 6rem);">
  <img onclick="window.open('https://bit.ly/ord2eatcom', '_blank');" src="/assets/images/logo.svg" class="sign-in-logo" />
  <!--<div id="reader" width="460px" ></div>-->
  <video id="qr_video" style="max-width: 80vw;;max-height: 60vh; margin: 2rem auto; display: block;"></video>
  <a (click)="BackButton()" [routerLink]="[]" class="reqistration_button_next_a "><div class="menu_button_prev"></div></a>
</div>



<div class="footer-black"></div>
