import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxImageCompressService } from 'ngx-image-compress';
import { CategoryItemsComponent, CategoryItem } from "../../../components/CategoryItems/categoryitems.component";
import { CompanyService, CategoryModel, PublicCategoryItem, CategoryItemsModel, CategoryListItemModel, UpdateInitialText } from "../../../services/company/CompanyService";
import { Subject, VirtualAction } from "rxjs";
import { CategoryCarusalComponent } from "../../../components/CategoryCarusal/categorycarusal.component";
import { ListItemsComponent, ListItem } from "../../../components/ListItems/listitems.component";
import { CheckBoxChangeEvent } from "../../../components/checkbox/checkbox.component";
import { ToastComponent } from "../../../components/Toast/toast.component";
import { CompanyStateService } from "../../../services/company/CompanyStateService";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { GetLangSelectBoxDaata, GetSupportedLanguages } from "../../../GlobalModels";
import { LangSelectBox } from "../../../components/langselectbox/langselectbox";

declare function CategoryItemsInitCarusal(): any;
declare function ClearAllergnesBox(): any;

@Component({
  templateUrl: 'menu_detail.page.html'
})
export class MenuDetailPage implements OnInit {

  public CategoryType = 0;
 // public LanguageId = 0;
  public ParentCategoryModelId = -1;
  public SelectedProductId = -1;

  public categoryItemSource = [];

  public carusalItemSource = [];
  public carusalDetailItemSource = [];

  public slideConfig = { "slidesToShow": 1, "slidesToScroll": 1 };

  public drinkCss = "categories-div-drink-active";
  public foodCss = "categories-div-food";

  public template1css = "categories-div-template-active";
  public template2css = "categories-div-template"

  public carusalIndex = 0;
  public carusalDetailIndex = 0;
  public VisibleTemplate = 1;

  public OpenSubcategoryEvent: Subject<CategoryItem>;
  public CarusalOnSelectedChanged: Subject<number>;
  public CarusalLongOnSelectedChanged: Subject<number>;

  public FrozenProductEvent: Subject<boolean>;
  public ShowPriceEvent: Subject<boolean>;

  public RemoveListItemEvent: Subject<ListItem>;

  public OnChangeWithId: Subject<CheckBoxChangeEvent>;


  public ListItems = [];


  public detailTitle: string;
  public detailPrice: number;
  public detailDesc: string;
  public detailId: number;
  public detailFrozenProduct = false;

  public allergens = [];
  public allergenDefaultValues = [];


  public Long = true;
  public LongCarusal = true;
  public defaultLanguageId = 0;

  private langTimer1: any = null;

  public options = {
    multiple: true,
    closeOnSelect: true,
    width: '100%',

   // templateSelection: this.formatState
  }

  @ViewChild(ListItemsComponent, { static: false }) listItemComponent: ListItemsComponent;
  @ViewChild(CategoryItemsComponent, { static: false }) categoryItems: CategoryItemsComponent;
  @ViewChild(CategoryCarusalComponent, { static: false }) carusalItem: CategoryCarusalComponent;
  @ViewChildren('allergenList') allergenList: QueryList<ElementRef>;
  @ViewChild(ToastComponent, { static: false }) toast: ToastComponent;


  public selectedLangId = -1;

  public selectedAllergens = [];

  public ChangeLangEvent: Subject<number>;
  public ChangeLangEvent2: Subject<number>;

  public SelectedAllergensChanged: Subject<any[]>;

  public pageLanguageId = 'en';

  public LangSelectBoxDataSource = [];

  public InitLangSet = true;

  public PriceError = "Price must be a number";


  public urlType = "food";
  public urlSubCategory = 0;
  public urlDetailId = -1;
  public showPrice = false;



  constructor(public route: ActivatedRoute, public companyService: CompanyService, public router: Router,
    public companyStateService: CompanyStateService, public http: HttpClient, public translateService: TranslateService) {


    this.ChangeLangEvent2 = new Subject<number>();

    this.SelectedAllergensChanged = new Subject<any[]>();
    this.SelectedAllergensChanged.subscribe(result => {
      this.selectedAllergens = result;
    });

    this.LoadDetail();
    this.ChangeLangEvent2.subscribe(new_val => {
      this.selectedLangId = new_val;

      this.companyStateService.SelectedLanguageId = this.selectedLangId;
        this.LoadDetail();
      
    });

    this.FrozenProductEvent = new Subject<boolean>();
    this.FrozenProductEvent.subscribe(result => {
      this.detailFrozenProduct = result;
    });

    this.ShowPriceEvent = new Subject<boolean>();
    this.ShowPriceEvent.subscribe(result => {
      this.showPrice = result;
    });

    this.CarusalLongOnSelectedChanged = new Subject<number>();
    this.CarusalLongOnSelectedChanged.subscribe(data => {
      this.router.navigate([this.pageLanguageId, "company", "menu", "detail", this.urlType.toLowerCase(), this.urlSubCategory, this.carusalItemSource[data].id, this.selectedLangId]); 

    });


    this.RemoveListItemEvent = new Subject<ListItem>();
    this.RemoveListItemEvent.subscribe(data => {
      this.companyService.RemoveCategoryListItem(data.id).subscribe(result => {
        this.LoadDetail();
      });
      
    });


  }

  public LoadDetail() {
    
    this.LongCarusal = true;
    for (let i = 0; i < this.allergenDefaultValues.length; i++) {
      this.allergenDefaultValues[i] = false;
    }
    //this.selectedAllergens = [];
    //if (this.selectedAllergens.length > 1)
     // ClearAllergnesBox();
   // let _swap_allergens = this.allergens;
   // this.allergens = [];
   // this.selectedAllergens = [];
    this.companyService.GetCategoryItemDetail(this.urlDetailId, this.selectedLangId).subscribe(result => {
      
      if (result.code === 1) {
        this.detailId = result.detail.id;
        this.VisibleTemplate = (result.detail.templateType === 0 ? 1 : 2);
        this.ChangeTemplage(this.VisibleTemplate);
        this.detailTitle = result.detail.title;
        this.detailDesc = result.detail.desciption;
        this.detailPrice = result.detail.price;
        this.ListItems = result.listItems;
        this.detailFrozenProduct = result.detail.infoFrozenProduct;
        this.showPrice = result.detail.showPrice;
        //if (_swap_allergens.length > 0) {
        //  this.allergens = _swap_allergens;
        //}
       // debugger;
        if (result.detail.allergensJson !== null && result.detail.allergensJson.startsWith("[")) {
          this.selectedAllergens = JSON.parse(result.detail.allergensJson);

        }
        else {
          this.selectedAllergens = [];
        }
        
        //for (let i = 0; i < this.selectedAllergens.length; i++) {
        //  const _allValue = this.selectedAllergens[i];
        //  const _index = this.allergens.findIndex(function (item) { return item.name === _allValue });
        //  if (_index > -1)
        //    this.allergenDefaultValues[_index] = true;
        //}
      }
    });
  }



  public ChangeTemplage(type: number) {
    this.VisibleTemplate = type;
    if (this.VisibleTemplate === 1) {
      this.template2css = "categories-div-template";
      this.template1css = "categories-div-template-active";
    }
    if (this.VisibleTemplate === 2) {

      this.template2css = "categories-div-template-active";
      this.template1css = "categories-div-template";
    }
  }

  public SetMainCategory(category: number) {
    //  this.ParentCategoryModelId = -1;
    this.CategoryType = category;
    this.companyService.GetCategoryItems(this.urlSubCategory, this.selectedLangId, this.CategoryType).subscribe(data => {
      this.carusalItemSource = data;
      const _urlDetailId = this.urlDetailId;
      this.carusalDetailIndex = this.carusalItemSource.findIndex(function (item) { return item.id === _urlDetailId });
    });
  }


  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      window.scrollTo(0, 0);
      this.pageLanguageId = routeParams.languageId;
      this.urlType = routeParams.type;
      this.urlSubCategory = parseInt(routeParams.subCategory);
      this.selectedLangId = parseInt(routeParams.translateLangId);
      this.urlDetailId = parseInt(routeParams.detailId);
      this.translateService.use(this.pageLanguageId);
      this.translateService.get("Price must be a number").subscribe(trans => {
        this.PriceError = trans;
      });

      if (this.urlType.toLowerCase() === "food")
        this.SetMainCategory(1);
      else
        this.SetMainCategory(0);
      this.companyService.GetAllergens().subscribe(result => {
        this.allergens = [];
        let _tmpAllergens = [];
        for (let i = 0; i < result.length; i++) {
          _tmpAllergens.push({ id: result[i].name, text: result[i].name});
        }
        this.allergens = _tmpAllergens;
      });
      this.companyService.GetCompanyProfil().subscribe(data => {
        this.defaultLanguageId = data.defaultLanguage;

        // this.LangBox2.SetValue(this.selectedLangId);
        if (data.translateLanguages.startsWith("[") === true) {
          const langs = JSON.parse(data.translateLanguages);
          if (langs.length === 0)
            langs.push(data.defaultLanguage);
          this.LangSelectBoxDataSource = GetLangSelectBoxDaata(langs);
        }
        else {
          const langs: any = [];
          langs.push(data.defaultLanguage);
          this.LangSelectBoxDataSource = GetLangSelectBoxDaata(langs);
        }

      });
      this.LoadDetail();
    });
    
  }


  public FindIndex(realOrder: [], old_index: number): number {
    return realOrder.findIndex(function (item) {
      return item === "category_item_" + old_index;
    });
  }

  public BackButton() {
    this.router.navigate(["/" + this.pageLanguageId, 'company', 'menu',"sub-category", this.urlType, this.urlSubCategory, this.selectedLangId]);

  }

  public SaveItemDetail() {


    const _detail = new PublicCategoryItem();
    _detail.detail = new CategoryItemsModel();
    _detail.languageId = this.selectedLangId;
    _detail.listItems = [];
    if (this.VisibleTemplate === 1) {
      const isnum = /^\d{0,24}(\.\d{0,24}){0,24}$/.test("" + this.detailPrice);
      if (isnum === false) {
          alert(this.PriceError);

      }
      else {
        _detail.detail.desciption = this.detailDesc;
        _detail.detail.title = this.detailTitle;
        _detail.detail.price = this.detailPrice;
        _detail.detail.id = this.detailId;
        _detail.detail.showPrice = this.showPrice;
        _detail.detail.templateType = 0;
        _detail.detail.infoFrozenProduct = this.detailFrozenProduct;
        _detail.detail.allergensJson = JSON.stringify(this.selectedAllergens);
      }
    }
    else {
      var realOrder = this.listItemComponent.GetRealOrder();
      for (let i = 0; i < this.ListItems.length; i++) {

        const isnum = /^\d{0,24}(\.\d{0,24}){0,24}$/.test("" + this.ListItems[i].price);
        if (isnum === false) {
          alert(this.PriceError);
          return;
        }
        else {
          const new_item = new CategoryListItemModel();
          new_item.id = this.ListItems[i].id;
          new_item.order = this.FindIndex(realOrder, i);
          new_item.price = this.ListItems[i].price;
          new_item.title = this.ListItems[i].title;
          new_item.visible = this.ListItems[i].visible;
          _detail.listItems.push(new_item);
        }
      }
      _detail.detail.templateType = 1;
      _detail.detail.id = this.detailId;
      
    }
    this.companyService.UpdateCategoryItemDetail(_detail).subscribe(result => {

      this.toast.Message = "Item updated";
      this.toast.Show();
      this.LoadDetail();
    });
  }


  public GetTranslationDetailTempl1() {

    let langs = [];
    langs.push(this.selectedLangId);
    let lang_item = GetLangSelectBoxDaata(langs as []);
    let langtarget = lang_item[0].shortcutLang;


    let textQ = "q=" + encodeURIComponent(this.detailTitle);
    if (this.detailDesc !== "" && this.detailDesc !== null && typeof this.detailDesc !== "undefined")
      textQ += "&q=" + encodeURIComponent(this.detailDesc);

    this.http.get("https://translation.googleapis.com/language/translate/v2?" + textQ + "&target=" + langtarget + "&format=text&key=AIzaSyAYRgDYz9R8GnvvdPr7hSPpLoXELZnJbPc").subscribe((result: any) => {
      
      this.detailTitle = result.data.translations[0].translatedText;
      if (result.data.translations.length > 0)
        this.detailDesc = result.data.translations[1].translatedText;
    });
  }

  public GetTranslationDetailTempl2() {


    let langs = [];
    langs.push(this.selectedLangId);
    let lang_item = GetLangSelectBoxDaata(langs as []);
    let langtarget = lang_item[0].shortcutLang;


    let textQ = "";
    for (let i = 0; i < this.ListItems.length; i++) {
      if (this.ListItems[i].title !== "") {
        if (textQ !== "")
          textQ += "&";
        textQ += "q=" + encodeURIComponent(this.ListItems[i].title);
      }
        
    }


    this.http.get("https://translation.googleapis.com/language/translate/v2?" + textQ + "&target=" + langtarget + "&format=text&key=AIzaSyAYRgDYz9R8GnvvdPr7hSPpLoXELZnJbPc").subscribe((result: any) => {
      for (let i = 0; i < this.ListItems.length; i++) {
        this.ListItems[i].title = result.data.translations[i].translatedText;
      }
    });
  }


  public GetDefaultText() {

    this.companyService.GetCategoryItemDetail(this.urlDetailId, this.defaultLanguageId).subscribe(result => {
      if (result.code === 1) {
        this.detailDesc = result.detail.desciption;

      }
    });

  }

}
