/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./categorycarusal.component";
var styles_CategoryCarusalComponent = [];
var RenderType_CategoryCarusalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CategoryCarusalComponent, data: {} });
export { RenderType_CategoryCarusalComponent as RenderType_CategoryCarusalComponent };
function View_CategoryCarusalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["style", "width:8.33rem!important"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.imageBase64, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_CategoryCarusalComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.imageBase64, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_CategoryCarusalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["carusalCategories", 1]], null, 4, "div", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.OnClick(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CategoryCarusalComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CategoryCarusalComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.LongType; _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.LongType == false); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co._selectedIndex == _v.context.index) ? "slider-div-item-active" : "slider-div-item"); _ck(_v, 0, 0, currVal_0); }); }
export function View_CategoryCarusalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { carusalCategories: 1 }), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "slider-div"], ["id", "category_items_carusal"], ["style", "display:flex;margin:0px auto;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "carusal_button_left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Left() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "carusal-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "carusal-content-wrap"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(6, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CategoryCarusalComponent_1)), i0.ɵdid(8, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 0, "div", [["class", "carusal_button_right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Right() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.posStyle; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co._dataSource; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_CategoryCarusalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "CategoryCarusal", [], null, null, null, View_CategoryCarusalComponent_0, RenderType_CategoryCarusalComponent)), i0.ɵdid(1, 4243456, null, 0, i2.CategoryCarusalComponent, [], null, null)], null, null); }
var CategoryCarusalComponentNgFactory = i0.ɵccf("CategoryCarusal", i2.CategoryCarusalComponent, View_CategoryCarusalComponent_Host_0, { DataSource: "DataSource", SelectedIndex: "SelectedIndex", OnSelectedChanged: "OnSelectedChanged", LongType: "LongType" }, {}, []);
export { CategoryCarusalComponentNgFactory as CategoryCarusalComponentNgFactory };
